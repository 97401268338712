import { SET_GLOSSAY_ITEM, SET_IS_ITEM_LOAD, SET_IS_LOADING, SET_PAIRED_BOOK_DATA, SET_TRANSFORMED_DATA,ADD_GLOSSARY_KEYWORD, SET_GLOSSARY_ITEM_WITH_KEYWORD, SET_IS_GLOSSARY_CALLED_FALSE, SET_LOGGED_IN_USER_EMAIL, SET_USER_OPENED_BOOK_INFO, SET_CONCURRENT_EDITS_DATA, SET_FLOW_TYPE,  } from "./type";
const initialState = {
  data: [],
  pairedData: {},
  isLoading: false,
  isItemLoadingId: "",
  glossaryItem: {},
  isGetKeywordApiCalled:false,
  loggedInUserEmail:"",
  userOpenedBookInfo:{},
  concurrentEditsTrackerData:{},
  flowType:""
};

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRANSFORMED_DATA:
      return { ...state, data: action.payload };
    case SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_IS_ITEM_LOAD:
      console.log("action.payload", action.payload);
      return { ...state, isItemLoadingId: action.payload };
    case SET_PAIRED_BOOK_DATA:
      return { ...state, pairedData: action.payload };
    case SET_GLOSSARY_ITEM_WITH_KEYWORD:
      return { ...state, glossaryItem: action.payload };
    case SET_GLOSSAY_ITEM:
      console.log("nnnnnn", action.payload);
      return {
        ...state,
        glossaryItem: action.payload,
        isLoading: false,
        isGetKeywordApiCalled: true,
      };
    case SET_IS_GLOSSARY_CALLED_FALSE:
      return { ...state, isGetKeywordApiCalled: false };
    case ADD_GLOSSARY_KEYWORD:
      return {
        ...state,
        glossaryItem: {
          ...state.glossaryItem,
          book_level: action.payload,
        },
      };
    case SET_LOGGED_IN_USER_EMAIL:
      return { ...state, loggedInUserEmail: action.payload };
    case SET_USER_OPENED_BOOK_INFO:
      return { ...state, userOpenedBookInfo: action.payload };
    case SET_CONCURRENT_EDITS_DATA:
      return { ...state, concurrentEditsTrackerData: action.payload };
    case SET_FLOW_TYPE:
        return { ...state, flowType: action.payload };
    default:
      return state;
  }
};

export default mainReducer;
