export const SET_TRANSFORMED_DATA = "SET_TRANSFORMED_DATA";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_IS_ITEM_LOAD = "SET_IS_ITEM_LOAD";
export const SET_PAIRED_BOOK_DATA = "SET_PAIRED_BOOK_DATA";
export const SET_GLOSSAY_ITEM = "SET_GLOSSAY_ITEM";
export const ADD_GLOSSARY_KEYWORD = "ADD_GLOSSARY_KEYWORD";
export const SET_GLOSSARY_ITEM_WITH_KEYWORD="SET_GLOSSARY_ITEM_WITH_KEYWORD";
export const SET_IS_GLOSSARY_CALLED_FALSE="SET_IS_GLOSSARY_CALLED_FALSE";
export const SET_LOGGED_IN_USER_EMAIL="SET_LOGGED_IN_USER_EMAIL";
export const SET_USER_OPENED_BOOK_INFO="SET_USER_OPENED_BOOK_INFO";
export const SET_CONCURRENT_EDITS_DATA="SET_CONCURRENT_EDITS_TRACKER_DATA"
export const SET_FLOW_TYPE="SET_FLOW_TYPE"

