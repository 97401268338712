import { SET_CURRENT_CHAPTER, SET_FETCH_TRANSLATED_CHAPTER_PENDING ,SET_FIND_AND_REPLACE_WORD_MATCHES_COUNT,SET_GLOSSARY_APPLIED_DATA,SET_GLOSSARY_LIST,SET_GLOSSARY_SCREEN_TYPE,SET_GLOSSARY_SUGGESTION_DATA,SET_IS_GLOSSARY_APPLYING,SET_IS_TRANSLATION_CONTENT_EDITED, SET_PRE_APPLIED_GLOSSARY_DATA} from "./editor.actions";

const initialState = {
  currentChapter: 1,
  fetchTranslatedChapterPending:false,
  isTranslationContentEdited:false,
  findAndReplaceWordMatchesCount:0,
  isGlossaryApplying:{status:false,englishWord:""},
  glossarySuggestionData:[],
  glossaryList:"",
  glossaryAppliedData:[],
  glossaryScreenType:"",
  preAppliedGlossaryData:{}
};

const editorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_CHAPTER:
      return { ...state, currentChapter: action.payload };

    case SET_FETCH_TRANSLATED_CHAPTER_PENDING:
      return { ...state, fetchTranslatedChapterPending: action.payload };
    case SET_IS_TRANSLATION_CONTENT_EDITED:
      return { ...state, isTranslationContentEdited: action.payload };
    case SET_FIND_AND_REPLACE_WORD_MATCHES_COUNT:
      return { ...state, findAndReplaceWordMatchesCount: action.payload };
    case SET_IS_GLOSSARY_APPLYING:
      return { ...state, isGlossaryApplying: action.payload };
    case SET_GLOSSARY_SUGGESTION_DATA:
      return { ...state, glossarySuggestionData: action.payload };
    case SET_GLOSSARY_LIST:
        return { ...state, glossaryList: action.payload };
    case SET_GLOSSARY_APPLIED_DATA:
        return { ...state, glossaryAppliedData: action.payload };
    case SET_GLOSSARY_SCREEN_TYPE:
        return { ...state, glossaryScreenType: action.payload };
    case SET_PRE_APPLIED_GLOSSARY_DATA:
        return { ...state, preAppliedGlossaryData: action.payload };
    default:
      return state;
  }
};

export default editorReducer;
