import React, { useState } from "react";
import { Button, Tooltip, styled } from "@mui/material";
import { useSelector } from "react-redux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DoneIcon from "@mui/icons-material/Done";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import "./GlossaryRejectionFeedback.scss";
function GlossaryRejectionFeedback({
  handleApproveOrRejectGlossary,
  setShowFeedBackDropdown,
}) {
  const [selectedReason, setSelectedReason] = useState(
    "Not suitable for neither normal glossary nor Smart Glossary."
  );
  const [feedBackInput, setFeedBackInput] = useState("");
  const [showFeedbackInputField, setShowFeedbackInputField] = useState(false);
  const [height, setHeight] = useState("auto"); // Initial height
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    e.preventDefault(); // Prevent text selection while dragging
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;

    // Adjust height based on the mouse movement
    let newHeight = window.innerHeight - e.clientY;
    if (newHeight > 80 && newHeight < window.innerHeight - 50) {
      newHeight = showFeedbackInputField
        ? Math.min(newHeight, 445)
        : Math.min(newHeight, 341);
      setHeight(newHeight);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  React.useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  const handleChange = (event) => {
    if (event.target.value === `Anything else you’d like to share?`) {
      setHeight("auto");
      setShowFeedbackInputField(true);
    } else {
      setShowFeedbackInputField(false);
      setHeight("auto");
    }
    setSelectedReason(event.target.value);
  };

  const handleFeedbackInputField = (e) => {
    setFeedBackInput(e.target.value);
  };

  const reasonList = [
    "Not suitable for neither normal glossary nor Smart Glossary.",
    "Incorrect when to apply and when not to apply conditions.",
    "Does not have correct generalization for Smart Glossary.",
    "More suitable for normal glossary.",
    "Anything else you’d like to share?",
  ];
  return (
    <div
      style={{
        boxShadow: "0px -6px 20px 0px rgba(0, 0, 0, 0.05)",
        height: height,
      }}
      className="flex absolute feedback-containerr rounded-b-md left-0 px-8 py-4 bg-[#FFF]  gap-5 flex-col bottom-0 w-full"
    >
      <div className="flex gap-x-8 relative justify-center w-full items-center">
        {/* <Tooltip title={"Resize"}> */}
        <div className="absolute -top-4 cursor-ns-resize flex justify-center drag-handle">
          <MoreHorizIcon
            onMouseDown={handleMouseDown}
            onTouchStart={(e) => handleMouseDown(e.touches[0])}
          />
        </div>
        {/* </Tooltip> */}
        <Button
          disabled={true}
          className="flex rounded-[4px] font-medium  text-[#FD0007]  relative text-base py-4 px-4 gap-2"
        >
          <div className="flex items-center  gap-2">
            <CloseRoundedIcon />
            Reject
          </div>
        </Button>
        <div className="relative">
          <Button
            disabled={true}
            variant="contained"
            className="flex rounded-[4px] fixAll relative text-base py-2 px-4 gap-2"
          >
            <DoneIcon />
            Approve & Next
          </Button>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="text-xl text-black font-semibold">Help us improve</div>
        <div className="text-base font-medium text-[#5F6368]">
          Your feedback helps us create better glossaries.
        </div>
      </div>
      <div className="flex flex-col gap-2 ">
        <div className="text-[#242424] text-base font-medium">
          What didn’t work for you?
        </div>

        <select
          id="book-sub-genre"
          className="w-full py-3 px-3 outline-none text-xs"
          style={{
            borderRadius: "8px",
            borderColor: "#D9D9D9",
            fontSize: "16px",
            border: "1px solid #A1A1CC",
          }}
          name="scope"
          value={selectedReason}
          onChange={handleChange}
        >
          {reasonList.map((reason) => {
            return (
              <option
                value={reason}
                style={{
                  padding: "20px",
                  marginBottom: "10px",
                  marginTop: "10px", // Limited styling for option
                }}
                className="text-sm font-medium"
              >
                {reason}
              </option>
            );
          })}
        </select>
        {showFeedbackInputField && (
          <textarea
            style={{
              border: "1px solid #D9D9D9",
              fontFamily: "Poppins",
            }}
            placeholder="Feel free to provide any additional feedback."
            className="w-full text-sm resize-none mt-1 bg-[#F9F9F9] translatedVariant  overflow-hidden box-border h-fit rounded-[4px]  px-3 py-[5px] outline-none"
            name="replacement_dutch_word"
            value={feedBackInput}
            onChange={handleFeedbackInputField}
            autoComplete="off"
            rows={4}
          />
        )}
      </div>
      <div className="flex justify-between">
      <div className="flex gap-x-5 justify-end w-full items-center">
      <Button
          onClick={() => {
            setShowFeedBackDropdown(false);
          }}
          
          disabled={false}
          variant="outlined"
          className="flex rounded-[6px] font-medium  py-[7px] relative text-base"
        >
          <div className="flex items-center  gap-2">Cancel</div>
        </Button>
        <Button
          onClick={() => {
            handleApproveOrRejectGlossary();
            setShowFeedBackDropdown(false);
          }}
          disabled={false}
          variant="outlined"
          className="flex rounded-[6px] font-medium relative text-base py-[7px]  px-8 gap-2"
        >
          <div className="flex items-center  gap-2">Skip</div>
        </Button>

        <Button
          disabled={showFeedbackInputField && !feedBackInput ? true : false}
          onClick={() => {
            let userFeedBack = showFeedbackInputField
              ? feedBackInput
              : selectedReason;
            handleApproveOrRejectGlossary(userFeedBack);
            setShowFeedBackDropdown(false);
          }}
          variant="contained"
          className="flex rounded-[6px] fixAll w-[246px] bg-[#3B37FF] font-medium relative text-base py-2 px-4 gap-2"
        >
          Send feedback
        </Button>
      </div>
      </div>
    </div>
  );
}

export default GlossaryRejectionFeedback;
