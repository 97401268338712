import React, { useCallback, useEffect, useRef, useState } from "react";
import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import { setIsSmartGlossary, setSearchedEnglishWord } from "../../App/Glossary/glossary.action";
import { checkDynamicText } from "../../utils/glossaryUtils";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";

function GlossaryEnglishWordInput({
  highlightedEnglishText,
  isSmartGlossary,
  setHighlightedEnglishText,
  setEnableEnglishWordEditing,
}) {
  const [englishInputValue, setEnglishInputValue] = useState(
    highlightedEnglishText
  );
  const {glossaryList}=useSelector((state)=>state.editor)
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  // const [isSmartGlossary, setIsSmartGlossary] = useState(false)
   const isEnglishWordExists=(oldEnglishWord,newEnglishWord)=>{
        if(oldEnglishWord?.toLowerCase()===newEnglishWord?.toLowerCase())return false
        let isGlossaryExists=glossaryList.find((glossary)=>glossary?.english_word?.toLowerCase()===newEnglishWord?.toLowerCase())
        if(isGlossaryExists){
          toast(`English Word already exists`, {
            type: "warning",
            autoClose: 3000,
          });
          return true
        } 
        return false
      }

  const handleUpdateGlossary = () => {
    if(isEnglishWordExists(highlightedEnglishText,englishInputValue)) return
    setEnableEnglishWordEditing(false);
    setHighlightedEnglishText(englishInputValue);
    dispatch(setSearchedEnglishWord(englishInputValue))
    // dispatch(setIsSmartGlossary(false));
  };

  const debouncedUpdateUserActiveTime = useCallback(
    debounce((inputText) => {
      let isDynamic = checkDynamicText(inputText);
      dispatch(setIsSmartGlossary(isDynamic));
    }, 1000),
    []
  );

  const handleEnglishWordChange = (e) => {
    const inputElement = e.target; // Reference to the input element
    const cursorPosition = inputElement.selectionStart; // Capture current cursor position
    
    let value = inputElement.value;
    value = value.replaceAll("[", "<").replaceAll("]", ">");
    
    setEnglishInputValue(value);
    requestAnimationFrame(() => {
      inputElement.setSelectionRange(cursorPosition, cursorPosition);
    });
    debouncedUpdateUserActiveTime(value);
  };

  useEffect(() => {
    if (highlightedEnglishText) {
      dispatch(setIsSmartGlossary(checkDynamicText(highlightedEnglishText)));
      setEnglishInputValue(highlightedEnglishText);
    }
  }, [highlightedEnglishText]);
  useEffect(() => {
    // Function to handle clicks outside the input field
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        if(!englishInputValue.trim()){
          toast(`The English text must not be empty`, {
            type: "warning",
            autoClose: 3000,
          });
          return;
        }
        handleUpdateGlossary()
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [englishInputValue]);

  return (
    <div className="flex flex-col bg-[#F5F7FF] gap-[2px]">
      <div
        style={{
          borderBottom: "1px solid #D9D9D9",
        }}
        className="flex items-center gap-1 "
      >
        <input
          placeholder="Type here"
          className=" text-sm bg-[#F5F7FF] w-full border-none rounded-[4px]  px-3 py-[5px] outline-none"
          name="replacement_dutch_word"
          value={englishInputValue}
          onChange={handleEnglishWordChange}
          ref={inputRef}
          // onBlur={handleUpdateGlossary}
          autoComplete="off"
        />
        {/* <div className="flex  gap-2 justify-end">
          <CloseIcon
            onClick={() => {
              setEnableEnglishWordEditing(false);
              // dispatch(setIsSmartGlossary(false));
            }}
            className="cursor-pointer w-[18px]  h-[18px] text-[#FF3B30]"
          />
          <DoneIcon
            onClick={() => {
              handleUpdateGlossary();
            }}
            style={{
              color: "#52C41A",
              cursor: "pointer",
              width: "18px",
              height: "18px",
            }}
          />
        </div> */}
      </div>
      {/* {isSmartGlossary && (
        <p className="text-[#A6A6A6] m-0 text-[11px] px-2">
          Smart glossary is enabled
        </p>
      )} */}
     
    </div>
  );
}

export default GlossaryEnglishWordInput;
