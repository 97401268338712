import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { RiArrowLeftLine } from "react-icons/ri";

import FileUploadInput from "../../Components/FileUploadInput/FileUploadInput";
import apiUrls from "../../api/apiUrl";
import useApi from "../../api/useAPi";
import DialogBox from "../../Components/DialogBox/DialogBox";
import Loader from "../../Components/Loader/Loader";
import EmailModal from "../../Components/EmailModal/emailModal";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import Footer from "../../Components/Footer/Footer";
import {
  fetchUserFirebaseById,
  loggedInUserActivity,
  updateUserFirebaseFileCount,
} from "../../utils/firebaseActions";
import useStatus from "../../hooks/useStatus";

import info from "../../assets/info.svg";

import "./UploadBookScreenRG.scss";
import { Slider, Switch } from "@mui/material";
import { ADD_APIS_RESPONSE_STATUS } from "../../utils/fireStoreTypes";
import { getCapitalisedString, getEmailThroughSession } from "../../utils/translationUtil";

const MAX_NUMBER_OF_EARLY_CHAPTERS_GENERATION_ALLOWED = 5;

const GENRES = ["Romance"];
const SUB_GENRES = ["Harlequin Intrigue", "Harlequin Medical Romance", "Harlequin Presents"];

const UploadBookScreenRG = ({
  /* @review-glossary-changes */
  flowType
}) => {
  const navigate = useNavigate();
  const [cookies] = useCookies([]);
  const popupEmail=cookies["email"]
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [email, setEmail] = useState(popupEmail);
  const [bookName, setBookName] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [genreName, setGenreName] = useState("");
  const [englishTranslation, setEnglishTranslation] = useState(null);
  const [translatedFile, setTranslatedFile] = useState(null)
  const [dutchTranslation, setDutchTranslation] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [pairBy, setPairBy] = useState("");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isNavigate, setIsNavigate] = useState(false);
  const [pairedBookId, setPairedBookId] = useState("");
  const [modalType, setModalType] = useState("");
  const [temperatureType, setTemperatureType] = useState(1);
  const [isAdvancedSettingsExpanded, setIsAdvancedSettingsExpanded] = useState(false);
  const [isEarlyChapterGenerationEnabled, setIsEarlyChapterGenerationEnabled] = useState(false);
  const [earlyChaptersCount, setEarlyChaptersCount] = useState(0)
  const { fetchData } = useApi();
  const [assistants, setAssistants] = useState([]);

  const [translationTypeEnglish, setTranslationTypeEnglish] =
    useState("english");
  // const [translationType, settranslationType] = useState("dutch");
  const [translationTypeFrench, setTranslationTypeFrench] = useState("french");
  const [translationType, setTranslationType] = useState("dutch");

  const [selectedGenre, setSelectedGenre] = useState("Romance");
  const [selectedSubGenre, setSelectedSubGenre] = useState("");

  const statusHook = useStatus();
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    statusHook.checkStatus(userId);
  }, [userId]);

  useEffect(() => {
    const getAssistants = async () => {
      try {
        const res = await fetchData(apiUrls.getAssistants);
        return res;
      } catch (error) {
        console.error("Error fetching assistants:", error);
        return null;
      }
    };

    const fetchDataAndLog = async () => {
      const data = await getAssistants();
      const res = data?.map(({ model_name, assistant_name }) => ({
        model_name,
        assistant_name,
      }));

      console.log("translationType", translationType, modalType);

      const filtered = res?.filter(
        (item) => (item?.assistant_name.split("-")[0] === translationType) && (item.model_name ==="Google")
      );
      setAssistants(filtered);
      setModalType(filtered?.[0]?.assistant_name);
    };

    fetchDataAndLog();
  }, [translationType]);

  const handleBookNameChange = (event) => {
    setBookName(event.target.value);
    clearFormError("bookName");
  };

  const handleAuthorNameChange = (event) => {
    setAuthorName(event.target.value);
    clearFormError("authorName");
  };

  const handleGenreNameChange = (event) => {
    setGenreName(event.target.value);
    clearFormError("genreName");
  };

  const handleEnglishTranslationChange = (file) => {
    setEnglishTranslation(file);
    clearFormError("englishTranslation");
  };

  const handleTranslationFileChange = (file) => {
    setTranslatedFile(file);
    clearFormError("translatedFile");
  };

  const handleSubGenreChange = (event) => {
    setSelectedSubGenre(event.target.value);
    clearFormError("selectedSubGenre");
  }

  const submitEmailAndCallApi = async () => {
    const toastId = toast.loading("Book is uploading...");

    try {
      setLoading(true);
      let targetLanguage
      switch(translationType){
       case "hinglish":
        targetLanguage="Hinglish"
        break;
       case "dutch":
        targetLanguage="Dutch"
        break;
       case "malyalam":
        targetLanguage="Malyalam"
        break;
       case "hindi":
        targetLanguage="Hindi"
        break;
        case "french":
          targetLanguage="French"
          break;
        case "telugu":
          targetLanguage="Telugu"
          break;
      }
      console.log("Hinglish",translationType,targetLanguage,modalType)
      const userId = cookies["__Secure-next-auth.session-token"];
      console.log(userId);
      const formData = new FormData();
      formData.append("book_title", bookName.trim());
      formData.append("author_name", authorName.trim());
      formData.append("genre", selectedGenre.trim());
      formData.append("subGenre", selectedSubGenre.trim());
      formData.append("english", englishTranslation);
      // formData.append("translationType", translationType);
      formData.append("pair_style", pairBy);
      formData.append("t_model", "EGPT");
      formData.append(
        "stakeholder_emails",
        `["varun.singh@techolution.com","${email}"]`
      );

      formData.append("assistant_name", modalType);
      formData.append("custom_temperature", temperatureType);
      formData.append("capture_formatting", "true");
      formData.append("pre_applied_glossary", "true");
      formData.append("html_formatting", "true");
      formData.append("target_language", targetLanguage);
      formData.append("source_language", "English");
      formData.append("rlef_parameter", "pairing_model_storage");
      formData.append("paragraph_level_pairing",true);
      formData.append("custom_html_parser", "true");
      formData.append("paragraph_translation_delay", "1");
      formData.append("parallel_translation_count", "4");
      formData.append("use_error_fallback", "true");
      formData.append("type_scripting_flag", "false");

      if(flowType==="glossaryReview"){
       formData.append("dutch", translatedFile); 
       formData.append("is_smart_glossary_generation", true); 
      }
      


      if(isEarlyChapterGenerationEnabled){
        formData.append("translate_chapters_till", earlyChaptersCount + 1);
      }

      const userDetails = await fetchUserFirebaseById(userId);
      // if(userDetails?.fileCount >= 5) {
      //   toast("Max 5 files can be uploaded!")
      //   // return;
      // }

      const res = await fetchData(apiUrls.fileConversion, "POST", formData);
      if (res?.status === "File saved successfully") {
        setFormSubmitted(true);
        setSelectedSubGenre("");
        setBookName("");
        setAuthorName("");
        setEnglishTranslation(null);
        setDutchTranslation(null);
        setPairBy("");
        //Tempory msg
        setDialogMessage("Uploaded successfully.");
        setPairedBookId(res?.unique_string);
        // navigate("/dashboard");

        toast.update(toastId, {
          render: "Book uploaded successfully!",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });

        setTimeout(() => {
          navigate("/dashboard", {
            state: { isDutchUploadInput: isDutchUploadInput },
          });
        }, 2000);

        // loggedInUserActivity({
        //   activity: ADD_APIS_RESPONSE_STATUS,
        //   apiResponse: {
        //     status: "success",
        //     apiEndPoint: apiUrls.fileConversion,
        //     responseMessage: res?.status,
        //     authorName:authorName.trim(),
        //     bookTitle:bookName.trim(),
        //   },
        // });
        // setIsNavigate(true);
        // setDialogOpen(true);
        // navigate("/pairsPage");
      }
      await updateUserFirebaseFileCount(userId);
    } catch (err) {
      console.log(err);

      toast.update(toastId, {
        render: "Something went wrong while uploading the Book!",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });

      setDialogMessage("Upload failed. Please try again.");
      setIsNavigate(false);
      setDialogOpen(true);
      logEvent(analytics, "upload_failed", { error: err.message });

      // loggedInUserActivity({
      //   activity: ADD_APIS_RESPONSE_STATUS,
      //   apiResponse: {
      //     status: "error",
      //     apiEndPoint: apiUrls.fileConversion,
      //     authorName:authorName.trim(),
      //     bookTitle:bookName.trim(),
      //     responseMessage: err?.message,
      //   },
      // });
    } finally {
      setLoading(false);
    }
  };

  const handleDutchTranslationChange = (file) => {
    setDutchTranslation(file);
    clearFormError("dutchTranslation");
  };

  const handlePairByChange = (event) => {
    setPairBy(event.target.value);
    clearFormError("pairBy");
  };

  const handleSubmit = async (event) => {
    console.log("called submit");
    event.preventDefault();

    if (!validateForm()) {
      console.log("entr validify");
      return;
    }
    console.log("passed validify");
    //

    setShowEmailModal(true);
  };

  const validateForm = () => {
    const errors = {};

    if (!bookName.trim()) {
      setBookName("");
      errors.bookName = "Please enter the book title.";
    }

    if (bookName.length > 150) {
      errors.bookName = "Book name is too long!";
    }

    if (/[\\/:*?"<>|]/g.test(bookName)) {
      errors.bookName = `Book title can not contain any of these \ / : * ? " < > | characters`;
    }

    if (!authorName.trim()) {
      setAuthorName("");
      errors.authorName = "Please enter the author name.";
    }

    if (/[\\/:*?"<>|]/g.test(authorName)) {
      console.log("author name",authorName)
      errors.authorName = `Author name can not contain any of these \ / : * ? " < > | characters`;
    }

    if (authorName.length > 80) {
      errors.authorName = "Author name is too long!";
    }

    if (!englishTranslation) {
      errors.englishTranslation = "Please select the English version of book.";
    }

    if(!translatedFile){
      errors.translatedFile="Please upload translated book"
    }

    // file size limit error
    if (englishTranslation?.size > 14680064) {
      errors.englishTranslation = "File size should be less than 14mb";
    }
    //except pdf error
    console.log("englishTranslation", englishTranslation);
    // if (englishTranslation?.type.split("/")[1] !== "pdf") {
    //   errors.englishTranslation = "only .pdf and .docx files are accepted";
    // }

    if (
      !englishTranslation?.name.endsWith(".docx") &&
      !englishTranslation?.name.endsWith(".pdf")
    ) {
      errors.englishTranslation = "Only .doc/.docx files are accepted.";
    }
    
    if (!selectedSubGenre.length) {
      errors.selectedSubGenre = "Please select the sub-genre of book.";
    }

    // || !englishTranslation?.name.endsWith('.docx')

    // if (!dutchTranslation) {
    //   errors.dutchTranslation = "Please select the Dutch version of book.";
    // }
    console.log("translationInfo ",modalType,translationType)

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleTooltipHover = (visible) => {
    setTooltipVisible(visible);
  };

  const handleDialogClose = () => {
    const pairedId = "uLVjYjqHWpfwSnIJvKtpzNxPGIYVFbbxyBqbZrbwYSbtNFud"; //Incase staticId need to pass
    setDialogOpen(false);
    setDialogMessage("");
    isNavigate && navigate("/dashboard");
  };

  const clearFormError = (fieldName) => {
    setFormErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[fieldName];
      return updatedErrors;
    });
  };

  const handleDragDrop = () => {
    setDialogMessage("Only Word files are allowed.");
    setDialogOpen(true);
  };

  const handleClearInputFieldEnglish = () => {
    setEnglishTranslation(null);
  };
  const handleClearInputFieldDutch = () => {
    setDutchTranslation(null);
  };

  const handleSingleUploadClick = () => {
    // setIsActiveSingleUpload(true);
    // setIsActiveBatchUpload(false);
    console.log("called single");
    setIsBatchUpload(false);
  };

  const handleBatchUploadClick = () => {
    // setIsActiveSingleUpload(false);
    // setIsActiveBatchUpload(true);
    console.log("called batch");
    return;
    setIsBatchUpload(true);
  };

  const handleTranslationTypeChange = (event) => {
    const selectedValue = event.target.value;
    console.log("selectedValue", selectedValue);
    setTranslationType(selectedValue);
  };

  const handleModalTypeChange = async (event) => {
    const modal = event.target.value;
    console.log("modal", modal);
    setModalType(modal);
  };

  const [isBatchUpload, setIsBatchUpload] = useState(false);

  const handleFolderSelect = (folder) => {
    // Handle folder selection here
    console.log("Selected folder:", folder);
  };

  const [isDutchUploadInput, setDutchUploadInput] = useState(false);
  const [textButtonForDutchShow, setTextButtonForDutchShow] = useState(
    "Add reference translation"
  );

  const handleDutchUploadInputShow = () => {
    setDutchUploadInput(!isDutchUploadInput);
    isDutchUploadInput
      ? setTextButtonForDutchShow("Add reference translation")
      : setTextButtonForDutchShow("Remove reference translation");
  };

  const handleGoBack = () => {
    navigate(-1);
  };


  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        // Update the cookie value when the user returns to the tab
         try {
          const updatedEmail=await getEmailThroughSession()
          
          if(updatedEmail)
            setEmail(updatedEmail)

          loggedInUserActivity({
            activity: ADD_APIS_RESPONSE_STATUS,
            apiResponse: {
              status: "success",
              apiEndPoint: apiUrls.appsecAuthSessionUrl,
              responseMessage: "successfully got the active user data",
            },
          });

         } catch (error) {
             console.log("error in getting session");
             loggedInUserActivity({
               activity: ADD_APIS_RESPONSE_STATUS,
               apiResponse: {
                 status: "error",
                 apiEndPoint: apiUrls.appsecAuthSessionUrl,
                 responseMessage:
                   error.message ?? "error in getting active user data",
               },
             });           
         }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  function renderUploadHeader() {
    return (
      <header className="bg-white fixed top-0 left-0 w-full z-10 flex flex-col md:flex-row md:items-center gap-2 px-4 py-2">
        <div className="flex items-center gap-2">
          <IconButton onClick={handleGoBack}>
            <RiArrowLeftLine className="back-arrow" />
          </IconButton>
          <Typography
            noWrap
            color="primary.dark"
            className="sm:text-[20px]"
            fontWeight={600}
          >
            Upload Book For Translation
          </Typography>
        </div>
        {/* <div className="md:ml-auto flex items-center gap-2">
          <ButtonGroup variant="contained" disableElevation>
            <Button disabled={isBatchUpload} onClick={handleSingleUploadClick}>
              Single upload
            </Button>
            <Button disabled={!isBatchUpload} onClick={handleBatchUploadClick}>
              Batch Upload
            </Button>
          </ButtonGroup>
        </div> */}
      </header>
    );
  }

  const renderTranslationInfo = () => {
    return (
      <div className="flex flex-col w-full ">
        <p className="text-[#1E1C80] font-semibold form-title">Select the Language of Translation</p>
        <div className="flex flex-col md:flex-row md:items-center gap-2 md:gap-5 w-full">
        <div className="flex flex-col  gap-2 w-full">
          <label
            htmlFor="source-language"
            className="text-[16px] text-[#131214] font-semibold text-nowrap"
          >
            Translating From:
          </label>
          <div className="dropdown-container w-full">
            <select
              id="source-language"
              className="w-full text-black"
              style={{borderRadius:'6px',borderColor:"#1E1C80"}}
              onChange={(e) => handleTranslationTypeChange(e, "english")}
              value={translationType}
              disabled
            >
              <option value="english">English</option>
            </select>
          </div>
        </div>
        <div className="flex flex-col  gap-2 w-full">
          <label
            htmlFor="target-language"
            className="text-[16px] text-[#131214] font-semibold text-nowrap"
          >
            Translated To:
          </label>
          <div className="dropdown-container w-full">
            <select
              id="target-language"
              className="w-full text-black round"
              style={{borderRadius:'6px',borderColor:"#1E1C80"}}
              onChange={handleTranslationTypeChange}
              value={translationType}
              // disabled={process.env.REACT_APP_ENVIRONMENT === "dev"?false:true}
            >
              <option value="dutch">Dutch</option>
              <option value="hinglish">Hinglish </option>
              <option value="malyalam">Malyalam </option>
              <option value="hindi">Hindi </option>
              <option value="french">French </option>
              <option value="telugu">Telugu </option>
              
            </select>
            {/* <Select
                id="target-language"
                style={{
                  borderRadius: "13px",
                  height: "42px",
                  textAlign: "left",
                }}
                value={translationType}
                onChange={(e) => handleTranslationTypeChange(e)}
                displayEmpty
                className={`input select active`}
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Example focus styles
                  },
                }}
              >
                <MenuItem value="dutch">Dutch</MenuItem>
                {process.env.REACT_APP_ENVIRONMENT === "dev" ? (
                  <MenuItem value="french">French</MenuItem>
                ) : (
                  <MenuItem value="french" disabled>
                    French{" "}
                    <LockOutlinedIcon
                      style={{ marginLeft: "auto", fontSize: "12px" }}
                    />
                  </MenuItem>
                )}
                {process.env.REACT_APP_ENVIRONMENT === "dev" ? (
                  <MenuItem value="swedish">Swedish</MenuItem>
                ) : (
                  <MenuItem value="swedish" disabled>
                    Swedish{" "}
                    <LockOutlinedIcon
                      style={{ marginLeft: "auto", fontSize: "12px" }}
                    />
                  </MenuItem>
                )}
                Hindi , Hinglish should not go to QA or Prod
                <MenuItem value="hindi">Hindi</MenuItem>
                <MenuItem value="hinglish">Hinglish</MenuItem>
              </Select> */}
          </div>
        </div>
        </div>
      </div>
    );
  };

  const renderTranslationProperties = () => {
    return (
      <div className=" w-full flex flex-col  ">
      
        {/* <div className="button-container">
      <button
        onClick={handleSingleUploadClick}
        id="btn-single"
        className={!isBatchUpload ? "active" : ""}
      >
        Single Upload
      </button>
      <button
        onClick={handleBatchUploadClick}
        id="btn-batch"
        className={isBatchUpload ? "active" : ""}
      >
        Batch Upload
      </button>
    </div> */}

         <p className="text-[#1E1C80] font-semibold form-title">Select AI Model and Creativity</p>

        <div className="flex flex-row gap-5 ">
          <div className="flex flex-col gap-2 w-full">
            <label
              htmlFor="ai-model"
              className="text-[16px] text-[#131214] font-semibold text-nowrap"
            >
              Select Model:
            </label>
            <div className="dropdown-container w-full">
              <select
                id="ai-model"
                className="w-full round "
                style={{borderRadius:'6px',borderColor:"#1E1C80"}}
                value={modalType}
                onChange={handleModalTypeChange}
              >
                {assistants?.map((item, id) => (
                  <option
                    value={item.assistant_name}
                    key={id}
                    disabled={
                      process.env.REACT_APP_ENVIRONMENT === "qa" &&
                      item.model_name !== "Google"
                    }
                  >
                    {item.model_name}{" "}
                    {process.env.REACT_APP_ENVIRONMENT === "qa" &&
                      item.model_name !== "Google" && (
                        <LockOutlinedIcon
                          style={{ marginLeft: "auto", fontSize: "12px" }}
                        />
                      )}
                  </option>
                ))}
                <option  value={"englishtesting"}
                    key={"Google-dev"}>Google-dev</option>
              </select>
            </div>
          </div>
            <div className="flex flex-col gap-2 w-full">
              <label
                htmlFor="creativity"
                className="text-[16px] text-[#131214] font-semibold text-nowrap"
              >
                Select Creativity:
              </label>
              <div className="dropdown-container w-full">
                <select
                  id="creativity"
                  className="w-full text-black"
                  style={{borderRadius:'6px',borderColor:"#1E1C80"}}
                  onChange={(e) => setTemperatureType(e.target.value)}
                  value={temperatureType}
                  disabled
                >
                  {/* <option value={0.1}>Low</option> */}
                  {/* <option value={0.5}>Medium</option> */}
                  <option value={1}>High</option>
                </select>
              </div>
            </div>
          </div>
        </div>
    );
  };

  const onExpandAdvancedSettings = () => {
    setIsAdvancedSettingsExpanded((prevState) => {
      // if(prevState)

      return !prevState
    });
  }

  const handleEarlyChapterGenerationChange = (e, newValue) => {
    setIsEarlyChapterGenerationEnabled(!isEarlyChapterGenerationEnabled)
    if(newValue === false){
      setEarlyChaptersCount(0)
    }
  }

  const handleEarlyChapterCountChange = (e, newValue) => {
    setEarlyChaptersCount(newValue)
  }

  const renderBookInfoForm = () => {
    if (isBatchUpload) return null;
    return (
      <div className=" py-8 w-full">
        <Typography
          component="h2"
          className="text-left form-title"
          fontWeight={600}
          color="primary.dark"
        >
          Enter the details of the book to be uploaded
        </Typography>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col  gap-4 pt-4">
            <div className="flex w-full gap-5">
              <div className="w-full flex flex-col gap-2">
                <label
                  htmlFor="book-title"
                  className="text-[16px] text-[#131214] font-semibold text-nowrap"
                >
                  Book title:
                </label>
                <TextField
                  variant="outlined"
                  id="book-title"
                  size="small"
                  placeholder="Book title"
                  value={bookName}
                  fullWidth
                  InputProps={{
                    classes: {
                      notchedOutline: "border-solid border rounded-[6px] border-[#1e1c80]",
                    },
                  }}
                  onChange={handleBookNameChange}
                  error={Boolean(formErrors.bookName)}
                  helperText={formErrors.bookName}
                />
              </div>
              <div className="w-full flex flex-col gap-2">
                <label
                  htmlFor="book-author-name"
                  className="text-[16px] text-[#131214] font-semibold text-nowrap"
                >
                  {`Author's name:`}
                </label>
                <TextField
                  id="book-author-name"
                  type="text"
                  fullWidth
                  size="small"
                  placeholder="Author's name"
                  InputProps={{
                    classes: {
                      notchedOutline: "border-solid border rounded-[6px] border-[#1e1c80]",
                    },
                  }}
                  value={authorName}
                  onChange={handleAuthorNameChange}
                  error={Boolean(formErrors.authorName)}
                  helperText={formErrors.authorName}
                />
              </div>
            </div>
            <div className="flex w-full gap-5">
              <div className="w-full flex flex-col gap-2">
                <label
                  htmlFor="book-genre"
                    className="text-[16px] text-[#131214] font-semibold text-nowrap"
                >
                  Genre:
                </label>
                <div className="dropdown-container w-full">
                  <select
                    id="book-gender"
                    className="w-full text-black"
                    placeholder="Enter the genre of the book"
                    style={{borderRadius:'6px',borderColor:"#1E1C80"}}
                    onChange={(e) => setSelectedGenre(e.target.value)}
                    value={selectedGenre}
                    disabled
                  >
                    {GENRES.map((genre) => (
                      <option value={genre} key={genre}>
                        {genre}
                      </option>
                    ))}
                  </select>
                  {/* <InputComponent
                    // type="text"
                    placeholder="Genre"
                    value={selectedGenre}
                    onChange={(e) => setSelectedGenre(e.target.value)}
                    isInGenreInput={true}
                  /> */}
                </div>
                {/* {!formErrors.authorName && <span className="error-message"></span>}
              {formErrors.authorName && <span className="error-message">{formErrors.authorName}</span>} */}
                <span
                  className={`error-message-upload ${
                    formErrors.genreName ? "show" : ""
                  }`}
                >
                  {formErrors.genreName}
                </span>
              </div>
              <div className="w-full flex flex-col gap-2 relative">
                <label
                  htmlFor="book-genre"
                    className="text-[16px] text-[#131214] font-semibold text-nowrap"
                >
                  Sub-Genre:
                </label>
                <div className="dropdown-container w-full">
                  <select
                    id="book-sub-genre"
                    className={`w-full round  ${selectedSubGenre.length ? "text-black" : "text-gray-400"}`}
                    placeholder="Enter the Sub-Genre of the book"
                    style={{borderRadius:'6px',borderColor:"#1E1C80", borderColor: formErrors.selectedSubGenre ? "#d32f2f" : ""}}
                    // value={selectedSubGenre}
                    onChange={handleSubGenreChange}
                  >
                    <option value="" disabled selected hidden>Select the Sub-Genre of the book</option>
                    {SUB_GENRES.map((subGenre) => (
                      <option value={subGenre} key={subGenre}>
                        {subGenre}
                      </option>
                    ))}
                  </select>
                </div>
                <span
                  className={`text-xs text-[#d32f2f] absolute -bottom-[22px] left-3 ${
                    formErrors.selectedSubGenre ? "show" : ""
                  }`}
                >
                  {formErrors.selectedSubGenre}
                </span>
              </div>
            </div>
          </div>
          {/* <div className="upload-row">
          <div className="upload-input">
            <div className="dropdown-container">
              <select
                className="round"
                onChange={(e) =>
                  handleTranslationTypeChange(e, "english")
                }
                value={translationTypeEnglish}
              >
                <option value="english">English</option>
                <option value="dutch">Dutch</option>
              </select>
            </div>

            
          </div>
        </div> */}
          <div className="upload-tooltip">
            <p className="upload-text form-title">Upload the book</p>
            <Tooltip title="Please upload a DOC/DOCX file.">
              <img src={info} alt="info" className="info-icon" />
            </Tooltip>
          </div>
          {/* @review-glossary-changes */}
          { flowType ==="glossaryReview" ? <div className="flex items-center gap-5">
            <div className="upload-row w-1/2">
            <label className="upload-label">English Book:</label>
            <div className="upload-input">
              <FileUploadInput
                clearInputField={handleClearInputFieldEnglish}
                selectedFile={englishTranslation}
                onChange={handleEnglishTranslationChange}
                placeholder="Click here to select and upload original book"
                onDragDrop={handleDragDrop}
                formSubmitted={formSubmitted}
                uploadFileError={formErrors?.englishTranslation}
              />
            </div>
            </div>
            <div className="upload-row w-1/2">
            <label className="upload-label">{getCapitalisedString(translationType)} Book:</label>
            <div className="upload-input">

              <FileUploadInput
                clearInputField={()=>{setTranslatedFile(null)}}
                selectedFile={translatedFile}
                onChange={handleTranslationFileChange}
                placeholder="Click here to select and upload translated book"
                onDragDrop={handleDragDrop}
                formSubmitted={formSubmitted}
                uploadFileError={formErrors?.translatedFile}
              />
            </div>
            </div>
          </div> :
          <div className="upload-row">
            <label className="upload-label">Original:</label>
            <div className="upload-input">

              <FileUploadInput
                clearInputField={handleClearInputFieldEnglish}
                selectedFile={englishTranslation}
                onChange={handleEnglishTranslationChange}
                placeholder="Click here to select and upload original book"
                onDragDrop={handleDragDrop}
                formSubmitted={formSubmitted}
                uploadFileError={formErrors?.englishTranslation}
              />
            </div>
          </div>}

          {/* ------------------------------------------------ advance settings ------------------------------------------------------------ */}
          <div className="upload-tooltip" style={{justifyContent: 'space-between', marginTop: '3rem'}}>
            <p className="upload-text form-title">Advance Settings</p>
            <div>
              <p className="upload-text expand-button form-title cursor-pointer" onClick={onExpandAdvancedSettings}>
                {
                  isAdvancedSettingsExpanded
                   ? "Collapse"
                    : "Expand"
                }
              </p>
            </div>
          </div>
          {
            isAdvancedSettingsExpanded
            &&
            <>
              {
                <div className="advance-setting-item">
                  <div className="advance-setting-row">
                    <div className="advance-setting-title">
                      <label className="advance-setting-label" >Live Learning</label>
                      <Tooltip title="This will allow the AI to continue learning from the edits you make!">
                        <img src={info} alt="info" className="info-icon" />
                      </Tooltip>
                    </div>
                    <Switch checked={isEarlyChapterGenerationEnabled} onChange={handleEarlyChapterGenerationChange} inputProps={{ style: { marginTop: '10px', scale: '1.8', opacity: 0 }}} />
                  </div>
                    {
                      isEarlyChapterGenerationEnabled
                      &&
                      <>
                        <div className="advance-setting-row">
                          <div className="advance-setting-title">
                            <label className="advance-setting-label" >Early Chapter Generation</label>
                          </div>
                          <Typography color="primary" sx={{marginRight: '8px'}}>{earlyChaptersCount}</Typography>
                        </div>
                        <div className="advance-setting-row" style={{padding: '8px', paddingRight: '12px'}}>
                          <Slider 
                            aria-label="Default" 
                            valueLabelDisplay="off" 
                            value={earlyChaptersCount} 
                            onChange={handleEarlyChapterCountChange}
                            min={0}
                            max={MAX_NUMBER_OF_EARLY_CHAPTERS_GENERATION_ALLOWED}
                          />
                        </div>
                        <div className="advance-setting-row">
                          <p style={{color: '#b3b3b3'}}>Only {MAX_NUMBER_OF_EARLY_CHAPTERS_GENERATION_ALLOWED} Chapters can be generated early as buffer </p>
                        </div>
                      </>
                    }
                </div>
              }
            </>
          }
          <br />
          {/* <div>
            {formErrors.englishTranslation && (
              <span
                style={{ display: "block", margin: "auto" }}
                className="error-message"
              >
                {formErrors.englishTranslation}
              </span>
            )}
          </div> */}
          {isDutchUploadInput && (
            <>
              <div className="upload-row">
                <label className="upload-label reference">Reference:</label>

                <div className="upload-input uploadRef">
                  {/* <div className="dropdown-container">
                  <select
                    className="round"
                    onChange={(e) =>
                      handleTranslationTypeChange(e, "dutch")
                    }
                    value={translationType}
                  >
                    <option value="english">English</option>
                    <option value="dutch">Dutch</option>
                  </select>
                </div> */}
                  <FileUploadInput
                    clearInputField={handleClearInputFieldDutch}
                    selectedFile={dutchTranslation}
                    onChange={handleDutchTranslationChange}
                    placeholder="Click here to select and upload translated book (optional)"
                    onDragDrop={handleDragDrop}
                    formSubmitted={formSubmitted}
                  />
                </div>
              </div>
              {formErrors.dutchTranslation && (
                <span
                  style={{ display: "block", margin: "auto" }}
                  className="error-message"
                >
                  {formErrors.dutchTranslation}
                </span>
              )}
            </>
          )}

          {/* <button type="button" onClick={handleDutchUploadInputShow} className="btn-shw-dutch">
          {textButtonForDutchShow}
        </button> */}

          <div className="py-4">
            <button
              type="submit"
              className={`btn-submit ${
                authorName && bookName && englishTranslation
                  ? "activeUpload"
                  : ""
              }`}
            >
              Upload & Translate
            </button>
          </div>
        </form>
      </div>
    );

    //         (
    //           <div>
    //             <div className="batch-upload-container">
    //               <div>
    //                 <div className="upload-row">
    //                   <div>
    //                     <label className="upload-label">Original:</label>
    //                     <br />

    //                     <div className="upload-input">
    //                       <div className="dropdown-container">
    //                         <select
    //                           className="round"
    //                           onChange={(e) => handleTranslationTypeChange(e, "english")}
    //                           value={translationTypeEnglish}
    //                         >
    //                           <option value="english">English</option>
    //                           <option value="dutch">Dutch</option>
    //                         </select>
    //                       </div>
    //                     </div>
    //                     <br />
    //                     <div>
    //                       <FolderUploadInput
    //                         title="Drag & drop or Select the folder that you wish to upload here"
    //                         onFolderSelect={handleFolderSelect}
    //                       />
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //               <div>
    //                 <div>
    //                   <div className="upload-row">
    //                     <div>
    //                       <label className="upload-label">Target:</label>
    //                       <br />

    //                       <div className="upload-input">
    //                         <div className="dropdown-container">
    //                           <select
    //                             className="round"
    //                             onChange={(e) => handleTranslationTypeChange(e, "dutch")}
    //                             value={translationType}
    //                           >
    //                             <option value="english">English</option>
    //                             <option value="dutch">Dutch</option>
    //                           </select>
    //                         </div>
    //                       </div>
    //                     </div>
    //                     <div>
    //                       <FolderUploadInput
    //                         title="Drag & drop or Select the folder that you wish to upload here
    // (optional)"
    //                         onFolderSelect={handleFolderSelect}
    //                       />
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>

    //             <br />
    //             <div className="hint-batch-upload">
    //               <AiFillInfoCircle className="fill-info" />
    //               Rename all your files in the format “Book title_language” before uploading. E.g. Romeo &
    //               Juliet_English
    //             </div>
    //             <br />
    //             <button type="submit" className="btn-submit">
    //               Upload
    //             </button>
    //           </div>
    //         )
  };

  return (
    <>
      <ToastContainer hideProgressBar={true} />
      {renderUploadHeader()}
      <div className="mt-[20px] p-8 sm:p-16 w-full flex gap-2 flex-col h-full max-w-[1200px] mr-auto">
        {renderTranslationInfo()}
        {renderTranslationProperties()}
        {renderBookInfoForm()}
      </div>
      {loading && <Loader />}
      {dialogOpen && (
        <DialogBox message={dialogMessage} onClose={handleDialogClose} />
      )}
      <Footer />
      <EmailModal
        title={bookName}
        showEmailModal={showEmailModal}
        email={email}
        setShowEmailModal={setShowEmailModal}
        setEmail={setEmail}
        submitEmailAndCallApi={submitEmailAndCallApi}
      />
    </>
  );
};

export default UploadBookScreenRG;
