import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as Library } from "../../assets/library.svg";
import { useDispatch, useSelector } from "react-redux";
import { setGlossaryScreenType, setPreAppliedGlossaryData } from "../../App/editor/editor.actions";
import { updateGlossaryPreAppliedData, updatePredefinedClassName } from "../../utils/glossaryUtils";
import { useParams } from "react-router-dom";
import { useSaveTranslatedChapter } from "../../hooks/useReviewTranslation";
import { updateUserActiveTime } from "../../utils/translationUtil";
import debounce from 'lodash.debounce';
import { setResetGlossaryMatchesScreen } from "../../App/Glossary/glossary.action";
function ViewGlossaryButton({
  // buttonPosition,handleAddToGlossary
  editor,
  editorType,
  individualScroll,
  setGlossarySectionToggle,
  setTextToAddInGlossary,
  glossarySectionToggle,
  chapter,
  setIsLayoutFixed,
  setTranslatedContent,
  translateEditor,
  setShowWarningPopup
}) {
  const successHandler = (data) => null;
  const {
    data,
    mutate: saveEditedTranslatedChapter,
    error,
    isPending,
    isSuccess,
  } = useSaveTranslatedChapter({ successHandler });

  const [highlightedText, setHighlightedText] = useState({
    text: "",
    occurrenceNo: 0,
  });
  const [buttonPosition, setButtonPosition] = useState({
    text: "",
    occurrenceNo: 0,
  });
  const {preAppliedGlossaryData}=useSelector((state)=>state.editor)
  const { loggedInUserEmail} = useSelector((state) => state.main);
  const dispatch = useDispatch();
  const [isApproved, setisApproved] = useState(false)
  const params=useParams()

  const disableHighlightingAndButton = () => {
    const activeElement = document.activeElement;
    let isEditorContentSelection = false;
    if (editorType === "translated") {
      isEditorContentSelection = activeElement.classList.contains(
        "ck-blurred"
      );
    }
    if (editorType === "source") {
      isEditorContentSelection =
        activeElement.tagName !== "INPUT" &&
        activeElement.tagName !== "TEXTAREA" &&
        !activeElement.isContentEditable;
    }
    if (isEditorContentSelection) {
      window.getSelection().removeAllRanges();
      setButtonPosition({ visible: false});
    }
  };
  
  
  const debouncedUpdateUserActiveTime = useCallback(
    debounce((translationId,loggedInUserEmail) =>{
      try {
        return updateUserActiveTime(translationId,loggedInUserEmail)
      } catch (error) {}
      }, 1000),
    []
  );

  const handleNativeSelection = (e) => {
    debouncedUpdateUserActiveTime(params.id,loggedInUserEmail)
    const approveButton = document.querySelector(
      ".approveButton"
    );
    if(isApproved || approveButton === e.target) return ;
    setTimeout(() => {
      const editorElement = editor.ui?.view?.editable?.element;
      const selection = window.getSelection();
      let selectedText = selection?.toString()?.trim();
      let selectedTextElement = selection?.focusNode?.parentElement;
      let translateSentence=""
      while (selectedTextElement && !selectedTextElement.classList.contains('predefined-added') && editorType==="translated") {
        selectedTextElement = selectedTextElement?.parentElement;
      }
      selectedTextElement=selectedTextElement?.classList?.contains('predefined-added')?selectedTextElement:null;
     
      let selectedGlossaryCardId=null;
      //@enable for scrolling
      if(selectedTextElement?.getAttribute("local_id")){
        // selectedGlossaryCardId=selectedTextElement.getAttribute("data-occurrence-id")
        selectedGlossaryCardId=selectedTextElement?.getAttribute("local_id")
      }
      selectedText = editorType==="translated" ? selectedGlossaryCardId : selectedText;
      const editorRange = document.createRange();
      editorRange.selectNodeContents(editorElement);
      // if (!editorElement.contains(e.toElement) && !glossarySectionToggle)
      if (!editorElement.contains(e.toElement))
        disableHighlightingAndButton();
      let isEditorReadOnly=editorType==="translated"?editor?.isReadOnly : translateEditor?.isReadOnly
      if (
        selectedText &&
        editorElement.contains(selection.anchorNode)
        &&
        !isEditorReadOnly
        // &&
        // !glossarySectionToggle
      ) {
        // Get the range of the selected text
        const range = selection.getRangeAt(0);
        setHighlightedText({ text: selectedText.trim(), occurrenceNo: 0 });
        const rect = range?.getBoundingClientRect();
        const mainContainer = document.querySelector(
          individualScroll ? `#${editorType}Container` : "#bookEditorContainer"
        );
        let parentContainer=document.querySelector(`#${editorType}`);
        let parentContainerRect=parentContainer.getBoundingClientRect();
        if (!mainContainer) return;
        // Set button position relative to selected text
        let rectLeft = (rect.left - parentContainerRect.left) + rect.width / 2 - 80;
        let positonLeft = glossarySectionToggle
          ? rectLeft + mainContainer?.scrollLeft
          : (rectLeft + mainContainer.scrollLeft);
        let positionTop =  (rect.top - 30 - 30) + mainContainer?.scrollTop
        setButtonPosition({
          top: positionTop, // Adjust for scroll position
          left: positonLeft,
          visible: true,
        });
      } else {
        setButtonPosition({ visible: false});
      }
    }, 0);
  };
 
  const disableButtonOnScrollEvent=()=>{
    setButtonPosition({ visible: false});
  }

  useEffect(() => {
    if(isApproved) return;
    const editorElement = editor.ui?.view?.editable?.element;
    let translateElement = document.querySelector(`#${editorType}`);
    if (editorElement && ((editorType==="translated" && process.env.REACT_APP_ENVIRONMENT==="dev") || editorType==="source")) {
      // Attach native selection listeners
      translateElement?.addEventListener("mouseup", handleNativeSelection);
      translateElement?.addEventListener("keyup", handleNativeSelection);
      if (individualScroll) {
        let sourceElement = document.querySelector(`#${editorType}Container`);
        sourceElement.addEventListener("scroll", disableButtonOnScrollEvent);
      } else {
        const bookContainer = document.querySelector("#bookEditorContainer");
        bookContainer.addEventListener("scroll", disableButtonOnScrollEvent);
      }
    }

    const handleClickOutside = (event) => {
      
      const addToGlossaryButton = document.querySelector(
        ".addToGlossaryButton"
      );
      const reviewButton = document.querySelector(
        ".reviewButton"
      );

      const approveButton = document.querySelector(
        ".approveButton"
      );
      if (
        !editorElement?.contains(event.target) &&
        reviewButton !== event.target && approveButton !== event.target && addToGlossaryButton !== event.target
      ) {
        let targetEventClassList=event?.target?.classList || []
        if (
          (targetEventClassList?.contains("predefined-added") ||
          targetEventClassList?.contains("predefined-skipped"))
        )
          return;
        setButtonPosition({ visible: false}); // Hide button when clicking outside the editor
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      if (editor && !isApproved) {
        translateElement?.removeEventListener("mouseup", handleNativeSelection);
        translateElement?.removeEventListener("keyup", handleNativeSelection);
        setTextToAddInGlossary({});
        setButtonPosition({ top: 0, left: 0, visible: false });
        if (individualScroll) {
          let sourceElement = document.querySelector(`#${editorType}Container`);
          sourceElement?.removeEventListener(
            "scroll",
            disableHighlightingAndButton
          );
        } else {
          const bookContainer = document.querySelector("#bookEditorContainer");
          bookContainer?.removeEventListener(
            "scroll",
            disableHighlightingAndButton
          );
        }
      }
    };
  }, [editor, glossarySectionToggle, individualScroll,isApproved]);

  const handleAddToGlossary = (isForceFullyOpen) => {
    if (!highlightedText.text) return;
    if(glossarySectionToggle  && isForceFullyOpen!==true){
      setShowWarningPopup({visible:true,handleYesBtn:handleAddToGlossary,handleNoBtn:disableButtonOnScrollEvent})
      return ;
    }
    setTextToAddInGlossary(highlightedText);
    dispatch(setResetGlossaryMatchesScreen({status:true,englishWord:highlightedText?.text?.trim()}))
    setButtonPosition({ top: 0, left: 0, visible: false });
    dispatch(setGlossaryScreenType(editorType==="source"?"glossaryMatches":"reviewGlossary"));
    setGlossarySectionToggle(true) 
  };

  const handleApproveGlossary = async (e) => {
   if(isApproved) return;
    e?.stopPropagation();
    try {
      if (!highlightedText.text) return;
      setisApproved(true);
      // setButtonPosition({...buttonPosition, visible: true })
      const updatedData = await updateGlossaryPreAppliedData(
        {
          unique_string: params.id,
          local_id: highlightedText.text,
          status: "approved",
          chapter_id: `chapter_${chapter.chapter_number}`,
        },
        preAppliedGlossaryData
      );
        
        dispatch(setPreAppliedGlossaryData(updatedData));
        let editedTranslation = editor.getData();
        editedTranslation = updatePredefinedClassName(
          editedTranslation,
          highlightedText.text
        );
        setTranslatedContent(editedTranslation);
        setIsLayoutFixed(false);
        const store = editor.config.get("store").getState();
        saveEditedTranslatedChapter({
          translation_id: params.id,
          chapter: store.editor.currentChapter,
          translated_data: editedTranslation,
        });
        setButtonPosition({ top: 0, left: 0, visible: false });
        setisApproved(false);
    } catch (error) {}
  };
  
  return (
    <>
      {buttonPosition.visible && (
        <div
          style={{
            position: "absolute",
            top: buttonPosition.top - 175, // Adjust for button height
            left: buttonPosition.left,
            zIndex: 99999,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
            width: "150px",
          }}
        >
          {editorType === "source" && (
            <div
              onClick={handleAddToGlossary}
              className="px-2 addToGlossaryButton relative rounded-sm flex bg-white text-[#3B37FF] cursor-pointer items-center text-sm gap-2  py-[6px]"
            >
              <Library /> View glossary
            </div>
          )} 
          {editorType==="translated" && process.env.REACT_APP_ENVIRONMENT==="dev" &&  (
            <div className="px-2 font-[Roboto] cursor-pointer addToGlossaryButton text-center addToGlossaryButton relative rounded-sm flex bg-white text-[#1E1C80] items-center justify-center text-sm gap-2  py-[6px]">
              <div
                onClick={handleApproveGlossary}
                className={`${isApproved?"text-[#979797]":"text-[#3B37FF]"} text-sm approveButton`}
              >
               { isApproved?"Approved":"Approve"}
              </div>{" "}
              {"|"}{" "}
              <div
                onClick={handleAddToGlossary}
                className="text-[#1E1C80] reviewButton relative text-sm"
              >
                Review
              </div>
            </div>
          )}
          <div
            className="w-2 bg-white absolute left-[50%] shadow-lg -bottom-[3px] h-2"
            style={{
              transform: "rotate(45deg)",
              borderRadius: "0px 0px 2px 0px",
            }}
          ></div>
        </div>
      )}
    </>
  );
}

export default ViewGlossaryButton;
