import React, { useCallback, useEffect, useRef, useState } from "react";
import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import { getCapitalisedString } from "../../utils/translationUtil";
import { ReactComponent as GlossaryInfoIcon } from "../../assets/glossaryInfoIcon.svg";
import { Button, CircularProgress, Tooltip, styled } from "@mui/material";
import { setGlossaryList } from "../../App/editor/editor.actions";
import { toast } from "react-toastify";
import { checkDynamicText, removeWhitespaceAndNewlines } from "../../utils/glossaryUtils";
import {
  setIsSmartGlossary,
  setResetGlossaryMatchesScreen,
  setSearchedEnglishWord,
} from "../../App/Glossary/glossary.action";
import "./CreateGlossary.scss";
import SmartGlossaryRecommendation from "../SmartGlossaryRecommendation/SmartGlossaryRecommendation";
import { cn } from "../../lib/utils";
function CreateGlossary({
  englishWord,
  handleUpdateGlossary,
  setOpenedGlossaryData,
  operationType,
  setSelectedGlossary,
  setHighlightedEnglishText,
  smartGlossaryRecommendations,
  setSmartGlossaryRecommendations,
  findOverBookData,
  fetchSuggestionsLoading,
  setFetchSuggestionsLoading,
  setGlossaryInputData,
  glossaryInputData,
  viewAllFeilds,
  setViewAllFeilds
}) {
  const { userOpenedBookInfo } = useSelector((state) => state.main);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [error, setError] = useState({ isAlreadyExists: false });
  const { glossaryList } = useSelector((state) => state.editor);
  const [loading, setLoading] = useState(false);
 

  // useEffect(() => {
  //   if (englishWord && (glossaryInputData.englishWord)) {
  //     setGlossaryInputData({ ...glossaryInputData, englishWord: englishWord });
  //   }
  // }, [englishWord]);

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "black",
      color: "white",
      width: "170px",
    },
    "& .MuiTooltip-arrow": {
      color: "black", // Arrow color
    },
  }));

  const debouncedUpdateEnglishText = useCallback(
    debounce((inputText) => {
      if (
        glossaryList?.find(
          (glossary) =>{
            let englishWord=removeWhitespaceAndNewlines(glossary?.english_word?.toLowerCase().trim())
            let inputEnglishWord=removeWhitespaceAndNewlines(inputText?.toLowerCase().trim())
            // glossary?.english_word?.toLowerCase().trim() ===
            // inputText?.toLowerCase().trim()
             return englishWord===inputEnglishWord
          }
        )
      ) {
        setError((prevError) => ({ ...prevError, isAlreadyExists: true }));
        return;
      } else {
        setError((prevError) => ({ ...prevError, isAlreadyExists: false }));
      }
      let isDynamic = checkDynamicText(inputText);
      dispatch(setIsSmartGlossary(isDynamic));
      setHighlightedEnglishText(inputText);
      dispatch(setSearchedEnglishWord(inputText));
      dispatch(
        setResetGlossaryMatchesScreen({
          englishWord: inputText.trim(),
          status: false,
        })
      );
    },800),
    []
  );

  const handleGlossaryInputChange = (e) => {
    const inputElement = e.target;
    let value = inputElement.value;
    if (e.target.name === "englishWord") {
      const cursorPosition = inputElement.selectionStart; // Capture current cursor position
      value = value.replaceAll("[", "<").replaceAll("]", ">");
      requestAnimationFrame(() => {
        inputElement.setSelectionRange(cursorPosition, cursorPosition);
      });
      debouncedUpdateEnglishText(value.trim()); 
    }

    if(e.target.name==="replacement_dutch_word" || e.target.name === "englishWord" || e.target.name === "whenToApply" || e.target.name === "exceptions") {
      inputElement.style.height = 'auto';
      inputElement.style.height =  `${inputElement.scrollHeight}px`;
    }

    setGlossaryInputData({
      ...glossaryInputData,
      [e.target.name]: e?.target?.value,
    });
  };

  const isVariantAlreadyExists = (variant, variantList) => {
    if (operationType === "update") return;

    let isThisVariantAlreadyExists = variantList?.find(
      (dutchVariant) =>
        dutchVariant?.replacement_dutch_word?.toLowerCase() ===
        variant?.trim()?.toLocaleLowerCase()
    );

    if (isThisVariantAlreadyExists) {
      toast(`Variant already exists`, {
        type: "warning",
        autoClose: 3000,
      });
      return true;
    }

    return false;
  };

  const isEnglishWordExists = (oldEnglishWord, newEnglishWord) => {
    if (oldEnglishWord?.toLowerCase() === newEnglishWord?.toLowerCase())
      return false;
    let isGlossaryExists = glossaryList.find(
      (glossary) =>
        glossary?.english_word?.toLowerCase() === newEnglishWord?.toLowerCase()
    );
    if (isGlossaryExists) {
      toast(`English Word already exists`, {
        type: "warning",
        autoClose: 3000,
      });
      return true;
    }
    return false;
  };

  const handleSaveGlossary = async () => {
    // updateUserActiveTime(params.id, loggedInUserEmail);
    if (loading) return;
    if (hasAllInputs()) {
      toast(`Kindly complete all the required fields before proceeding.`, {
        type: "warning",
        autoClose: 3000,
      });
      return;
    }
    if (!viewAllFeilds) {
      setExpanded(false);
      setViewAllFeilds(true);
      return;
    }
    if (
      !glossaryInputData.replacement_dutch_word ||
      !glossaryInputData.scope ||
      !glossaryInputData.whenToApply
    )
      return;

    const isGlossaryExist = glossaryList?.find(
      (glossary) =>
        glossary?.english_word.trim().toLowerCase() ===
        glossaryInputData.englishWord.trim().toLowerCase()
    );

    if (
      isGlossaryExist
      //  &&
      // isVariantAlreadyExists(
      //   glossaryInputData.replacement_dutch_word.trim(),
      //   isGlossaryExist.dutch_word_array
      // )
    ) {
      return;
    }
    setLoading(true);
    let updateGlossaryData = {
      action: operationType,
      inputData: glossaryInputData,
      englishText:  glossaryInputData.englishWord,
    };

    // if (operationType === "update")
    //   updateGlossaryData.variant = variantDataForEdit?.replacement_dutch_word;

    const newGlossary = await handleUpdateGlossary(updateGlossaryData);
    toast(`Glossary created successfully!`, {
      type: "success",
      autoClose: 3000,
    });
    

    if (setOpenedGlossaryData) {
      setOpenedGlossaryData(newGlossary);
    }
    
    dispatch(setGlossaryList([newGlossary,...glossaryList]))
    // let updatedGlossaryList = glossaryList;
    // if (isGlossaryExist && updatedVariants?.length) {
    //   updatedGlossaryList = updatedGlossaryList.map((glossary) => {
    //     if (glossary.english_word === englishWord) {
    //       glossary.dutch_word_array = updatedVariants;
    //     }
    //     return glossary;
    //   });
    // }

    // dispatch(setGlossaryList(updatedGlossaryList));

    setSelectedGlossary({
      englishWord:  glossaryInputData.englishWord,
      variant: glossaryInputData?.replacement_dutch_word,
    });

    // if (setVariantDataForEdit)
    //   setVariantDataForEdit({
    //     replacement_dutch_word: "",
    //     scope: "",
    //     whenToApply: "",
    //     exceptions: "",
    //   });

    
    setLoading(false);
    setGlossaryInputData({
      replacement_dutch_word: "",
      scope: "",
      whenToApply: "",
      exceptions: "",
      englishWord:  glossaryInputData.englishWord,
    });
    setTimeout((
    )=>{
      setViewAllFeilds(false);
    })
  };
  const hasAllInputs = () => {
    if (loading) return true;
    if (
      viewAllFeilds &&
      (!glossaryInputData.replacement_dutch_word ||
        !glossaryInputData.scope ||
        !glossaryInputData.whenToApply ||
        !glossaryInputData.englishWord)
    )
      return true;

    if (
      !viewAllFeilds &&
      (!glossaryInputData.replacement_dutch_word ||
        !glossaryInputData.englishWord)
    )
      return true;

    return false;
  };

  return (
    <div className="flex flex-col gap-3 bg-[#F5F7FF] rounded-lg p-3">
      <div
        style={{ border: "1px solid rgba(57, 97, 255, 0.15)" }}
        className="flex flex-col rounded-[5px] bg-white py-4 px-5 gap-4"
      >
        <div className="flex flex-col gap-2">
          <span className="text-[12px] text-[#000000D9] flex items-center gap-1 font-[poppins] font-[500]">
            English Sentence
            <span
              style={{ fontFamily: "SimSong" }}
              className="text-[#FF4D4F] text-sm"
            >
              *
            </span>
          </span>
          <div className="flex flex-col gap-1">
            <textarea
              style={{
                fontFamily: "Times New Roman",
                border: error.isAlreadyExists
                  ? "1px solid #D32F2F"
                  : "1px solid #D9D9D9",
               }}
              placeholder="Type here"
              rows={1}
              className={cn(
                "w-full text-sm  resize-none  overflow-hidden box-border rounded-[4px] englishTextInput px-3 py-[5px] outline-none"
              )}
              name="englishWord"
              value={glossaryInputData.englishWord}
              onChange={handleGlossaryInputChange}
              autoComplete="off"
            />
            {error.isAlreadyExists && (
              <span className="text-[#D32F2F] text-[10px] pr-3">
                English text is already exists
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-[12px] text-[#000000D9] flex items-center gap-1 font-[poppins] font-[500]">
            {getCapitalisedString(userOpenedBookInfo?.translatedTo)} Variant{" "}
            <span
              style={{ fontFamily: "SimSong" }}
              className="text-[#FF4D4F] text-sm"
            >
              *
            </span>
          </span>
          <textarea
            style={{
              border: "1px solid #D9D9D9",
              fontFamily: "Times New Roman",
            }}
            placeholder="Type here"
            className="w-full text-sm resize-none translatedVariant  overflow-hidden box-border h-fit rounded-[4px]  px-3 py-[5px] outline-none"
            name="replacement_dutch_word"
            value={glossaryInputData.replacement_dutch_word}
            onChange={handleGlossaryInputChange}
            autoComplete="off"
            rows={1}
          />
        </div>
        {viewAllFeilds && (
          <div
            style={{ border: "0.5px dashed rgba(30, 28, 128, 0.60)" }}
            className="flex flex-col gap-[6px] p-3 rounded"
          >
            <div className="w-full flex flex-col gap-2 relative">
              <label
                htmlFor="book-genre"
                className="text-[12px] text-[#000000D9] flex items-center gap-1 font-[poppins] font-[500]"
              >
                Apply this glossary to
                <span
                  style={{ fontFamily: "SimSong" }}
                  className="text-[#FF4D4F] text-sm"
                >
                  *
                </span>
              </label>

              <div className=" w-full">
                <select
                  id="book-sub-genre"
                  className={`w-full  ${
                    glossaryInputData.scope ? "text-[#242424]" : "text-[gray]"
                  } py-[5px] px-3 outline-none text-xs`}
                  placeholder="Enter the Sub-Genre of the book"
                  style={{
                    borderRadius: "4px",
                    borderColor: "#D9D9D9",
                    fontSize: "12px",
                  }}
                  onChange={handleGlossaryInputChange}
                  name="scope"
                  value={glossaryInputData.scope}
                >
                  <option value="" className="" disabled selected hidden>
                    Select apply this glossary to
                  </option>
                  <option className="text-xs   " value={"Book"} key={"book"}>
                    Current book: {userOpenedBookInfo.title}
                  </option>
                  <option className="text-xs " value={"Author"} key={"author"}>
                    Author: {userOpenedBookInfo?.author}
                  </option>
                  <option className="text-xs " value={"Genre"} key={"genre"}>
                    Genre: {userOpenedBookInfo?.genre ?? "Romance"}
                  </option>
                  <option
                    className="text-xs "
                    value={"Language"}
                    key={"language"}
                  >
                    Language: {userOpenedBookInfo?.translatedTo ?? "Dutch"}
                  </option>
                </select>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-1">
                <span className="text-[12px] flex items-center gap-1 text-[#000000D9] font-[poppins] font-[500]">
                  When to apply
                  <span
                    style={{ fontFamily: "SimSong" }}
                    className="text-[#FF4D4F] text-sm"
                  >
                    *
                  </span>
                </span>
                <CustomTooltip
                  sx={{
                    "& .MuiTooltip-tooltip": {
                      backgroundColor: "black",
                      color: "white",
                    },
                  }}
                  placement="top-start"
                  arrow
                  title={
                    <React.Fragment>
                      <b>Example</b>
                      : Light <br />
                      Use light when referring to brightness, like switch on the
                      light.
                    </React.Fragment>
                  }
                >
                  <GlossaryInfoIcon className="cursor-pointer mb-[2px] text-[#999898] w-[14px] h-[14px]" />
                </CustomTooltip>
              </div>
              <textarea
                style={{ border: "1px solid #D9D9D9", fontFamily: "Poppins" }}
                onChange={handleGlossaryInputChange}
                name="whenToApply"
                placeholder="Type here"
                value={glossaryInputData.whenToApply}
                className="w-full h-[54px] resize-none  overflow-hidden box-border whenToApply  text-xs rounded-[4px]  px-3 py-[5px] outline-none "
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-1">
                <span className="text-[12px] flex items-center gap-1 text-[#000000D9] font-[poppins] font-[500]">
                  When to not apply
                  {/* <span
                       style={{ fontFamily: "SimSong" }}
                       className="text-[#FF4D4F] text-sm"
                     >
                       *
                     </span> */}
                </span>
                <CustomTooltip
                  sx={{
                    "& .MuiTooltip-tooltip": {
                      backgroundColor: "black",
                      color: "white",
                      width: "170px",
                    },
                  }}
                  placement="top-start"
                  arrow
                  title={
                    <React.Fragment>
                      <b>Example</b>
                      : Light <br />
                      Avoid using light for non-brightness contexts, like light
                      weight.
                    </React.Fragment>
                  }
                >
                  <GlossaryInfoIcon className="cursor-pointer mb-[2px] text-[#999898] w-[14px] h-[14px]" />
                </CustomTooltip>
                <span className="text-[#999898] text-[12px]">(optional)</span>
              </div>
              <textarea
                style={{ border: "1px solid #D9D9D9", fontFamily: "Poppins" }}
                onChange={handleGlossaryInputChange}
                name="exceptions"
                placeholder="Type here"
                value={glossaryInputData.exceptions}
                autoComplete="off"
                className="w-full h-[54px] whenToApply resize-none  overflow-hidden box-border  text-xs  rounded-[4px]  px-3 py-[5px] outline-none "
              />
            </div>
          </div>
        )}
        <div className="flex items-center gap-[17px] text-xs">
          <span
            className="cursor-pointer text-[#000]"
            onClick={() => {
              if (viewAllFeilds) {
                setViewAllFeilds(false);
              }
              setGlossaryInputData({
                replacement_dutch_word: "",
                scope: "",
                whenToApply: "",
                exceptions: "",
                englishWord: englishWord,
              });
            }}
          >
            {viewAllFeilds ? "Cancel" : "Reset"}
          </span>
          <div className="flex items-center gap-2">
            <Button
              disabled={loading}
              onClick={handleSaveGlossary}
              style={{ padding: "2px 5px" }}
              variant="outlined"
              size="small"
              className="text-[#3B37FF] gap-1 rounded flex  cursor-pointer"
            >
              {viewAllFeilds ? (loading ? <><CircularProgress color="inherit" size={11} className="mb-[2px]" /> Saving.. </> : "Save") : "Next"}
            </Button>
            {/* {loading && <CircularProgress size={11} className="mb-[2px]" />} */}
          </div>
        </div>
      </div>
      <SmartGlossaryRecommendation
        smartGlossaryRecommendations={smartGlossaryRecommendations}
        setSmartGlossaryRecommendations={setSmartGlossaryRecommendations}
        debouncedUpdateEnglishText={debouncedUpdateEnglishText}
        setGlossaryInputData={setGlossaryInputData}
        englishWord={englishWord}
        findOverBookData={findOverBookData}
        fetchSuggestionsLoading={fetchSuggestionsLoading}
        setFetchSuggestionsLoading={setFetchSuggestionsLoading}
        setExpanded={setExpanded}
        expanded={expanded}
      />
    </div>
  );
}

export default CreateGlossary;
