import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import usePagination from "@mui/material/usePagination/usePagination";
import { useParams } from "react-router-dom";

import { Button, Tooltip, styled } from "@mui/material";
import { useSelector } from "react-redux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DoneIcon from "@mui/icons-material/Done";
import { ReactComponent as Library } from "../../assets/libraryFilled.svg";
import {
  checkDynamicText,
  getGlossaryForReview,
  highlightSentenceInEditor,
  removeHighlighting,
  scroll,
  updateReviewedGlossary,
} from "../../utils/glossaryUtils";
import {
  CircularProgress,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";
import EditGlossaryForm from "../EditGlossaryForm/EditGlossaryForm";
import store from "../../App/store";
import { updateUserActiveTime } from "../../utils/translationUtil";
import GlossaryRejectionFeedback from "../GlossaryRejectionFeedback/GlossaryRejectionFeedback";
import ShowReviewedGlossary from "../ShowReviewedGlossary/ShowReviewedGlossary";
function GlossaryReviewPannel({
  handleChapterChange,
  fetchTranslatedChapterPending,
  individualScroll,
  setSourceContent,
  sourceContent,
  setIsLayoutFixed,
  setTranslatedContent,
  translatedContent,
}) {
  const params = useParams();

  const [glossaryData, setGlossaryData] = useState([]);
  const [pendingGlossaries, setPendingGlossaries] = useState([]);
  const [showFeedBackDropdown, setShowFeedBackDropdown] = useState(false);
  const [isHighlightedSentence, setIsHighlightedSentence] = useState(false);
  const [loading, setLoading] = useState(false);
  const { loggedInUserEmail, userOpenedBookInfo } = useSelector(
    (state) => state.main
  );
  const [showRejectionMessage, setShowRejectionMessage] = useState(false);
  const [glossaryRedirectionData, setGlossaryRedirectionData] = useState({});
  const [statusLoading, setStatusLoading] = useState(false);
  const [glossaryInputData, setGlossaryInputData] = useState({
    replacement_dutch_word: "",
    scope: "",
    whenToApply: "",
    exceptions: "",
    englishWord: "",
    originalDutchText: "",
    originalEnglishText: "",
  });

  const fetchGlossariesForReview = async () => {
    setLoading(true);
    const data = await getGlossaryForReview(params.id);
    if (!data?.length) {
      setLoading(false);
      return;
    }
    setGlossaryData(data);
    let pendingGlossaries = data.filter(
      (glossary) =>
        glossary.status !== "rejected" && glossary.status !== "approved"
    );
    // handleGlossaryRedirection(Number(pendingGlossaries[0].chapter_number,pendingGlossaries[0].original_english_word))
    setPendingGlossaries(pendingGlossaries);
    setLoading(false);
  };

  useEffect(() => {
    fetchGlossariesForReview();
  }, []);

  const handleCloseGlossaryPannel = () => {
    const glossaryButton = document.querySelector(
      '[data-cke-tooltip-text="Glossary"]'
    );
    glossaryButton?.click();
  };


  useEffect(() => {
    if (fetchTranslatedChapterPending) return;
    const currentChapterNo = store.getState().editor.currentChapter;
    if (
      (pendingGlossaries?.[0]?.chapter_number && !isHighlightedSentence) ||
      pendingGlossaries?.[0]?.chapter_number === currentChapterNo
    ) {
      let variantData =
        pendingGlossaries?.[0]?.dutch_word_array?.[0]
          ?.replacement_dutch_word_original || pendingGlossaries?.[0]?.dutch_word_array?.[0]
          ?.replacement_dutch_word
      let englishOriginalPara = !checkDynamicText(
        pendingGlossaries[0].english_word
      )
        ? pendingGlossaries[0].english_word
        : pendingGlossaries[0].original_english_word;
      handleGlossaryRedirectionAndHighlighting(
        Number(pendingGlossaries[0].chapter_number),
        englishOriginalPara,
        variantData
      );
      setIsHighlightedSentence(true);
    }
    if (glossaryRedirectionData.chapterNo) {
      handleGlossaryRedirectionAndHighlighting(
        glossaryRedirectionData.chapterNo,
        glossaryRedirectionData.sourceSentence,
        glossaryRedirectionData.translatedSentence
      );
      setGlossaryRedirectionData({});
    }
  }, [fetchTranslatedChapterPending, pendingGlossaries]);

  const handleGlossaryRedirectionAndHighlighting = (
    glossaryChapterNo,
    sourceSentence,
    translatedSentence
  ) => {
    const currentChapterNo = store.getState().editor.currentChapter;

    if (currentChapterNo !== glossaryChapterNo) {
      setGlossaryRedirectionData({
        chapterNo: glossaryChapterNo,
        sourceSentence,
        translatedSentence,
      });
      handleChapterChange(glossaryChapterNo);
    } else {
      if (sourceSentence) {
        highlightSentenceInEditor(
          sourceSentence?.trim(),
          sourceContent,
          setSourceContent,
          setIsLayoutFixed,
          "source"
        );
      }
      if (translatedSentence) {
        highlightSentenceInEditor(
          translatedSentence?.trim(),
          translatedContent,
          setTranslatedContent,
          setIsLayoutFixed,
          "translated"
        );
      }
      setTimeout(() => {
        let highlightedText = document.querySelector(
          ".source-glossaryReviewText"
        );
        if (highlightedText) {
          scroll(highlightedText, individualScroll);
        }
      }, 500);
    }
  };

  const getUpdatedPayload = (glossary, glossaryInput, status) => {
    let updatedDutchVariant = glossary.dutch_word_array[0];
    updatedDutchVariant = {
      ...updatedDutchVariant,
      variant_scope: glossaryInput.scope,
      replacement_dutch_word: glossaryInput.replacement_dutch_word,
      instructions: {
        exception: glossaryInput.exceptions,
        whenToApply: glossaryInput.whenToApply,
      },
    };
    let updatedGlossary = {
      ...glossary,
      dutch_word_array: [updatedDutchVariant],
      english_word: glossaryInput.englishWord,
      status: status,
      approved_by: loggedInUserEmail || null,
    };
    return updatedGlossary;
  };

  const isMandatoryFeildsAreFilled = () => {
    if (
      !glossaryInputData.replacement_dutch_word ||
      !glossaryInputData.scope ||
      !glossaryInputData.whenToApply ||
      !glossaryInputData.englishWord ||
      !glossaryInputData.originalDutchText ||
      !glossaryInputData.originalEnglishText
    ) {
      toast(`Kindly complete all the required fields before proceeding.`, {
        type: "warning",
        autoClose: 3000,
      });
      return false;
    }
    return true;
  };

  const updateGlossaryData=(updatedGlossary)=>{
    setGlossaryData((prev)=>prev.map((glssary)=>{
          if(glssary.id===updatedGlossary.id){
            return updatedGlossary
          }
          return glssary;
    }))
  }

  const handleApproveOrRejectGlossary = async (type, reason) => {
    updateUserActiveTime(params.id, loggedInUserEmail);
    if (!pendingGlossaries.length) return;
    if (type === "approved" && !isMandatoryFeildsAreFilled()) return;
    setStatusLoading(type);
    if (pendingGlossaries.length === 1) {
      setPendingGlossaries([]);
    }
    let payload = pendingGlossaries[0];
    if (type === "approved") {
      payload = getUpdatedPayload(
        pendingGlossaries[0],
        glossaryInputData,
        type
      );
    } else {
      payload.status = "rejected";
      payload.rejection_reason = reason || null;
    }
    removeHighlighting(
      sourceContent,
      translatedContent,
      setSourceContent,
      setTranslatedContent,
      setIsLayoutFixed
    );
    await updateReviewedGlossary(payload);
    updateGlossaryData(payload);
    setShowRejectionMessage(type);
    setTimeout(() => {
      setShowRejectionMessage("");
    }, 3000);

    setIsHighlightedSentence(false);
    setPendingGlossaries((prev) => {
      if (!prev.length) return [];
      let updatedGlossaries = [...prev];
      updatedGlossaries.shift();
      return updatedGlossaries;
    });
    setStatusLoading("");
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 3,
    borderRadius: 5,
    width: "auto",
    transition: "all 1.5s ease-in-out",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
      ...theme.applyStyles("dark", {
        backgroundColor: theme.palette.grey[800],
      }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      transition: "width 1.5s ease",
      backgroundColor:
        "linear-gradient(117deg, #3B37FF -5.93%, #1E1C80 125.04%))",
      ...theme.applyStyles("dark", {
        backgroundColor:
          "linear-gradient(117deg, #3B37FF -5.93%, #1E1C80 125.04%))",
      }),
    },
  }));


  const isNoGlossaryToReview=()=>{
    return (!pendingGlossaries.length && glossaryData.length)
  }

  return (
    <div
      style={{ height: "calc(100dvh - 133px - 20px)" }}
      className="w-[750px] shrink-0 mt-[150px] rounded-md  relative ml-4 "
    >
      <div
        style={{ height: "calc(100dvh - 133px - 20px)" }}
        className={`w-[750px] shrink-0 top-[100px] left-4 glossaryContainer  fixed  gap-2 flex 
            "pb-[67.46px]" 
           flex-col px-6 pt-6 bg-[#FFFFFF] my-12  rounded-md`}
      >
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <Library/>
            <span className="text-xl font-[Roboto] font-medium text-[#3B37FF]">
              {isNoGlossaryToReview()?"Glossary Review Completed":"Review glossary"}
            </span>
          </div>
        </div>
        {!isNoGlossaryToReview() && <>  <div className="flex justify-end -mt-2 w-full">
          <span className="text-[#3B37FF] pb-[2px] text-sm">
            {glossaryData?.length - pendingGlossaries?.length}
          </span>
          <span className="text-[#5F6368] pb-[2px] text-sm">
            /{glossaryData?.length} glossary reviewed
          </span>
        </div>
        <div className="-mt-2 w-full">
          <BorderLinearProgress
            variant="determinate"
            value={
              (100 / glossaryData?.length || 0) *
                (glossaryData?.length - pendingGlossaries?.length) || 0
            }
          />
        </div> </>}
        {loading ? (
          <div className="flex justify-center items-center h-full">
            {" "}
            Loading...{" "}
          </div>
        )  : isNoGlossaryToReview()? <ShowReviewedGlossary fetchGlossariesForReview={fetchGlossariesForReview} glossaryData={glossaryData}/>: pendingGlossaries.length < 1 ? (
          <div className="flex justify-center items-center h-full">
            {" "}
            No glossaries to review
          </div>
        ) : (
          <EditGlossaryForm
            glossaryInputData={glossaryInputData}
            setGlossaryInputData={setGlossaryInputData}
            glossary={pendingGlossaries[0]}
          />
        )}
       {!isNoGlossaryToReview() && <div
          style={{ boxShadow: "0px -6px 20px 0px rgba(0, 0, 0, 0.05)" }}
          className="footer py-2 flex justify-between items-center right-0 px-3 absolute bottom-0 w-full "
        >
          <div className="flex gap-8 justify-center w-full items-center">
            <Button
              onClick={() => setShowFeedBackDropdown(true)}
              disabled={
                loading ||
                !pendingGlossaries.length ||
                statusLoading === "rejected"
                  ? true
                  : false
              }
              className="flex rounded-[4px] font-medium text-[#FD0007]  relative text-base py-4 px-4 gap-2"
            >
              <div className="flex items-center  gap-2">
                <CloseRoundedIcon />
                {statusLoading === "rejected" ? "Rejecting.." : "Reject"}
              </div>
            </Button>
            <div className="relative">
              <Button
                disabled={
                  loading ||
                  !pendingGlossaries.length ||
                  statusLoading === "approved"
                    ? true
                    : false
                }
                onClick={() => handleApproveOrRejectGlossary("approved")}
                variant="contained"
                className="flex rounded-[4px] fixAll relative text-base py-2 px-4 gap-2"
              >
                <DoneIcon />
                {statusLoading === "approved"
                  ? "Approving.."
                  : "Approve & Next"}
              </Button>
            </div>
          </div>
        </div>}
        {showFeedBackDropdown && (
          <GlossaryRejectionFeedback
            setShowFeedBackDropdown={setShowFeedBackDropdown}
            handleApproveOrRejectGlossary={(reason) => {
              handleApproveOrRejectGlossary("rejected", reason);
            }}
          />
        )}
        {showRejectionMessage && (
          <div
            style={{
              boxShadow:
                "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
              border: "1px solid #F2F4F7",
            }}
            className="flex py-4 pl-6 rounded-xl z-10 bg-[#F5F5FF] text-sm absolute right-4 bottom-24 font-semibold pr-4 gap-4 items-center"
          >
            Glossary {showRejectionMessage}{" "}
            <CloseRoundedIcon
              onClick={() => {
                setShowRejectionMessage("");
              }}
              className="text-[#667085] cursor-pointer"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default GlossaryReviewPannel;
