import React from "react";
import "./ViewGlossaryWarningPopup.scss";

const ViewGlossaryWarningPopup = ({ message, handleCta }) => {
  return (
    <div className="viewGlossarydialog-overlay">
      <div className="viewGlossarydialog-box">
        <p className="viewGlossarydialog-message">{message}</p>
        <div className="flex items-center gap-3 w-full justify-start">
        <button className="viewGlossarydialog-yes" onClick={ ()=> handleCta("yes")}>
          Yes
        </button>
        <button className="viewGlossarydialog-no" onClick={()=> handleCta("no")}>
          No
        </button>
        </div>
      </div>
    </div>
  );
};

export default ViewGlossaryWarningPopup;
