import axios from "axios";
import apiUrls from "../api/apiUrl";
import DOMPurify from "dompurify";
import { diffWords } from 'diff';
const { diff_match_patch } = require("diff-match-patch");

export const addGlossarytoTheBook = async (glossaryItem) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL + apiUrls.createGlossaryItem}`,
      glossaryItem
    );
    return data;
  } catch (error) {
    console.log("error in creating glossary");
  }
};

export const getGlossaryItem = async (translationId, language) => {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_BACKEND_URL + apiUrls.getBookGlossary
      }?language=${language || "dutch"}&unique_string=${translationId}`
    );

    if (response.data.glossary_items) {
      return [];
    }

    return response.data ?? [];
  } catch (error) {
    console.log("error in getting glossary");
  }
};

export const updateGlossarytoTheBook = async (glossaryItem, glossaryId) => {
  try {
    console.log("glossaryItem", glossaryItem);
    await axios.put(
      `${
        process.env.REACT_APP_BACKEND_URL +
        apiUrls.updateBookGlossary +
        glossaryId
      }/`,
      glossaryItem
    );
  } catch (error) {
    throw error;
  }
};

export const deleteGlossaryFromTheBook = async (glossaryId) => {
  try {
    await axios.delete(
      `${
        process.env.REACT_APP_BACKEND_URL +
        apiUrls.deleteBookGlossary +
        glossaryId
      }/`
    );
  } catch (error) {
    console.log("error in deketing glossary");
  }
};

export const applySelectedGlossary = async (
  variantIndex,
  glossaryId,
  translationId,
  chapterNo,
  userEmail,
  englishSentences
) => {
  try {
    if (
      variantIndex === -1 ||
      !glossaryId ||
      !translationId ||
      !chapterNo ||
      !userEmail
    )
      return;
    let payload = {
      unique_string: translationId,
      glossary_item: glossaryId,
      variant_index: variantIndex,
      // priority_chapter_number: chapterNo,
      applied_by: userEmail,
      variant_selection_type: "full_context_type",
    };

    if (englishSentences?.length) {
      payload.array_of_selected_sentences = englishSentences;
    }
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL + apiUrls.applyGlossary}`,
      payload
    );
  } catch (error) {
    console.log("error in updating glossary");
  }
};

export const getGlossarySuggestionData = async (translationId) => {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_BACKEND_URL + apiUrls.getGlossarySuggestion
      }?unique_string=${translationId}`
    );
    console.log("suggestion data", response?.data);
    if (response?.data?.Pending) return response.data.Pending;

    return [];
  } catch (error) {
    console.log("error in getting timestamp", error);
    return [];
  }
};

export const applySuggestedGlossary = async (glossaryPayload) => {
  try {
    if (
      !glossaryPayload.object_id ||
      !glossaryPayload?.updated_status ||
      !glossaryPayload?.priority_chapter_number
    )
      return;

    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL + apiUrls.applySuggestedGlossary}`,
      glossaryPayload
    );
  } catch (error) {
    console.log("error in updating glossary");
  }
};

export const getUpdatedTranslatedChapter = async (translationId, chapter) => {
  return axios
    .post(
      ` ${process.env.REACT_APP_BACKEND_URL}/v2/${apiUrls.getTranslation}?translationId=${translationId}&chapter=${chapter}`,
      {}
    )
    .then((response) => {
      if (response?.data?.chapter_data?.Dutch)
        return response?.data?.chapter_data?.Dutch;

      return "Error in getting translation";
    })
    .catch((error) => {
      console.log("error in getting translation");
    });
};

export const getGlossaryAffectedParagraphs = async (translationId) => {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_BACKEND_URL + apiUrls.getGlossaryChangedPara
      }?unique_string=${translationId}&status=Applied`
    );
    if (response?.data) {
      let glossaryAppliedData = response?.data;
      console.log("glossaryAppliedData", glossaryAppliedData);
      let formatedData = {};
      glossaryAppliedData.forEach((appliedData) => {
        formatedData[appliedData?.application_glossary_item?.english_word] = {
          glossaryItemDetails: appliedData?.application_glossary_item,
          translationId: appliedData?.book,
          affectedChaptersData:
            appliedData.individually_affected_chapter_and_para,
          status: "Applied",
        };
      });
      return formatedData;
    }

    return {};
  } catch (error) {
    console.log("error in getting timestamp", error);
    return [];
  }
};

export const showDifference = (predictedText, changesText) => {
  let diff = new diff_match_patch();
  var d = diff.diff_main(predictedText, changesText);
  diff.diff_cleanupSemantic(d);
  const wordDiffs = [];
  for (const [diffType, diffText] of d) {
    if (diffType === diff_match_patch.DIFF_DELETE) {
      // Split deleted characters into words
      const deletedWords = diffText.split(/\s+/);
      wordDiffs.push(
        ...deletedWords.map((word) => [diff_match_patch.DIFF_DELETE, word])
      );
    } else if (diffType === diff_match_patch.DIFF_INSERT) {
      // Split inserted characters into words
      const insertedWords = diffText.split(/\s+/);
      wordDiffs.push(
        ...insertedWords.map((word) => [diff_match_patch.DIFF_INSERT, word])
      );
    } else {
      // No change at character level, so add unchanged words
      const unchangedWords = diffText.split(/\s+/);
      wordDiffs.push(
        ...unchangedWords.map((word) => [diff_match_patch.DIFF_EQUAL, word])
      );
    }
  }
  // var ds = diff.diff_prettyHtml(d);
  var ds = diff.diff_prettyHtml(d);
  const diffText = ds;
  console.log("wordDiffs", DOMPurify.sanitize(diffText));
  return DOMPurify.sanitize(diffText);
};

export const getDiffContent = (oldHtml, newHtml) => {
  const parser = new DOMParser();

  // Parse the old and new HTML strings into DOM nodes
  const oldDoc = parser.parseFromString(oldHtml, "text/html");
  const newDoc = parser.parseFromString(newHtml, "text/html");

  const oldTextPragraphs = oldDoc.querySelectorAll("body p");
  const newTextPragraphs = newDoc.querySelectorAll("body p");
  console.log("old text ", oldDoc.body.textContent);
  console.log("new text", newDoc.body.textContent);
  //  console.log("oldTextPragraphs",oldTextPragraphs)
  let modifiedHtml = newHtml;
  for (let i = 0; i < oldTextPragraphs.length; i++) {
    const oldDataParagraph = oldTextPragraphs[i];
    const newDataParagraph = newTextPragraphs[i];
    if (oldDataParagraph.textContent !== newDataParagraph.textContent) {
      const updatedDiffText = showDifference(
        oldDataParagraph.textContent,
        newDataParagraph.textContent
      );
      const modifiedPara = document.createElement("p");
      modifiedPara.innerHTML = updatedDiffText;
      // copyStyles(newDataParagraph,modifiedPara)
      modifiedPara.style.cssText = newDataParagraph.style.cssText;
      console.log("modifiedPara", modifiedPara);
      modifiedHtml = modifiedHtml.replace(
        newDataParagraph?.outerHTML,
        modifiedPara.outerHTML
      );
    }
  }

  return modifiedHtml;
};

// export const getDiffingContent = async (
//   translationId,
//   chapterNo,
//   englishWord
// ) => {
//   let promise = [];
//   promise.push(getUpdatedTranslatedChapter(translationId, chapterNo));
//   promise.push(getGlossaryAffectedParagraphs(translationId));
//   const latestData = await Promise.all(promise);
//   const latestChapter = latestData[0];
//   const glossaryAppliedData = latestData[1] ?? [];
//   console.log("glossaryAppliedData", glossaryAppliedData);
//   const currentWordData = glossaryAppliedData?.find(
//     (applicationData) =>
//       applicationData?.application_glossary_item?.english_word === englishWord
//   );

//   if (!currentWordData)
//     return { latestTranslatedData: "", diffTranslatedData: "" };

//   const currentChapterData =
//     currentWordData?.individually_affected_chapter_and_para?.[chapterNo];

//   if (!currentChapterData || !currentChapterData?.changes_data?.length)
//     return { latestTranslatedData: "", diffTranslatedData: "" };

//   //  getDiffContent()
// };

export const removeAddedSpanTags = (translateEditor) => {
  if (!translateEditor) return;
  const currentData = translateEditor?.getData();
  const container = document.createElement("div");
  container.innerHTML = currentData;
  const spans = container.querySelectorAll(`span.added`);
  if (!spans || !spans.length) return false;
  spans.forEach((span) => {
    span.replaceWith(span.textContent);
  });
  return container.innerHTML;
};

export const applyGlossaryOnOccurrence = async (glossaryPayload) => {
  try {
    const response = await axios.post(
      `${
        process.env.REACT_APP_BACKEND_URL + apiUrls.applyGlossaryOccurenceWise
      }`,
      glossaryPayload
    );
    return response.data;
  } catch (error) {
    console.log("error in updating glossary");
  }
};

export const clearFindResults = (sourceEditor) => {
  try {
    if (!sourceEditor) return;
    const findAndReplaceEditing = sourceEditor.plugins.get(
      "FindAndReplaceEditing"
    );

    findAndReplaceEditing?.state.clear(sourceEditor.model);
    findAndReplaceEditing?.stop();
  } catch (error) {}
};

export function scrollOccurrenceContainer(className, containerClassName) {
  setTimeout(() => {
    const mainContainer = document.querySelector(
      containerClassName ? containerClassName : ".occurrencesContainer"
    );

    if (!mainContainer) return;

    // Use getBoundingClientRect to get the current scroll position relative to the viewport
    const containerRect = mainContainer?.getBoundingClientRect();
    const currentScrollPosition = mainContainer.scrollTop;
    const viewportHeight = containerRect.height;
    const documentHeight = mainContainer.scrollHeight;

    // Calculate the maximum scroll position
    const maxScroll = documentHeight - viewportHeight;

    // Find the currently highlighted element
    let highlightedElement = mainContainer.getElementsByClassName(className);
    highlightedElement = highlightedElement[0];

    if (highlightedElement) {
      const elementRect = highlightedElement?.getBoundingClientRect();
      const elementTopRelativeToContainer = elementRect.top - containerRect.top;

      // Get the top boundary of the viewport
      const viewportTop = window.scrollY || window.pageYOffset;
      // Get the bottom boundary of the viewport
      const viewportBottom = viewportTop + window.innerHeight;
      // Check if the element is within the visible scrollable area of the container
      const isElementInView =
        elementRect.bottom < containerRect.bottom &&
        elementRect.top > containerRect.top;
      console.log(
        "mainContainer",
        isElementInView,
        elementRect.top,
        containerRect.top
      );

      if (!isElementInView) {
        // Calculate the new scroll position
        let newScrollPosition =
          currentScrollPosition + elementTopRelativeToContainer;
        // Ensure we don't scroll past the bottom of the document
        newScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScroll));

        mainContainer.scrollTo({
          top: newScrollPosition,
          behavior: "smooth",
        });
      }
    }
  }, 100);
}

export const getCurrentChapterParagraph = (
  currentChapter,
  prevParagraph,
  paragraphAfterGlossary
) => {
  const parser = new DOMParser();
  const prevParagraphElement = parser.parseFromString(
    prevParagraph,
    "text/html"
  );
  const paragraphAfterGlossaryElement = parser.parseFromString(
    paragraphAfterGlossary,
    "text/html"
  );
  const currentChapterElement = parser.parseFromString(
    currentChapter,
    "text/html"
  );
  const allTranslatedParagraph =
    currentChapterElement.querySelectorAll("body > p");
  let matchedPara = "";
  allTranslatedParagraph.forEach((paragraph) => {
    if (
      paragraph.textContent?.trim() ===
      prevParagraphElement?.body?.textContent?.trim()
    ) {
      matchedPara = paragraph.outerHTML;
    }

    if (
      paragraph.textContent?.trim() ===
      paragraphAfterGlossaryElement?.body?.textContent?.trim()
    ) {
      matchedPara = paragraph.outerHTML;
    }
  });
  return matchedPara?.trim();
};

const showTooltip = (element, text) => {
  let tooltip = document.getElementById("tooltip");
  if (!tooltip) {
    tooltip = document.createElement("div");
    tooltip.id = "tooltip";
    document.body.appendChild(tooltip);
  }
  tooltip.textContent = text;
  const rect = element.getBoundingClientRect();
  tooltip.style.top = `${rect.top - 30}px`;
  // console.log("styles",tooltipRect.width)
  tooltip.style.left = `${rect.left - 20}px`;
  tooltip.style.display = "block";
};

const hideTooltip = () => {
  const tooltip = document.getElementById("tooltip");
  if (tooltip) {
    tooltip.style.display = "none";
  }
};

export const showReasonOnHoverEvent = (event) => {
  if (
    event.target.tagName === "SPAN" &&
    event.target.classList.contains("preDefined")
  ) {
    const reason = event.target.getAttribute("data-reason");
    if (reason) {
      console.log("reason", event.target, reason);
      showTooltip(event.target, reason);
    }
  }
};

export const hideReasonOnMouseOutEvent = (event) => {
  if (
    event.target.tagName === "SPAN" &&
    event.target.classList.contains("preDefined")
  ) {
    hideTooltip();
  }
};

export const getGlossaryPreAppliedData = async (translationId) => {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_BACKEND_URL + apiUrls.preAppliedGlossaryData
      }?translation_id=${translationId}`
    );
    console.log("suggestion data", response?.data);
    if (!response?.data?.pre_applied_glossary_data) return {};
    let updatedPreAppliedGlossary = {
      ...response?.data?.pre_applied_glossary_data,
    };
    Object.keys(updatedPreAppliedGlossary).forEach((chapterString) => {
      const applicationData = updatedPreAppliedGlossary[chapterString];
      Object.keys(applicationData).forEach((cardId) => {
        if (applicationData[cardId].status === "approved")
          delete applicationData[cardId];
      });
      if (!Object.keys(updatedPreAppliedGlossary[chapterString]).length) {
        delete updatedPreAppliedGlossary[chapterString];
      }
    });

    return updatedPreAppliedGlossary;
  } catch (error) {
    console.log("error in getting timestamp", error);
    return [];
  }
};

export const updateGlossaryPreAppliedData = async (
  payload,
  preAppliedGlossaryData
) => {
  try {
    const response = await axios.post(
      `${
        process.env.REACT_APP_BACKEND_URL + apiUrls.updatePreAppliedGlossaryData
      }`,
      payload
    );
    let updatedPreAppliedGlossary = { ...preAppliedGlossaryData };
    Object.keys(updatedPreAppliedGlossary).forEach((chapterString) => {
      const applicationData = updatedPreAppliedGlossary[chapterString];
      Object.keys(applicationData).forEach((cardId) => {
        if (cardId === payload.local_id) delete applicationData[cardId];
      });
      if (!Object.keys(updatedPreAppliedGlossary[chapterString]).length) {
        delete updatedPreAppliedGlossary[chapterString];
      }
    });
    return updatedPreAppliedGlossary;
  } catch (error) {
    console.log("error in updateGlossaryPreAppliedData", error);
    return [];
  }
};

export const updatePredefinedClassName = (data, cardId) => {
  const domElement = document.createElement("div");
  domElement.innerHTML = data;
  const escapedClassName = CSS.escape(cardId);
  let predefinedGlossaryAddedElem = domElement?.querySelector(
    `[local_id=${escapedClassName}]`
  );
  // const documentEle=document?.querySelector(`[local_id=${escapedClassName}]`)
  if (predefinedGlossaryAddedElem) {
    predefinedGlossaryAddedElem.className = "predefined-added-approved";
  } else {
    console.error("not found", escapedClassName);
  }
  // if(documentEle){
  // documentEle.className="predefined-added-approved";
  // }
  return domElement.innerHTML;
};

export function getCompleteSentence(startElement) {
  // Initialize an empty array to store the sentence parts
  const sentenceParts = [];

  // Traverse backwards to find the start of the sentence
  let currentNode = startElement.previousSibling;
  let reason = startElement?.getAttribute("data-replacement-reason");
  let variantUsed = startElement.getAttribute("variant_used");
  let englishWord = startElement.getAttribute("glossary_english_word");
  sentenceParts.push(
    ` <span class="added" glossary_english_word="${englishWord}" data-replacement-reason="${reason}" variant_used="${variantUsed}"> ${startElement.textContent} </span> `
  );
  while (currentNode) {
    if (currentNode.textContent.includes(".")) {
      const nodeArr = currentNode.textContent.split(".");
      sentenceParts.unshift(nodeArr[1]);
      break;
    }
    if (currentNode?.classList?.contains("predefined-removed")) {
      sentenceParts.unshift(
        `<span class="removed">${currentNode?.textContent}</span>`
      );
    } else {
      sentenceParts.unshift(currentNode.textContent);
    }

    currentNode = currentNode.previousSibling;
  }

  // Traverse forwards to find the end of the sentence
  currentNode = startElement.nextSibling;
  while (currentNode) {
    if (currentNode.textContent.includes(".")) {
      const nodeArr = currentNode.textContent.split(".");
      sentenceParts.push(nodeArr[0] + ".");
      break;
    }
    sentenceParts.push(currentNode.textContent);
    currentNode = currentNode.nextSibling;
  }

  console.log("currentNode", sentenceParts);
  // Combine the parts into a single string
  return sentenceParts.join("").trim();
}

export const getChapterNoFromString = (chapterString) => {
  return Number(chapterString?.split("_")[1]);
};

export const checkDynamicText = (text) => {
  const dynamicTextPattern = /<[^<>]+>/; // Matches text between <>
  return dynamicTextPattern.test(text);
};

export function scroll(element, individualScroll) {
  setTimeout(() => {
    const footer = document.querySelector("#sourced-editor-word-count");
    const containerQuery = individualScroll
      ? "#sourceContainer"
      : "#bookEditorContainer";
    const mainContainer = document.querySelector(containerQuery);
    if (!mainContainer) return;
    const footerHeight = footer ? footer.offsetHeight : 0;
    const buffer = 20;

    // Use getBoundingClientRect to get the current scroll position relative to the viewport
    const containerRect = mainContainer?.getBoundingClientRect();
    const currentScrollPosition = mainContainer.scrollTop;
    const viewportHeight = containerRect.height;
    const documentHeight = mainContainer.scrollHeight;

    // Calculate the maximum scroll position
    const maxScroll = documentHeight - viewportHeight;

    // Find the currently highlighted element
    let highlightedElement = element;

    if (highlightedElement) {
      const elementRect = highlightedElement.getBoundingClientRect();
      const elementTopRelativeToContainer = elementRect.top - containerRect.top;

      // elementRect.bottom - containerRect.top + currentScrollPosition;
      // Get the top boundary of the viewport
      const viewportTop = window.scrollY || window.pageYOffset;
      // Get the bottom boundary of the viewport
      const viewportBottom = viewportTop + window.innerHeight;
      // Check if the element is within the visible scrollable area of the container
      const isElementInView =
        elementRect.bottom + 50 < viewportBottom && elementRect.top > 135;

      if (!isElementInView) {
        // Calculate the new scroll position
        let newScrollPosition =
          currentScrollPosition +
          elementTopRelativeToContainer -
          footerHeight -
          buffer;

        // Ensure we don't scroll past the bottom of the document
        newScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScroll));

        mainContainer.scrollTo({
          top: newScrollPosition,
          behavior: "smooth",
        });
      }
    }
  }, 100);
}

const getOccurrenceParagraphText = (findGlossaryData, occurrenceNo) => {
  let occurrence = 0;
  let englishParaText = "";
  let paragraphList = findGlossaryData?.chapterWiseOccurancePara || {};

  // Object.keys(sentenceList || {}).forEach((chapterNo) => {
  //   let chapterSentences=sentenceList?.[chapterNo]
  //   chapterSentences.forEach((sentence)=>{
  //     occurrence++;
  //     if(occurrence===occurrenceNo)
  //       englishSentence=sentence;
  //   })

  // })

  Object.keys(paragraphList || {}).forEach((chapterNo) => {
    let chapterParagraph = paragraphList?.[chapterNo]?.English || [];
    chapterParagraph.forEach((sentence) => {
      occurrence++;
      if (occurrence === occurrenceNo) englishParaText = sentence;
    });
  });
  const domElem = document.createElement("div");
  let originalPara = englishParaText;
  domElem.innerHTML = englishParaText;
  englishParaText = domElem.textContent;
  return { englishParaText, originalPara };
};

export const scrollTowardsText = (
  findGlossaryData,
  occurrenceNo,
  individualScroll
) => {
  try {
    const data = getOccurrenceParagraphText(
      findGlossaryData,
      occurrenceNo
    );
    let englishParaText=normalizeText(data.englishParaText)?.toLowerCase()
    if (!englishParaText) return;
    const allTranslatedParagraphs = document.querySelectorAll(
      `#source .ck-editor__editable > p`
    );
    for (let index = 0; index < allTranslatedParagraphs.length; index++) {
      let paragraph = allTranslatedParagraphs[index];
      let paraText=normalizeText(paragraph?.textContent)?.toLowerCase()
      if (paraText?.includes(englishParaText) || englishParaText.includes(paraText) || englishParaText===paraText) {
        scroll(paragraph, individualScroll);
        break;
      }
    }
    // allTranslatedParagraphs.forEach((paragraph) => {

    // });
  } catch (error) {
    console.log("error in scrolling towards text");
  }
};

export const getEnglishSentencesFromData = (smartGlossaryData) => {
  let englishSentences = [];
  Object.keys(smartGlossaryData || {}).forEach((chapterKey) => {
    let chapterOccurrenceData = smartGlossaryData[chapterKey] || {};
    Object.keys(chapterOccurrenceData).forEach((occurrenceNo) => {
      let occurrenceData = chapterOccurrenceData[occurrenceNo];
      englishSentences.push(occurrenceData.sentence);
    });
  });
  return englishSentences;
};

export const getCurrentChapterFirstOccurrenceNo = (
  chapterOccurrenceData,
  currentChapter
) => {
  if (!chapterOccurrenceData[currentChapter]) return 0;
  let occurrenceNo = 1;
  Object.keys(chapterOccurrenceData || {}).forEach((chapterKey) => {
    if (Number(chapterKey) < currentChapter) {
      occurrenceNo += chapterOccurrenceData[chapterKey];
    }
  });
  return occurrenceNo;
};

const getWordOccurrenceNo = (
  currentChapterOccurenceNo,
  wordOccurrencesData,
  chapterNo
) => {
  let occurrenceNo = 0;
  Object.keys(wordOccurrencesData).forEach((chapterKey) => {
    let totalOccurances = wordOccurrencesData[chapterKey];
    if (Number(chapterKey) < chapterNo) occurrenceNo += totalOccurances;

    if (Number(chapterKey) === chapterNo)
      occurrenceNo += currentChapterOccurenceNo;
  });

  return occurrenceNo;
};

export function normalizeText(text) {
  if(!text?.trim()) return "";
  return text
  .replaceAll(/[’‘‛`]/g, "'") // Various apostrophes and backticks to straight apostrophe
  .replaceAll(/[“”„‟″]/g, '"') // Various double quotes to straight double quote
  .replaceAll(/–|—|‒|―/g, "-") // Various dashes to hyphen
  .replaceAll(/[‚‚ʻ]/g, ",") // Various commas to standard comma
  .replaceAll(/[•◦‣∙]/g, "") // Remove bullet-like symbols
  .replaceAll(/[‐‑‒]/g, "-") // Hyphen-like characters to a single hyphen
  .replaceAll(/\s+/g, " ") // Collapse multiple spaces into one
}

/**
 * getCurrentOccurrenceData function returns the data of selected occurrence no like ocurrence no and paragraph where that occurrence text belongs to
 */
export const getCurrentOccurrenceData = ({
  currentChapterOccurenceNo: currentChapterOccurenceNo,
  findOverBookData: findOverBookData,
  currentChapter: currentChapter,
  currentOccurrenceNo: currentOccurrenceNo,
}) => {
  if (findOverBookData.isSmartGlossary) {
    let { originalPara, englishParaText } = getOccurrenceParagraphText(
      findOverBookData,
      currentOccurrenceNo
    );
    let englishSentence = "";
    let chapterOccurrenceNo = "";
    let occurrenceItr = 0;
    console.log("smartFix", englishParaText);
    Object.keys(findOverBookData?.smartGlossaryData || {}).forEach(
      (chapterKey) => {
        let occurrenceDataList =
          findOverBookData?.smartGlossaryData?.[chapterKey] || {};
        Object.keys(occurrenceDataList || {}).forEach((occurrenceNo) => {
          const occurrenceData = occurrenceDataList[occurrenceNo] || {};
          occurrenceItr++;
          console.log("smartFix", occurrenceData.sentence);
          englishParaText = normalizeText(englishParaText);
          occurrenceData.sentence = normalizeText(occurrenceData.sentence);
          if (
            occurrenceItr === currentOccurrenceNo &&
            englishParaText.includes(occurrenceData.sentence)
          ) {
            // englishSentence=occurrenceData.sentence;
            chapterOccurrenceNo = Number(occurrenceNo) - 1;
          }
        });
      }
    );

    //  if(!englishSentence) return;

    //  englishParaText=englishParaText.replace(englishSentence,`<span class='ck-find-result_selected'> ${englishSentence} </span>`)
    //  console.log("smartFix",englishParaText)
    return {
      wordOccurrenceNo: currentOccurrenceNo,
      englishParagraph: originalPara,
      chapterOccurrenceNo,
    };
  }

  const wordOccurrenceNo = getWordOccurrenceNo(
    currentChapterOccurenceNo,
    findOverBookData.wordOccurrencesOverBook,
    currentChapter
  );
  const paragraphs = document.querySelectorAll(
    "#source .ck-editor__editable > p"
  );
  let englishParagraph = "";
  paragraphs.forEach((paragraph) => {
    const spanElement = paragraph.querySelector(".ck-find-result_selected");
    if (spanElement) {
      englishParagraph = paragraph.outerHTML;
    }
  });

  return { wordOccurrenceNo, englishParagraph, chapterOccurrenceNo: null };
};

export const getNextOrPrevOccurrenceData = ({
  type,
  currentOccurrenceNo,
  totalOccurances,
  findOverBookData,
}) => {
  let nextOccurrence = -1;
  switch (type) {
    case "next":
      nextOccurrence =
        currentOccurrenceNo === totalOccurances ? 1 : currentOccurrenceNo + 1;
      break;
    case "prev":
      nextOccurrence =
        currentOccurrenceNo === 1 ? totalOccurances : currentOccurrenceNo - 1;
      break;
  }
  let occurrenceNo = 0;
  let nextChapterNo;
  Object.keys(findOverBookData?.smartGlossaryData).forEach((chapterKey) => {
    let sentences = findOverBookData.smartGlossaryData[chapterKey] || {};
    Object.keys(sentences).forEach((sentence) => {
      occurrenceNo++;
      if (occurrenceNo === nextOccurrence) nextChapterNo = Number(chapterKey);
    });
  });

  return { nextOccurrence, nextChapterNo };
};

export const getSmartGlossaryRecommendation = async (
  englishWord,
  targetLanguage
) => {
  try {
    const response = await axios.post(
      `${
        process.env.REACT_APP_BACKEND_URL +
        apiUrls.getSmartGlossaryRecommendation
      }`,
      {
        sentence: englishWord,
        paragraph_text: "",
        translation: "",
        target_language: targetLanguage,
      }
    );

    if (!response.data) {
      return {};
    }

    return response.data ?? {};
  } catch (error) {
    console.log("error in getting glossary");
  }
};

const glossaryData = [
  {
    id: 1,
    language: "dutch",
    genre: "Romance",
    unique_string: "rQFpIyXZyovnYbwKZgbXJLnrwsXQjbjOGlweCifgqAKehLgR",
    chapter_number: 1,
    paragraph_range: [1, 2, 3],
    author: "SUYASH TEST CHANGED HERE",
    english_word:
      "<any name> was driving way too fast, especially for these country roads",
    original_english_word:
      "Dante was driving way too fast, especially for these country roads",
    dutch_word_array: [
      {
        genre: "Romance",
        author: "preapplied  False",
        language: "dutch",
        instructions: {
          exception: "",
          whenToApply: "Use it to replace chapter",
        },
        variant_scope: "Book",
        metadata_storage: {
          "creation date": "2024-10-25T13:00:26.436Z",
          creation_user: "suyash.pathak@techolution.com",
          application_history: [],
        },
        replacement_dutch_word:
          "<any name> reed veel te hard, vooral voor deze landweggetjes, zijn stond strak",
        replacement_dutch_word_original:
          "Dante reed veel te hard, vooral voor deze landweggetjes, zijn gezicht stond strak",
      },
    ],
    application_scope: "book_level",
    created_by: "suyash.pathak@techolution.com",
    approved_by: null,
    status: "pending",
    reason: "",
    created_glossary_item_id: null,
  },
];

export const getGlossaryForReview = async (translationId) => {
  try {
    // return glossaryData;
    const response = await axios.get(
      `${
        process.env.REACT_APP_BACKEND_URL + apiUrls.getGlossariesForReview
      }?translationId=${translationId}`
    );

    if (!response.data) {
      return {};
    }

    return response.data;

    // return new Promise((resolve,reject)=>{
    //    setTimeout(()=>{
    //     resolve(glossaryData)
    //    },5000)
    // })
  } catch (error) {}
};

export const updateReviewedGlossary = async (payload) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL + apiUrls.updateReviewedGlossary}/${
        payload.id
      }/`,
      payload
    );

    if (!response.data) {
      return {};
    }

    return response.data;
  } catch (error) {}
};

export const highlightSentenceInEditor = (
  sentenceToHighlight,
  sourceContent,
  setUpdatedContent,
  setIsLayoutFixed,
  editorType,
  paragraphNo
) => {
  if (!sourceContent.includes("<p")) {
    sourceContent = sourceContent.replace(
      sentenceToHighlight,
      `<span class='${editorType}-glossaryReviewText'>${sentenceToHighlight}</span>`
    );
    setTimeout(() => {
      setUpdatedContent(sourceContent);
      setIsLayoutFixed(false);
    });
    return;
  }
  let domElem = document.createElement("div");
  domElem.innerHTML = sourceContent;
  const allParagraph = domElem.querySelectorAll("p");
 
  for(let i=0;i<allParagraph.length;i++) {
    let paragraphElem=allParagraph[i]
    console.log(
      "editorHighlighting",
      paragraphElem.textContent,
      " senetence : ",
      sentenceToHighlight
    );
    if (!paragraphElem.textContent.includes(sentenceToHighlight)) {
      paragraphElem.textContent = normalizeText(
        paragraphElem.textContent
      );
      sentenceToHighlight = normalizeText(sentenceToHighlight);
    }
    if (
      paragraphElem?.textContent?.toLowerCase()?.includes(sentenceToHighlight?.toLowerCase()) ||
      paragraphNo === i
    ) {
      let paragraphString = paragraphElem.innerHTML;
      let sentenceWordArray = sentenceToHighlight.split(" ");
      let firstWord = sentenceWordArray[0];
      let lastWord = sentenceWordArray[sentenceWordArray.length - 1];
      const regex = new RegExp(`\\b${sentenceToHighlight}\\b[.]?`, 'i');
      paragraphString=paragraphString.replace(
        regex,
        `<span class='${editorType}-glossaryReviewText'>${sentenceToHighlight}</span>`
      );
      console.log("replaced-one",paragraphString," :  ",sentenceToHighlight)
      if (
        !paragraphString.includes(
          'glossaryReviewText'
        )
      ) {
        paragraphString = paragraphString.replace(
          firstWord,
          `<span class='${editorType}-glossaryReviewText'>${firstWord}`
        );
        paragraphString = paragraphString.replace(
          lastWord,
          `${lastWord}</span>`
        );
      }
      paragraphElem.innerHTML = paragraphString;
      break;
    }
  };

  console.log("paragraphString", domElem.innerHTML);
  setTimeout(() => {
    setUpdatedContent(domElem.innerHTML);
    setIsLayoutFixed(false);
  });
};

export const removeHighlighting = (sourceContent,translatedContent,setSourceContent,setTranslatedContent,setIsLayoutFixed) => {
  let sourceTextDom=document.createElement("div");
  let translatedTextDom=document.createElement("div");
  sourceTextDom.innerHTML=sourceContent
  translatedTextDom.innerHTML=translatedContent

  const sourceEditorSpans = sourceTextDom.querySelectorAll(
    `span.source-glossaryReviewText`
  );
  const translateEditorSpans = translatedTextDom.querySelectorAll(
    `span.translated-glossaryReviewText`
  );

  if (!sourceEditorSpans?.length && !translateEditorSpans.length) return;
  sourceEditorSpans.forEach((span) => {
    span.classList.remove("source-glossaryReviewText");
    span.classList.add("deleted-source-glossaryReviewText");
  });

  translateEditorSpans.forEach((span) => {
    span.classList.remove("translated-glossaryReviewText");
    span.classList.add("deleted-translated-glossaryReviewText");
  });

   setSourceContent(sourceTextDom.innerHTML);
   setTranslatedContent(translatedTextDom.innerHTML)
   setIsLayoutFixed(false);
};

export const getDynamicDiffWords = () => {};


export const getDiffSentence= (oldText, newText)=>{
  
      const diff = diffWords(oldText, newText);
      // console.log("diff details",diff)
      let diffSentence=`<span class='originalText'>`
      console.log("diffDetails",diff)
      for(let i=0;i<diff.length;i++){
          let diffDetail=diff[i];
          if(diffDetail?.removed){
            let removedText=`<span class='removedOldText'> ${diffDetail?.value}`;
            let nextItr=i+1;
            while(diff[nextItr]?.removed && diff[nextItr]?.value){
              removedText += diff[nextItr]?.value;
              nextItr++;
            }
            removedText+='</span>';
            diffSentence+=removedText
            i= --nextItr;
          }

          if(!diffDetail?.removed && !diffDetail?.added){
            diffSentence+=diffDetail?.value
          }
      }
     
      diffSentence+='</span>'

    return diffSentence;
}


export const  removeWhitespaceAndNewlines=(str) => {
  if(!str.trim()) return "";
  return str.replace(/\s+/g, '');
}

function escapeRegExp(text) {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}


export const putTagsAroundOccurrencesInSourceContent = (
  sourceContent,
  setUpdatedContent,
  setIsLayoutFixed,
  currentChapterNo,
  findOverBookData
) => {
  let prevOccurrenceCount = 0;
  Object.keys(findOverBookData?.smartGlossaryData || {}).forEach(
    (chapterKey) => {
      if (currentChapterNo > Number(chapterKey)) {
        prevOccurrenceCount += Object.keys(
          findOverBookData?.smartGlossaryData[chapterKey]
        ).length;
      }
    }
  );
  let occurrenceData=findOverBookData.smartGlossaryData?.[currentChapterNo]
  let domElem = document.createElement("div");
  // domElem.innerHTML = sourceContent;
  domElem.innerHTML = normalizeText(sourceContent);
  const allParagraph = domElem.querySelectorAll("p");
  Object.keys(occurrenceData).forEach((occurrence, index) => {
    let sentenceToHighlight = normalizeText(
      occurrenceData[occurrence].sentence
    );
    let occurrenceToHighlight = normalizeText(
      occurrenceData[occurrence].occurrence
    );

    for (let i = 0; i < allParagraph.length; i++) {
      let paragraphElem = allParagraph[i];
      console.log(
        "editorHighlighting",
        paragraphElem.textContent,
        " senetence : ",
        sentenceToHighlight
      );
      // sentenceToHighlight = paragraphElem?.textContent
      //   ?.toLowerCase()
      //   ?.includes(occurrenceToHighlight?.toLowerCase())
      //   ? occurrenceToHighlight
      //   : sentenceToHighlight;
      let paragraphHtml = paragraphElem.innerHTML;
      let prevOccurrenceHtml = "";
      let strToFind = `occurrence-highlighting">${sentenceToHighlight}</span>`;
      let remainingParaText = paragraphElem?.textContent;
      while (paragraphHtml.includes(strToFind)) {
        let splitPoint = paragraphHtml.indexOf(strToFind);
        let textSplitPoint = remainingParaText.indexOf(sentenceToHighlight);
        remainingParaText = remainingParaText.substring(
          textSplitPoint + sentenceToHighlight.length
        );
        prevOccurrenceHtml += paragraphHtml.substring(
          0,
          splitPoint + strToFind.length
        );
        paragraphHtml = paragraphHtml.substring(splitPoint + strToFind.length);
      }
      console.log(
        paragraphElem.textContent,
        " : ",
        sentenceToHighlight,
        " remainingParaText  :  ",
        remainingParaText
      );
      if (
        remainingParaText
          ?.toLowerCase()
          ?.includes(sentenceToHighlight?.toLowerCase() || "") ||
        sentenceToHighlight
          .toLowerCase()
          .includes(remainingParaText?.toLowerCase()) ||
        sentenceToHighlight.toLowerCase() === remainingParaText?.toLowerCase()
      ) {
        let sentenceWordArray = sentenceToHighlight.split(" ");
        let firstWord = sentenceWordArray[0];
        let lastWord = sentenceWordArray[sentenceWordArray.length - 1];
        const regex = new RegExp(escapeRegExp(sentenceToHighlight), "gi");
        paragraphHtml = paragraphHtml.replace(
          regex,
          `<span data-occurrence-no="${
            prevOccurrenceCount + index + 1
          }" class="${
            index + 1 === 1
              ? "selected-occurrence-highlighting  occurrence-highlighting"
              : "occurrence-highlighting"
          }">${sentenceToHighlight}</span>`
        );
        console.log("replaced-one", paragraphHtml, " :  ", sentenceToHighlight);
        if (
          !paragraphHtml.includes(
            `<span data-occurrence-no="${prevOccurrenceCount + index + 1}"`
          )
        ) {
          paragraphHtml = paragraphHtml.replace(
            firstWord,
            `<span data-occurrence-no="${
              prevOccurrenceCount + index + 1
            }" class="${
              index + 1 === 1
                ? "selected-occurrence-highlighting  occurrence-highlighting"
                : "occurrence-highlighting"
            }">${firstWord}`
          );
          if (
            paragraphHtml.includes(
              `<span data-occurrence-no="${prevOccurrenceCount + index + 1}"`
            )
          ) {
            paragraphHtml = paragraphHtml.replace(
              lastWord,
              `${lastWord}</span>`
            );
          }
        }
        paragraphElem.innerHTML = prevOccurrenceHtml + paragraphHtml;
        break;
      }
    }
  });

  console.log("paragraphString", domElem.innerHTML);
  setTimeout(() => {
    setUpdatedContent(domElem.innerHTML);
    setIsLayoutFixed(false);
  });
};


export const removeOccurrenceHighlighting = (
  sourceContent,
  setUpdatedContent,
  setIsLayoutFixed
) => {
  let domElem = document.createElement("div");
  domElem.innerHTML = sourceContent;
  const occurrenceList = domElem.querySelectorAll(
    ".selected-occurrence-highlighting, .occurrence-highlighting"
  );
  occurrenceList.forEach((occurrence) => {
    occurrence.classList.remove("selected-occurrence-highlighting");
    occurrence.classList.remove("occurrence-highlighting");
    occurrence.removeAttribute('data-occurrence-no')
  });
  setTimeout(() => {
    setUpdatedContent(domElem.innerHTML);
    setIsLayoutFixed(false);
  });
};


export const highlightNextOccurrenceInSourceEditor = (
  currentOccurrenceNo,
  nextOccurrenceNo
) => {
  if (!currentOccurrenceNo && !nextOccurrenceNo) return;

  let currentHighlightedElem =
    document.querySelectorAll(
      `[data-occurrence-no="${currentOccurrenceNo}"]`
    ) || [];
  if (currentHighlightedElem.length) {
    currentHighlightedElem.forEach((elem) => {
      elem.classList.remove("selected-occurrence-highlighting");
    });
  }
  let nextHighlightedElement = document.querySelectorAll(
    `[data-occurrence-no="${nextOccurrenceNo}"]`
  );
  if (nextHighlightedElement.length) {
    nextHighlightedElement.forEach((elem) => {
      elem.classList.add("selected-occurrence-highlighting");
    });
  }
};