import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as GlossaryInfoIcon } from "../../assets/glossaryInfoIcon.svg";
import { getCapitalisedString, updateUserActiveTime } from "../../utils/translationUtil";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Tooltip, styled } from "@mui/material";
import { cn } from "../../lib/utils";
import { useSelector } from "react-redux";
import "./EditGlossaryForm.scss";
import { checkDynamicText, getDiffSentence, showDifference } from "../../utils/glossaryUtils";
import TextDifference from "../TextDifference/TextDifference";
import { diffWords } from "diff";
import { useParams } from "react-router-dom";
import debounce from "lodash.debounce";
import SmartGlossaryRecommendation from "../SmartGlossaryRecommendation/SmartGlossaryRecommendation";
function EditGlossaryForm({
  glossary,
  glossaryInputData,
  setGlossaryInputData,
}) {
  const { userOpenedBookInfo,loggedInUserEmail } = useSelector(
    (state) => state.main
  );
  const params=useParams()
  const [sentenceDifferenceData, setSentenceDifferenceData] = useState({})
  const [smartGlossaryRecommendations, setSmartGlossaryRecommendations] = useState({})
  const [fetchSuggestionsLoading, setFetchSuggestionsLoading] = useState(false)
  const [expanded, setExpanded] = useState(true)
  useEffect(() => {
    if (glossary) {
      setTimeout(() => {
        adjustInputBoxHeight();
      }, 100);
      updateGlossaryForm(glossary);
      updateSentenceDifferenceData(glossary)
    }
  }, [glossary]);
 
  const adjustInputBoxHeight = () => {
    let englishInput = document.querySelector(".englishTextInput");
    let translationInput = document.querySelector(".translationInput");
    let whenToApplyInput = document.querySelector(".whenToApply");
    let exceptionsInput = document.querySelector(".whenNotToApply");

    if(!englishInput || !translationInput || !whenToApplyInput  || !exceptionsInput) return ;

    englishInput.style.height = "auto";
    englishInput.style.height = `${englishInput.scrollHeight}px`;

    translationInput.style.height = "auto";
    translationInput.style.height = `${translationInput.scrollHeight}px`;

    let maxHeight = Math.max(
      whenToApplyInput.scrollHeight,
      exceptionsInput.scrollHeight
    );
    whenToApplyInput.style.height = "auto";
    whenToApplyInput.style.height = `${maxHeight}px`;

    exceptionsInput.style.height = "auto";
    exceptionsInput.style.height = `${maxHeight}px`;
  };

  const handleGlossaryInputChange = (e) => {
    const inputElement = e.target;
    debouncedUpdateUserActiveTime(params.id,loggedInUserEmail)

    if (
      e.target.name === "replacement_dutch_word" ||
      e.target.name === "englishWord" ||
      e.target.name === "whenToApply" ||
      e.target.name === "exceptions"
    ) {
      inputElement.style.height = "auto";
      inputElement.style.height = `${inputElement.scrollHeight}px`;
    }

    setGlossaryInputData({
      ...glossaryInputData,
      [e.target.name]: e?.target?.value,
    });
  };

  const handleGlossaryScopeChange = (selectedScope) => {
    debouncedUpdateUserActiveTime(params.id,loggedInUserEmail)
    setGlossaryInputData({
      ...glossaryInputData,
      scope: selectedScope,
    });
  };

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "black",
      color: "white",
      width: "170px",
    },
    "& .MuiTooltip-arrow": {
      color: "black", // Arrow color
    },
  }));

   const debouncedUpdateUserActiveTime = useCallback(
      debounce((translationId, loggedInUserEmail) => {
        try {
          return updateUserActiveTime(translationId, loggedInUserEmail);
        } catch (error) {}
      }, 1000),
      []
    );
  
  
  const findDifference = (original, dynamic) => {
    let completeText = "";
    let diffData = {};
    const diff = diffWords(original, dynamic);
    console.log("resultText", diff);
    for (let i = 0; i < diff.length; i++) {
      let diffDetails = diff[i];
      if (diffDetails?.removed) {
        let removedText = diffDetails.value;
        let addedText = "";
        let iterator = i + 1;
        while (diff[iterator]?.removed) {
          removedText += diff[iterator++].value;
        }

        while (diff[iterator]?.added) {
          addedText += diff[iterator++].value;
        }

        if (!diff[iterator]?.removed && !diff[iterator]?.added && removedText) {
          completeText += `<span class='addedNewText'>${removedText} </span>`;
          if (addedText?.includes("<") && addedText?.includes(">")) {
            diffData[removedText] = addedText;
          }
        }
      }

      if (!diffDetails.added && !diffDetails.removed) {
        completeText += diffDetails.value;
      }
    }
    
    console.log("dataLogic", completeText, diffData);
    return diffData;
  };
  
  const updateSentenceDifferenceData=(glossary)=>{
    // let sourceTextDiffContent = showDifference(glossary.originalEnglishText, glossary.englishWord);
    // let translateTextDiffContent = showDifference(glossary.originalDutchText, glossary.replacement_dutch_word);
    let variantData = glossary.dutch_word_array[0];

    let originalEnglishText=!checkDynamicText(glossary.english_word) ? glossary.english_word:  glossary.original_english_word
    let sourceWordLevelChanges = findDifference(originalEnglishText, glossary.english_word)
    let translatedWordLevelChanges = findDifference(variantData.replacement_dutch_word_original, variantData.replacement_dutch_word)
    setSentenceDifferenceData({
      ...sentenceDifferenceData,
      source: {
        originalSentence:  originalEnglishText,
        modifiedSentence:  glossary.english_word,
        wordLevelChanges:sourceWordLevelChanges 
      },
      translated:{
        originalSentence:  variantData.replacement_dutch_word_original,
        modifiedSentence: variantData.replacement_dutch_word,
        wordLevelChanges:translatedWordLevelChanges 
      }
    });
  }

  const updateGlossaryForm = (glossary) => {
    let variantData = glossary.dutch_word_array[0];
    if (!glossary || !variantData) return;
    setGlossaryInputData({
      replacement_dutch_word: variantData.replacement_dutch_word,
      scope:"language",
      whenToApply: variantData.instructions.whenToApply,
      exceptions: variantData.instructions.exception || "None",
      englishWord: glossary.english_word,
      originalDutchText: variantData.replacement_dutch_word_original  || variantData.replacement_dutch_word,
      originalEnglishText: !checkDynamicText(glossary.english_word) ? glossary.english_word:  glossary.original_english_word,
    });
   
  };

  function extractChanges(htmlString, originalSentence, modifiedSentence) {
    // Parse the HTML string using DOMParser
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
  
    const delTags = [...doc.querySelectorAll("del")];
    const insTags = [...doc.querySelectorAll("ins")];
  
    const changes = {};
    console.log("PRINTtAGS","delTags", delTags,"insTags",insTags);
  
    // Ensure both delTags and insTags are processed correctly
    let insIndex = 0;
  
    delTags.forEach((delTag) => {
      const removedWord = delTag.textContent.trim();
      let addedWord = null;
  
      // Find the first valid <ins> tag that appears after the current <del>
      while (insIndex < insTags.length) {
        const insTag = insTags[insIndex];
        const potentialWord = insTag.textContent.trim();
  
        if (modifiedSentence.includes(potentialWord) && checkDynamicText(potentialWord)) {
          addedWord = potentialWord;
          insIndex++;
          break;
        }
        insIndex++;
      }
  
      // If a match is found, add to the changes object
      if (removedWord && addedWord) {
        changes[removedWord] = addedWord;
      }
    });
  
    return changes;
  }

  const textDifference = (newText, oldText, textType) => {
    if (checkDynamicText(newText) && !oldText)
      return "<span class='originalText'>None <span/>";
    // if(!checkDynamicText(newText) && oldText) return newText
    // let diffContent = showDifference(oldText, newText);
    let diffSentence = getDiffSentence(oldText, newText);
    
    // console.log("diffContent",diffContent," diffSentence : ",diffSentence)
    // diffContent = `<span class='originalText'> ${diffContent} </span>`;
    // diffContent = diffContent.replaceAll(
    //   `<ins style="background:#e6ffe6;">`,
    //   "<span class='addedNewText'>"
    // );
    // diffContent = diffContent.replaceAll(
    //   `<del style="background:#ffe6e6;">`,
    //   "<span class='removedOldText'>"
    // );
    // diffContent = diffContent.replaceAll("</ins>", "</span>");
    // diffContent = diffContent.replaceAll("</del>", "</span>");
    const domElem = document.createElement("div");
    domElem.innerHTML = diffSentence;
    let wordLevelChanges =
      sentenceDifferenceData?.[textType]?.wordLevelChanges || {};

    if (Object.keys(wordLevelChanges).length) {
      const oldtTextElements =
        domElem.querySelectorAll(".removedOldText") || [];
      console.log("algorithm data", wordLevelChanges, oldtTextElements);
      oldtTextElements.forEach((removedEleme) => {
        let removedTextDetails = Object.keys(wordLevelChanges).find((text) =>
          removedEleme.textContent.includes(text)
        );

        if (
          removedTextDetails &&
          removedEleme.textContent.toLowerCase().trim() !==
            removedTextDetails.toLowerCase().trim()
        ) {
          let additinalWordsArray = removedEleme.textContent
            .split(`${removedTextDetails}`)
            .filter((words) => words.trim())
            .map((text) => {
              if (removedEleme.textContent?.startsWith(text)) {
                return {
                  isStartPart: true,
                  value: text,
                };
              }

              return {
                isStartPart: false,
                value: text,
              };
            });

          console.log(
            "diff array",
            additinalWordsArray,
            removedEleme.outerHTML,
            removedEleme.textContent,
            removedTextDetails
          );
          removedEleme.textContent=removedTextDetails;
          additinalWordsArray.forEach((textDetails) => {
            
            if (textDetails.isStartPart) {
              domElem.innerHTML = domElem.innerHTML.replace(
                removedEleme.outerHTML,
                `<span class='removedOldText'>${textDetails.value} </span> ${removedEleme.outerHTML}`
              );
            } else {
              domElem.innerHTML = domElem.innerHTML.replace(
                removedEleme.outerHTML,
                `${removedEleme.outerHTML} <span class='removedOldText'>${textDetails.value} </span>`
              );
            }
          });

          console.log("diff array output", domElem.innerHTML);

          // if (additinalWordsArray.length) {
          //   removedEleme.textContent=removedTextDetails;
          //   if(additinalWordsArray.length>1){
          //   }
          // }
        }
      });
    }

    // const newTextElements = domElem.querySelectorAll(".addedNewText") || [];
    // newTextElements?.forEach((element) => element.remove());
    // console.log("diffContent", diffContent, "newSentence", domElem.innerHTML);
    return domElem.innerHTML;
  };

  return (
    <div>
      <div
        style={{
          height: "calc(100dvh - 133px - 20px - 134px - 25px)",
        }}
        className="gap-4 flex flex-col overflow-y-auto   hideSourceScrollBar h-full rounded-[4px] bg-white"
      >
        <div className="flex flex-col gap-1">
          <span className="text-sm text-[#344054] flex gap-1 items-center font-[poppins] font-[500]">
            English{" "}
            {checkDynamicText(glossaryInputData.englishWord) && (
              <span className="text-xs">(smart glossary)</span>
            )}
            <span
              style={{ fontFamily: "SimSong" }}
              className="text-[#FF4D4F] text-sm"
            >
              *
            </span>
          </span>
          <div className="flex w-full gap-2 items-center justify-between">
            <div className="flex flex-col gap-2 w-[40%]">
              {/* <div
                className=""
                dangerouslySetInnerHTML={{
                  __html: textDifference(
                    glossaryInputData.englishWord,
                    glossaryInputData.originalEnglishText
                  ),
                }}
              /> */}
              <TextDifference
                sentence={textDifference(
                  glossaryInputData.englishWord,
                  glossaryInputData.originalEnglishText,
                  "source"
                )}
                sentenceDifferenceData={sentenceDifferenceData.source}
                glossaryInputData={glossaryInputData}
                setGlossaryInputData={setGlossaryInputData}
                sentenceType={"source"}
              />
            </div>
            {/* <div className="flex flex-col gap-1"> */}
            <textarea
              style={{
                fontFamily: "Times New Roman",
                // border: error.isAlreadyExists
                //   ? "1px solid #D32F2F":
                border: "1px solid #D9D9D9",
              }}
              placeholder="Type here"
              className={cn(
                "w-[60%] text-base rounded-lg text-[#475467] resize-none overflow-hidden box-border englishTextInput px-3 py-[5px] outline-none"
              )}
              rows={1}
              name="englishWord"
              value={glossaryInputData.englishWord}
              onChange={handleGlossaryInputChange}
              autoComplete="off"
            />
            {/* </div> */}
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-sm text-[#344054] flex gap-1  items-center font-[poppins] font-[500]">
            {getCapitalisedString(userOpenedBookInfo?.translatedTo)}
            <span
              style={{ fontFamily: "SimSong" }}
              className="text-[#FF4D4F] text-sm"
            >
              *
            </span>
          </span>
          <div className="flex w-ful gap-1 items-center justify-between">
            <div className="flex flex-col gap-2 w-[40%]">
              {/* <div
                className=""
                dangerouslySetInnerHTML={{
                  __html: textDifference(
                    glossaryInputData.replacement_dutch_word,
                    glossaryInputData.originalDutchText
                  ),
                }}
                
              /> */}
              <TextDifference
                sentence={textDifference(
                  glossaryInputData.replacement_dutch_word,
                  glossaryInputData.originalDutchText,
                  "translated"
                )}
                sentenceDifferenceData={sentenceDifferenceData.translated}
                glossaryInputData={glossaryInputData}
                setGlossaryInputData={setGlossaryInputData}
                sentenceType={"translated"}
              />
            </div>
            {/* <div className="flex flex-col gap-1"> */}
            <textarea
              style={{
                fontFamily: "Times New Roman",
                // border: error.isAlreadyExists
                //   ? "1px solid #D32F2F":
                border: "1px solid #D9D9D9",
              }}
              placeholder="Type here"
              className={cn(
                "w-[60%] text-base rounded-lg text-[#475467] resize-none overflow-hidden box-border translationInput px-3 py-[5px] outline-none"
              )}
              rows={1}
              name="replacement_dutch_word"
              value={glossaryInputData.replacement_dutch_word}
              onChange={handleGlossaryInputChange}
              autoComplete="off"
            />
            {/* </div> */}
          </div>
        </div>

        {/* <div className="flex flex-col gap-2">
          <span className="text-[12px] text-[#000000D9] flex items-center gap-1 font-[poppins] font-[500]">
            {getCapitalisedString(userOpenedBookInfo?.translatedTo)} Variant{" "}
            <span
              style={{ fontFamily: "SimSong" }}
              className="text-[#FF4D4F] text-sm"
            >
              *
            </span>
          </span>
          <textarea
            style={{
              border: "1px solid #D9D9D9",
              fontFamily: "Times New Roman",
            }}
            placeholder="Type here"
            className="w-full text-sm rounded-[4px] resize-none overflow-hidden box-border  px-3 py-[5px] outline-none"
            name="replacement_dutch_word"
            rows={1}
            value={glossaryInputData.replacement_dutch_word}
            onChange={handleGlossaryInputChange}
            autoComplete="off"
          />
        </div> */}

        <div
          style={{ border: "1px solid #D9D9D9" }}
          className="flex flex-col mt-5  p-6 gap-6 rounded-lg"
        >
          <div className="flex justify-between gap-6">
            <div className="flex flex-col w-1/2 gap-2">
              <div className="flex items-center gap-1">
                <span className="text-[12px] flex items-center gap-1 text-[#000000D9] font-[poppins] font-[500]">
                  When to apply
                  <span
                    style={{ fontFamily: "SimSong" }}
                    className="text-[#FF4D4F] text-sm"
                  >
                    *
                  </span>
                </span>
                <CustomTooltip
                  sx={{
                    "& .MuiTooltip-tooltip": {
                      backgroundColor: "black",
                      color: "white",
                    },
                  }}
                  placement="top-start"
                  arrow
                  title={
                    <React.Fragment>
                      <b>Example</b>
                      : Light <br />
                      Use light when referring to brightness, like switch on the
                      light.
                    </React.Fragment>
                  }
                >
                  <GlossaryInfoIcon className="cursor-pointer mb-[2px] text-[#999898] w-[14px] h-[14px]" />
                </CustomTooltip>
              </div>
              <textarea
                style={{
                  border: "1px solid #D9D9D9",
                  fontFamily: "Poppins",
                }}
                onChange={handleGlossaryInputChange}
                name="whenToApply"
                placeholder="Type here"
                value={glossaryInputData.whenToApply}
                rows={1}
                className="w-full h-full hideSourceScrollBar  whenToApply  resize-none overflow-hidden box-border text-xs rounded-lg  px-3 py-[5px] outline-none "
              />
            </div>
            <div className="flex flex-col w-1/2 gap-2">
              <div className="flex items-center gap-1">
                <span className="text-[12px] flex items-center gap-1 text-[#000000D9] font-[poppins] font-[500]">
                  When to not apply
                </span>
                <CustomTooltip
                  sx={{
                    "& .MuiTooltip-tooltip": {
                      backgroundColor: "black",
                      color: "white",
                      width: "170px",
                    },
                  }}
                  placement="top-start"
                  arrow
                  title={
                    <React.Fragment>
                      <b>Example</b>
                      : Light <br />
                      Avoid using light for non-brightness contexts, like light
                      weight.
                    </React.Fragment>
                  }
                >
                  <GlossaryInfoIcon className="cursor-pointer mb-[2px] text-[#999898] w-[14px] h-[14px]" />
                </CustomTooltip>
                <span className="text-[#999898] text-[12px]">(optional)</span>
              </div>
              <textarea
                style={{
                  border: "1px solid #D9D9D9",
                  fontFamily: "Poppins",
                }}
                onChange={handleGlossaryInputChange}
                name="exceptions"
                placeholder="Type here"
                rows={1}
                value={glossaryInputData.exceptions}
                autoComplete="off"
                className="w-full h-full whenNotToApply hideSourceScrollBar resize-none overflow-hidden box-border  text-xs  rounded-lg px-3 py-[5px] outline-none "
              />
            </div>
          </div>
          <div className="w-full flex flex-col gap-2 relative">
            <label
              htmlFor="book-genre"
              className="text-[12px] text-[#000000D9] flex items-center gap-1 font-[poppins] font-[500]"
            >
              Apply this glossary to
              <span
                style={{ fontFamily: "SimSong" }}
                className="text-[#FF4D4F] text-sm"
              >
                *
              </span>
            </label>

            <div className=" w-full flex items-center font-[Poppins] flex-wrap gap-3">
              <div
                style={{ border: "1px solid #EEE" }}
                onClick={() => handleGlossaryScopeChange("language")}
                className={cn(
                  "rounded-md gap-1 py-[11px]  cursor-pointer px-3 flex items-center justify-center",
                  glossaryInputData.scope === "language" && "selectedScope"
                )}
              >
                <span className="text-xs text-[#3E3E3E]">Language - </span>
                <span
                  className={cn(
                    "text-xs text-[#3E3E3E] font-medium",
                    glossaryInputData.scope === "language"
                      ? "text-[#1E1C80]"
                      : "text-[#3E3E3E]"
                  )}
                >
                  {getCapitalisedString(userOpenedBookInfo?.translatedTo) ??
                    "Dutch"}
                </span>
              </div>
              <div
                style={{ border: "1px solid #EEE" }}
                onClick={() => handleGlossaryScopeChange("genre")}
                className={cn(
                  "rounded-md gap-1 py-[11px]  cursor-pointer px-3 flex items-center justify-center",
                  glossaryInputData.scope === "genre" && "selectedScope"
                )}
              >
                <span className="text-xs text-[#3E3E3E]">Genre - </span>
                <span
                  className={cn(
                    "text-xs text-[#3E3E3E] font-medium",
                    glossaryInputData.scope === "genre"
                      ? "text-[#1E1C80]"
                      : "text-[#3E3E3E]"
                  )}
                >
                  {userOpenedBookInfo?.genre ?? "Romance"}
                </span>
              </div>
              <div
                style={{ border: "1px solid #EEE" }}
                onClick={() => handleGlossaryScopeChange("author")}
                className={cn(
                  "rounded-md gap-1 py-[11px]  cursor-pointer px-3 flex items-center justify-center",
                  glossaryInputData.scope === "author" && "selectedScope"
                )}
              >
                <span className="text-xs text-[#3E3E3E]">Author - </span>
                <span
                  className={cn(
                    "text-xs text-[#3E3E3E] font-medium",
                    glossaryInputData.scope === "author"
                      ? "text-[#1E1C80]"
                      : "text-[#3E3E3E]"
                  )}
                >
                  {getCapitalisedString(userOpenedBookInfo?.author)}
                </span>
              </div>
              {/* <div
                style={{ border: "1px solid #EEE" }}
                onClick={() => handleGlossaryScopeChange("book")}
                className={cn(
                  "rounded-md gap-1 py-[11px]  cursor-pointer px-3 flex items-center justify-center",
                  glossaryInputData.scope === "book" && "selectedScope"
                )}
              >
                <span className="text-xs text-[#3E3E3E]">Book - </span>
                <span
                  className={cn(
                    "text-xs text-[#3E3E3E] font-medium",
                    glossaryInputData.scope === "book"
                      ? "text-[#1E1C80]"
                      : "text-[#3E3E3E]"
                  )}
                >
                  {userOpenedBookInfo?.title}
                </span>
              </div> */}
              {/* <select
                id="book-sub-genre"
                className={`w-full  ${
                  glossaryInputData.scope ? "text-[#242424]" : "text-[gray]"
                } py-[5px] px-3 outline-none text-xs`}
                placeholder="Enter the Sub-Genre of the book"
                style={{
                  borderRadius: "4px",
                  borderColor: "#D9D9D9",
                  fontSize: "12px",
                }}
                onChange={handleGlossaryInputChange}
                name="scope"
                value={glossaryInputData.scope}
              >
                <option value="" className="" disabled selected hidden>
                  Select apply this glossary to
                </option>
                <option className="text-xs   " value={"Book"} key={"book"}>
                  Current book: {userOpenedBookInfo.title}
                </option>
                <option className="text-xs " value={"Author"} key={"author"}>
                  Author: {userOpenedBookInfo?.author}
                </option>
                <option className="text-xs " value={"Genre"} key={"genre"}>
                  Genre: {userOpenedBookInfo?.genre ?? "Romance"}
                </option>
                <option
                  className="text-xs "
                  value={"Language"}
                  key={"language"}
                >
                  Language: {userOpenedBookInfo?.translatedTo ?? "Dutch"}
                </option>
              </select> */}
            </div>
          </div>
        </div>
        <SmartGlossaryRecommendation
          setExpanded={setExpanded}
          expanded={expanded}
          setGlossaryInputData={setGlossaryInputData}
          setFetchSuggestionsLoading={setFetchSuggestionsLoading}
          fetchSuggestionsLoading={fetchSuggestionsLoading}
          setSmartGlossaryRecommendations={setSmartGlossaryRecommendations}
          smartGlossaryRecommendations={smartGlossaryRecommendations}
          englishWord={glossaryInputData.originalEnglishText}
          flowType={"glossaryReview"}
        />
      </div>
    </div>
  );
}

export default EditGlossaryForm;
