import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as GlossaryViewIcon } from "../../assets/glossaryViewIcon.svg";
import { ReactComponent as Rotate } from "../../assets/Rotate.svg";
import { ReactComponent as Undo } from "../../assets/undo.svg";

import { ReactComponent as RightArrow } from "../../assets/rightArrow2.svg";
import { getSmartGlossaryRecommendation } from "../../utils/glossaryUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsGlossaryAssistantEnabled,
  setResetGlossaryMatchesScreen,
} from "../../App/Glossary/glossary.action";
import SmartGlossaryChatButton from "../SmartGlossaryChatButton/SmartGlossaryChatButton";
import store from "../../App/store";
function SmartGlossaryRecommendation({
  englishWord,
  debouncedUpdateEnglishText,
  setGlossaryInputData,
  setSmartGlossaryRecommendations,
  smartGlossaryRecommendations,
  findOverBookData,
  fetchSuggestionsLoading,
  setFetchSuggestionsLoading,
  expanded,
  setExpanded,
  flowType
}) {
  const dispatch = useDispatch();
  const { resetGlossaryMatchesScreen } = useSelector((state) => state.glossary);
  const { userOpenedBookInfo } = useSelector((state) => state.main);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const resetSuggestionApplication = (data, operation, originalSentence) => {
    let updatedSuggestion = { ...data };
    if (operation === "completeData") {
      updatedSuggestion.isWholeSentenceApplied = false;
      updatedSuggestion.individual_elements=updatedSuggestion?.individual_elements?.map((sentenceData) => {
        sentenceData.isSentenceApplied = false;
        return sentenceData;
      }).filter((sentenceData)=>sentenceData.placeholder_text.trim());
    } else if (operation === "sentencePart") {
      updatedSuggestion.individual_elements=updatedSuggestion?.individual_elements?.map((sentenceData) => {
        if (originalSentence === sentenceData.original_text) {
          sentenceData.isSentenceApplied = false;
        }
        return sentenceData;
      });
    } else {
      updatedSuggestion.isWholeSentenceApplied = false;
    }
    setSmartGlossaryRecommendations(updatedSuggestion);
  };

  const fetchGlossaryRecommendations = async (englishWord, type) => {
    if (!englishWord) return;
    if (
      smartGlossaryRecommendations?.original_sentence?.trim()?.toLowerCase() ===
        englishWord?.trim()?.toLowerCase() &&
      type !== "refresh"
    )
      return;
    let highlightedTextArray = englishWord.split(" ");
    if (highlightedTextArray.length === 1){
      setFetchSuggestionsLoading(false);
      return;
    } 
    setFetchSuggestionsLoading(true);
    const data = await getSmartGlossaryRecommendation(
      englishWord,
      userOpenedBookInfo?.translatedTo ?? "Dutch"
    );
    let updatedEnglishWord=store.getState().glossary.resetGlossaryMatchesScreen.englishWord;
    if(updatedEnglishWord!==englishWord && flowType!=="glossaryReview") return ;
    resetSuggestionApplication(data, "completeData");
    dispatch(
      setResetGlossaryMatchesScreen({ englishWord: englishWord, status: false })
    );
    setFetchSuggestionsLoading(false);
    setExpanded(true);
  };

  //   console.log("matchSentence",smartGlossaryRecommendations?.original_sentence?.trim()?.toLowerCase(),englishWord?.trim()?.toLowerCase())
  useEffect(() => {
    dispatch(
      setResetGlossaryMatchesScreen({
        ...resetGlossaryMatchesScreen,
        status: false,
      })
    );
    if(flowType==="glossaryReview" && englishWord){
      if(englishWord.split(" ").length<=1){
          setSmartGlossaryRecommendations({})
      }else{
        fetchGlossaryRecommendations(englishWord);
      }
    }
    // console.log(
    //   "englishWord",
    //   resetGlossaryMatchesScreen,
    //   englishWord
    // );
    // if (!smartGlossaryRecommendations && englishWord) {
    //   fetchGlossaryRecommendations(englishWord);
    // }
  }, [englishWord]);
  
 //adjusting english and translation input feild height according to text
  const adjustHeightAccordingToText=()=>{
    let englishTextInput= document.querySelector(".englishTextInput");
    if(!englishTextInput) return ;
    englishTextInput.style.height = 'auto';
    englishTextInput.style.height =  `${englishTextInput.scrollHeight}px`;
    let translatedVariant= document.querySelector(".translatedVariant");
    translatedVariant.style.height = 'auto';
    translatedVariant.style.height =  `${translatedVariant.scrollHeight}px`;
  }
   
  useEffect(() => {
    if (
      resetGlossaryMatchesScreen?.status &&
      resetGlossaryMatchesScreen?.englishWord
    ) {
      fetchGlossaryRecommendations(resetGlossaryMatchesScreen?.englishWord);
    }

    if (
      resetGlossaryMatchesScreen?.englishWord &&
      resetGlossaryMatchesScreen?.englishWord?.split(" ")?.length <= 1
    ) {
      setSmartGlossaryRecommendations({});
    }

    setTimeout(()=>{
      // adjustHeightAccordingToText()
    },100)
  }, [resetGlossaryMatchesScreen]);

  const isSmartGlossarySuggestionApplied = () => {
    if (smartGlossaryRecommendations.isWholeSentenceApplied) return true;

    const isPartSentenceApplied =
      smartGlossaryRecommendations.individual_elements.find(
        (sentenceData) => sentenceData.isSentenceApplied === true
      );
    if (isPartSentenceApplied) return true;
  };

  const animateInputFeild = () => {
    let englishInputElement = document.querySelector(".englishTextInput");
    englishInputElement.classList.add("highlightInputFeild");
    setTimeout(() => {
      englishInputElement.classList.remove("highlightInputFeild");
    }, 1000);
  };

  const handleApplySmartSentence = (
    type,
    dynamicSentence,
    originalSentence
  ) => {
    if (smartGlossaryRecommendations.isWholeSentenceApplied && type === "part")
      return;
    if (
      smartGlossaryRecommendations.individual_elements.find(
        (sentence) => sentence.isSentenceApplied === true
      ) &&
      type === "whole"
    )
      return;
    if (
      smartGlossaryRecommendations?.original_sentence?.trim()?.toLowerCase() !==
      resetGlossaryMatchesScreen?.englishWord?.trim()?.toLowerCase()
    )
      return;

    if (
      !smartGlossaryRecommendations?.original_sentence?.includes(
        originalSentence
      )
    )
      return;
    let updatedSentence;
    if (type === "whole") {
      updatedSentence = dynamicSentence;
      setSmartGlossaryRecommendations((prev) => {
        let updatedData = { ...prev };
        updatedData.isWholeSentenceApplied = true;
        return updatedData;
      });
    } else {
      updatedSentence = englishWord.replace(originalSentence, dynamicSentence);
      setSmartGlossaryRecommendations((prev) => {
        let updatedSuggestions = prev;
        updatedSuggestions.individual_elements.map((sentenceData) => {
          // sentenceData.isSentenceApplied = false;
          if (sentenceData.placeholder_text === dynamicSentence) {
            sentenceData.isSentenceApplied = true;
          }
          return sentenceData;
        });
        return updatedSuggestions;
      });
    }
    animateInputFeild();
    if(flowType!=="glossaryReview"){
      debouncedUpdateEnglishText(updatedSentence);
    }
    setGlossaryInputData((prev) => {
      return { ...prev, englishWord: updatedSentence };
    });
  };

  const handleUndoSuggestion = (type, originalSentence, dynamicSentence) => {
    if (!smartGlossaryRecommendations) return;
    resetSuggestionApplication(
      smartGlossaryRecommendations,
      type,
      originalSentence
    );
    let updatedText =
      type === "wholeSentence"
        ? smartGlossaryRecommendations?.original_sentence
        : englishWord.replace(dynamicSentence, originalSentence);
    console.log(
      "updatedSentece",
      englishWord,
      dynamicSentence,
      type,
      updatedText
    );
    if(flowType!=="glossaryReview"){
      debouncedUpdateEnglishText(updatedText);
    }
    
    setGlossaryInputData((prev) => {
      return {
        ...prev,
        englishWord: updatedText,
      };
    });
    animateInputFeild();
  };

  const isNoSmartGlossarySuggestions = () => {
    if (!smartGlossaryRecommendations) return true;
    if (
      !smartGlossaryRecommendations?.ai_generated_placeholder_sentence?.trim() &&
      !smartGlossaryRecommendations?.individual_elements?.length
    )
      return true;
    return false;
  };


  return fetchSuggestionsLoading ? (
    <div
      style={{ border: "1px dashed #ADACD4", padding: "12px 16px 12px 16px" }}
      className="rounded-lg text-start text-xs font-[Poppins]  font-semibold text-[#494799] "
    >
      Fetching for smart glossaries...
    </div>
  ) : (
    // : isNoSmartGlossarySuggestions() ? (
    //   <div
    //     style={{ border: "1px dashed #ADACD4", padding: "12px 16px 12px 16px" }}
    //     className="rounded-lg text-start text-xs font-[Poppins]  font-semibold text-[#494799] "
    //   >
    //     No smart glossaries found
    //   </div>
    // )
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      sx={{
        borderBottomLeftRadius: "0",
        borderBottomLeftRadius: "0",
        // "&:last-of-type": {
        //   borderRadius: "0px",
        // },
        "&::before": {
          height: 0, // Removes the height
        },
        padding: "5px 12px 12px 12px",
        boxShadow: "none",
        backgroundColor: "#F5F7FF",
        borderRadius: "8px",
        margin: "0px",
        border: "0.5px dashed rgba(30, 28, 128, 0.60)",
        "& .MuiAccordion-region": { height: "100%" },
        "& .MuiAccordionSummary-root": {
          // Targeting the accordion summary
          "&.Mui-expanded": {
            // Adjust min-height when expanded
            minHeight: "auto",
            margin: "0px",
            // alignItems: "start",
          },
        },
      }}
    >
      <AccordionSummary
        expandIcon={<GlossaryViewIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          borderBottomLeftRadius: "0",
          borderBottomLeftRadius: "0",
          paddingBottom: "0px",
          minHeight: "30px",
          height: isNoSmartGlossarySuggestions() ? "35px" : "55px",
          // paddingLeft: "5px",
          // alignItems: "start",
          margin: "0px",
          padding: "5px 0px",
        }}
      >
        <div className="flex flex-col w-full font-[Poppins] gap-[6px]">
          <div className="text-start text-xs  font-semibold text-[#1E1C80]">
            Smart Glossary Suggestion
          </div>
          {!isNoSmartGlossarySuggestions() && (
            <div className="text-[#6361A9] text-xs font-[Poppins] ">
              The Smart Glossary helps generalize translations for future use.
            </div>
          )}
          {/* { !expanded && <div className="flex -mr-2 w-full justify-end">
          <SmartGlossaryChatButton />
          </div>} */}
        </div>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "0",
          border: "none",
          boxShadow: "none",
          borderBottomLeftRadius: "0",
          borderBottomLeftRadius: "0",
          height: "100%",
        }}
      >
        <div className="flex flex-col gap-4">
          <div
            style={{
              background: "rgba(255, 255, 255, 0.70)",
              backdropFilter: "blur(6px)",
            }}
            className="flex bg-[#FFF] rounded font-[Poppins] flex-col gap-[22px] px-4 py-3"
          >
            {smartGlossaryRecommendations?.individual_elements?.length > 0 && (
              <div
                style={{ borderBottom: "1px solid #D8D8D8" }}
                className="flex flex-col gap-3 pb-3"
              >
                <div className="text-[#5F6368] text-xs font-[Poppins]">
                  Generalise Subject, Object, Verb, Nouns
                </div>
                {smartGlossaryRecommendations.individual_elements.map(
                  (sentenceData) => {
                     return sentenceData.placeholder_text && (
                      <div  key={sentenceData.original_text} className="flex justify-between items-center">
                        <div className="flex items-center gap-2">
                          <span className="py-1 px-2 bg-[#F5F5F5] rounded-lg max-w-[190px] text-[#979797] text-xs font-medium">
                            {sentenceData.original_text}
                          </span>
                          <RightArrow />
                          <span className="py-1 px-2 bg-[#F5F5FF] break-words rounded-lg  max-w-[180px]  text-[#979797] text-xs font-medium">
                            {" "}
                            {sentenceData.placeholder_text}
                          </span>
                        </div>
                        {sentenceData.isSentenceApplied ? (
                          <div
                            onClick={() => {
                              handleUndoSuggestion(
                                "sentencePart",
                                sentenceData.original_text,
                                sentenceData.placeholder_text
                              );
                            }}
                            className="flex items-center gap-1"
                          >
                            {" "}
                            <Undo />{" "}
                            <span className="text-[#1E1C80] break-words cursor-pointer text-xs font-medium">
                              {" "}
                              Undo
                            </span>
                          </div>
                        ) : (
                          <span
                            onClick={() => {
                              handleApplySmartSentence(
                                "part",
                                sentenceData.placeholder_text,
                                sentenceData.original_text
                              );
                            }}
                            className="text-[#1E1C80] cursor-pointer text-xs font-medium"
                          >
                            Apply{" "}
                          </span>
                        )}
                      </div>
                    );
                  }
                )}
              </div>
            )}
            {smartGlossaryRecommendations?.ai_generated_placeholder_sentence && (
              <div className="flex flex-col gap-3">
                <div className="text-[#5F6368] text-xs font-[Poppins]">
                  Rephrasing, Word order , Conjunction etc.
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-2">
                    <span className="py-1 px-2 bg-[#F5F5F5] break-words rounded-lg max-w-[190px] text-[#979797] text-xs font-medium">
                      {smartGlossaryRecommendations?.original_sentence}
                    </span>
                    <RightArrow />
                    <span className="py-1 px-2 bg-[#F5F5FF] break-words rounded-lg  max-w-[190px]  text-[#979797] text-xs font-medium">
                      {" "}
                      {
                        smartGlossaryRecommendations?.ai_generated_placeholder_sentence
                      }
                    </span>
                  </div>
                  {smartGlossaryRecommendations.isWholeSentenceApplied ? (
                    <div
                      onClick={() => {
                        handleUndoSuggestion(
                          "woleSentence",
                          smartGlossaryRecommendations.original_sentence,
                          smartGlossaryRecommendations?.ai_generated_placeholder_sentence
                        );
                      }}
                      className="flex items-center gap-1"
                    >
                      {" "}
                      <Undo />{" "}
                      <span className="text-[#1E1C80] cursor-pointer text-xs font-medium">
                        {" "}
                        Undo
                      </span>
                    </div>
                  ) : (
                    <span
                      onClick={() => {
                        handleApplySmartSentence(
                          "whole",
                          smartGlossaryRecommendations.ai_generated_placeholder_sentence,
                          smartGlossaryRecommendations.original_sentence
                        );
                      }}
                      className="text-[#1E1C80] cursor-pointer text-xs font-medium"
                    >
                      Apply{" "}
                    </span>
                  )}
                </div>
              </div>
            )}
            {isNoSmartGlossarySuggestions() && (
              <div className="flex flex-col items-center gap-2">
                {" "}
                <span className="text-xs italic text-[#5F6368]">
                  {" "}
                  No smart glossary recommendation found{" "}
                </span>
                <div
                  onClick={() =>
                    fetchGlossaryRecommendations(englishWord, "refresh")
                  }
                  className="flex items-center cursor-pointer gap-1"
                >
                  <Rotate />
                  <span className="text-[#1E1C80] cursor-pointer text-xs font-medium">
                    Refresh{" "}
                  </span>
                </div>
              </div>
            )}
          </div>
          {!isNoSmartGlossarySuggestions() ? (
            <div className="justify-between flex">
              <div
                onClick={() =>
                  fetchGlossaryRecommendations(englishWord, "refresh")
                }
                className="flex items-center cursor-pointer gap-1"
              >
                <Rotate />
                <span className="text-[#1E1C80] cursor-pointer text-xs font-medium">
                  Refresh{" "}
                </span>
              </div>
              <SmartGlossaryChatButton />
            </div>
          ) : (
            <div className="flex justify-end">
              <SmartGlossaryChatButton />
            </div>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default SmartGlossaryRecommendation;
