export const SET_IS_SMART_GLOSSARY = "actions/SET_IS_SMART_GLOSSARY";
export const SET_IS_GLOSSARY_ASSISTANT_ENABLED = "actions/SET_IS_GLOSSARY_ASSISTANT_ENABLED";
export const SET_FIND_GLOSSARY_CACHE = "actions/SET_FIND_GLOSSARY_CACHE"
export const SET_CURRENT_OCCURRENCE_NO = "actions/SET_CURRENT_OCCURRENCE_NO"
export const SET_SEARCHED_ENGLISH_WORD = "actions/SET_SEARCHED_ENGLISH_WORD"
export const SET_RESET_GLOSSARY_MATCHES_SCREEN = "actions/SET_RESET_GLOSSARY_MATCHES_SCREEN"


export const setIsSmartGlossary = (payload) => {
  return {
    type: SET_IS_SMART_GLOSSARY,
    payload: payload,
  };
};

export const setIsGlossaryAssistantEnabled = (payload) => {
  return {
    type: SET_IS_GLOSSARY_ASSISTANT_ENABLED,
    payload: payload,
  };
};


export const setCurrentOccurrenceNo = (payload) => {
  return {
    type: SET_CURRENT_OCCURRENCE_NO,
    payload: payload,
  };
};


export const setFindGlossaryCache = (payload) => {
  return {
    type: SET_FIND_GLOSSARY_CACHE,
    payload: payload,
  };
};

export const setSearchedEnglishWord = (payload) => {
  return {
    type: SET_SEARCHED_ENGLISH_WORD,
    payload: payload,
  };
};

export const setResetGlossaryMatchesScreen = (payload) => {
  return {
    type: SET_RESET_GLOSSARY_MATCHES_SCREEN,
    payload: payload,
  };
};
