import React, { useState } from "react";
import { ReactComponent as CircleExclamation } from "../../assets/circleExclamation.svg";
import { ReactComponent as Stars } from "../../assets/stars.svg";
import { ReactComponent as EditPencil } from "../../assets/editPencil.svg";
import { ReactComponent as Pencil } from "../../assets/pencil.svg";

import EditTranslation from "../EditTranslation/EditTranslation";
import ViewReasonAccordian from "../ViewReasonAccordian/ViewReasonAccordian";
import { cn } from "../../lib/utils";
import { CircularProgress } from "@mui/material";
import GlossaryAffectedSentence from "../GlossaryAffectedSentence/GlossaryAffectedSentence";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  getChapterNoFromString,
  updateGlossaryPreAppliedData,
  updatePredefinedClassName,
} from "../../utils/glossaryUtils";
import { useParams } from "react-router-dom";
import { scrollToHighlightedText } from "../../utils/translationUtil";
import { setPreAppliedGlossaryData } from "../../App/editor/editor.actions";
import { useSaveTranslatedChapter } from "../../hooks/useReviewTranslation";

function GlossaryApplicationCard({
  chapterKey,
  applicationData,
  iterationNo,
  openedGlossaryData,
  replaceTranslationLoader,
  setReplaceTranslationLoader,
  chapter,
  findOverBookData,
  findGlossaryData,
  handleRedirection,
  sourceEditor,
  translateEditor,
  screen,
  handleApproveGlossary,
}) {
  const { currentChapter, preAppliedGlossaryData } = useSelector(
    (state) => state.editor
  );
  const { currentOccurrenceNo } = useSelector(
    (state) => state.glossary
  );
  const [occurrenceNoToEdit, setOccurrenceNoToEdit] = useState(0);

  const getOccuranceNo = (chapterNo, occuranceNo) => {
    let finalOccuranceNo = 0;
    const keys =
      Object.keys(findOverBookData?.wordOccurrencesOverBook || {})?.sort(
        (a, b) => a - b
      ) || [];
    for (let i = 0; i < keys.length; i++) {
      const chapter = keys[i];
      if (chapter < chapterNo) {
        finalOccuranceNo += findOverBookData.wordOccurrencesOverBook[chapter];
      }
    }
    return finalOccuranceNo + occuranceNo;
  };

  const isOnCurrentOccurrence = (occurrenceNo, chapterNo) => {
    if (!sourceEditor || screen === "preAppliedGlossaryReview") return;
    const findAndReplaceEditing = sourceEditor.plugins.get(
      "FindAndReplaceEditing"
    );
    let result = false;
    const currentChapterOccurenceNo =
      findAndReplaceEditing.state.highlightedOffset;
    if (
      chapter?.chapter_number === chapterNo &&
      occurrenceNo === currentChapterOccurenceNo
    )
      result = true;
    return result;
  };

  const modifyEnglishText = (englishText) => {
    if (!englishText) return;
    return englishText.replaceAll("local_id", "preAppliedChangeId");
  };

  return (
    <>
      {applicationData?.latestTranslatedText?.trim() ? (
        <div
          key={iterationNo}
          style={{
            border:
              ((applicationData.occuranceNo===currentOccurrenceNo || isOnCurrentOccurrence(iterationNo + 1, Number(chapterKey))) ||
                (applicationData?.selectedCardId &&
                  applicationData?.selectedCardId ===
                    applicationData?.cardId)) &&
              "1px solid #3B37FF",
          }}
          className={cn(
            " bg-[#F5F7FF] rounded  px-3 pt-3 pb-2 flex gap-3 flex-col ",
            screen !== "preAppliedGlossaryReview" &&
              (applicationData.occuranceNo===currentOccurrenceNo || isOnCurrentOccurrence(iterationNo + 1, Number(chapterKey))) &&
              "selectedOccurrence",
            applicationData?.cardId && `${applicationData?.cardId}`
          )}
        >
          <div
            onClick={() => {
              if (screen === "preAppliedGlossaryReview") {
                handleRedirection(
                  applicationData.cardId,
                  applicationData.chapterString
                );
              } else {
                handleRedirection(iterationNo + 1, Number(chapterKey));
              }
            }}
            className="flex flex-col cursor-pointer  gap-2"
          >
            {screen !== "preAppliedGlossaryReview" && (
              <div className="flex justify-between items-center">
                <span className="text-[#475467] text-[12px]">
                  Fix {getOccuranceNo(Number(chapterKey), iterationNo + 1)}/
                  {findGlossaryData?.totalOccurances}
                </span>
                {applicationData?.status === "Skipped" && (
                  <div
                    style={{
                      border: "1px solid #E59A96",
                    }}
                    className="rounded-[12px] px-3 py-[2px] flex items-center text-[12px] font-[500] gap-2 bg-[#FFF0F0]  text-[#D25E58]"
                  >
                    <CircleExclamation />
                    <span>No change required</span>
                  </div>
                )}

                {applicationData?.status === "AiSuggested" && (
                  <div
                    style={{
                      border: "1px solid #D19200",
                    }}
                    className="rounded-[12px] px-3 py-[2px] flex items-center text-[12px] font-[500] gap-2 bg-[#FFF8E7]  text-[#D19200]"
                  >
                    <Stars className="text-[#D19200]" />
                    <span>New translation</span>
                  </div>
                )}
              </div>
            )}

            <div
              className=""
              dangerouslySetInnerHTML={{
                __html: modifyEnglishText(applicationData?.englishText),
              }}
            />
          </div>
          <div
            style={{
              boxShadow:
                "0px 2px 10px 0px rgba(0, 0, 0, 0.04), 2px 4px 4px 0px rgba(0, 0, 0, 0.00)",
              borderRadius: "4px 4px 0px 0px",
            }}
            className="flex gap-2 flex-col rounded-xl pb-3 pt-4 px-3 bg-white"
          >
            <GlossaryAffectedSentence
              iteration={applicationData}
              openedGlossaryData={openedGlossaryData}
            />
            <div className="flex justify-end items-center">
              {occurrenceNoToEdit !== applicationData?.occuranceNo && (
                <div className="flex items-center gap-5">
                  <div
                    onClick={() =>
                      setOccurrenceNoToEdit(applicationData?.occuranceNo)
                    }
                    className="flex items-center gap-2 cursor-pointer"
                  >
                    {" "}
                    <Pencil className="" />{" "}
                    <span className="font-[500] text-[12px] text-[#1E1C80]">
                      Edit Variant
                    </span>
                  </div>
                  {screen === "preAppliedGlossaryReview" && (
                    <div
                      onClick={() =>
                        handleApproveGlossary(
                          applicationData?.cardId,
                          applicationData?.chapterString
                        )
                      }
                      className="flex items-center gap-1 cursor-pointer"
                    >
                      {" "}
                      <DoneRoundedIcon
                        className={`${
                          applicationData.isApproved === applicationData?.cardId
                            ? "text-[#979797]"
                            : "text-[#3B37FF]"
                        } w-4 h-4`}
                      />{" "}
                      {applicationData.isApproved ===
                      applicationData?.cardId ? (
                        <span className="font-[500] text-[12px] text-[#979797]">
                          Approved
                        </span>
                      ) : (
                        <span className="font-[500] text-[12px] text-[#3B37FF]">
                          Approve
                        </span>
                      )}
                    </div>
                  )}
                </div>
              )}
              {occurrenceNoToEdit === applicationData?.occuranceNo && (
                <EditTranslation
                  replaceTranslationLoader={replaceTranslationLoader}
                  setReplaceTranslationLoader={setReplaceTranslationLoader}
                  englishSentence={applicationData?.englishText}
                  occurrenceNoToEdit={occurrenceNoToEdit}
                  changedTranslatedSentence={
                    applicationData?.latestTranslatedText
                  }
                  englishWord={openedGlossaryData?.english_word}
                  setOccurrenceNoToEdit={setOccurrenceNoToEdit}
                  translateEditor={translateEditor}
                  findOverBookData={findOverBookData}
                  chapterKey={chapterKey}
                  chapter={chapter}
                  screen={screen}
                  englishPara={applicationData?.englishPara?.replaceAll(
                    `local_id:"${applicationData.cardId}"`,
                    `local_id:"${applicationData.cardId}" class="ck-find-result_selected"`
                  )}
                />
              )}
            </div>
          </div>
         {/* {process.env.REACT_APP_ENVIRONMENT==="dev" && (!openedGlossaryData.is_smart_glossary) &&  <ViewReasonAccordian
            latestDutchSentence={applicationData?.latestTranslatedText}
            applicationReason={applicationData?.reason}
            variantUsed={applicationData?.variantUsed}
            defaultOpen={
              //  isOnCurrentOccurrence(iterationNo + 1, Number(chapterKey)) ||
              applicationData?.cardId &&
              applicationData?.cardId === applicationData?.selectedCardId
            }
          />} */}
        </div>
      ) : (
        <div
          onClick={() => {
            handleRedirection(iterationNo + 1, Number(chapterKey));
          }}
          key={iterationNo}
          style={{
            border: (applicationData.occuranceNo===currentOccurrenceNo  || isOnCurrentOccurrence(iterationNo + 1, Number(chapterKey)))
              ? "1px solid #3B37FF"
              : "1px solid #DFDFDF",
          }}
          className={cn(
            "text-black flex gap-2 flex-col cursor-pointer p-3 rounded text-sm",
           (applicationData.occuranceNo===currentOccurrenceNo  || isOnCurrentOccurrence(iterationNo + 1, Number(chapterKey))) &&
              "selectedOccurrence"
          )}
        >
          <div className="flex justify-between items-center">
            <span className="text-[#475467] text-[12px]">
            Occurrence: {getOccuranceNo(Number(chapterKey), iterationNo + 1)}/
              {findGlossaryData?.totalOccurances}
            </span>
            {applicationData.status === "Applying" && (
              <CircularProgress sx={{ color: "gray" }} size={16} />
            )}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: applicationData.englishText,
            }}
          />
        </div>
      )}
    </>
  );
}

export default GlossaryApplicationCard;
