// store.js
import { createStore, applyMiddleware ,compose} from 'redux';// You need to create reducers
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'

import mainReducer from './reducer'; // Import your reducers
import editorReducer from './editor/editor.reducers';
import glossaryReducer from './Glossary/glossary.reducer';

const rootReducer = combineReducers({
    main: mainReducer,
    editor: editorReducer,
    glossary:glossaryReducer
    // Add other reducers here
});


const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;



// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));