import React, { useEffect, useState, useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { collection, query, onSnapshot } from 'firebase/firestore';
import { firestore } from '../../firebase';

const GlossaryPreAppliedMonitor = ({ chapterNumber = 1 }) => {
    const { id } = useParams();
    const [paragraphsData, setParagraphsData] = useState({});
    const [filteredParagraphData, setFilteredParagraphData] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showSmartGlossary, setShowSmartGlossary] = useState(true);
    const [showGlossaryRelatedToEnglish, setShowGlossaryRelatedToEnglish] = useState(true);
    const [showAppliedGlossariesOnly, setShowAppliedGlossariesOnly] = useState(false);

    const glossaryCounts = useMemo(() => {
      let smartGlossaryCount = Object.entries(paragraphsData)
        .sort(([idA], [idB]) => parseInt(idA) - parseInt(idB))
        .map(([paragraphId, data]) => ({
          ...data[`chapter_${chapterNumber}_paragraph_${paragraphId}`],
          paragraphId,
          chapterNumber,
        }))
        .filter(
          (item) =>
            item.filtered_smart_glossary_data_of_current_paragraph?.length
        ).length;

      let englishRelatedGlossaryCount = Object.entries(paragraphsData)
        .sort(([idA], [idB]) => parseInt(idA) - parseInt(idB))
        .map(([paragraphId, data]) => ({
          ...data[`chapter_${chapterNumber}_paragraph_${paragraphId}`],
          paragraphId,
          chapterNumber,
        }))
        .filter(
          (item) => item.glossary_related_to_current_english_array?.length
        ).length;

      let glossaryAppliedCount =
        Object.entries(paragraphsData)
          .sort(([idA], [idB]) => parseInt(idA) - parseInt(idB))
          .map(([paragraphId, data]) => ({
            ...data[`chapter_${chapterNumber}_paragraph_${paragraphId}`],
            paragraphId,
            chapterNumber,
          }))
          .filter((paraData) => {
            // let isApplied = false;
            // Object.entries(
            //   JSON.parse(paraData?.regular_glossary_applied_status || "{}")
            // ).forEach((status) => {
            //   if (status === "applied") {
            //     isApplied = true;
            //   }
            // });
            // Object.entries(
            //   JSON.parse(paraData?.smart_glossary_applied_status || "{}")
            // ).forEach((status) => {
            //   if (status === "applied") {
            //     isApplied = true;
            //   }
            // });
            // return isApplied;
           return  paraData?.regular_glossary_applied_status === "applied" ||
            paraData?.smart_glossary_applied_status === "applied"
          })?.length || 0;
      //   .forEach(
      //     ({
      //       filtered_smart_glossary_data_of_current_paragraph,
      //       glossary_related_to_current_english_array,
      //     }) => {
      //       const countAppliedStatus = (glossaryArray) => {
      //         glossaryArray.forEach((smartGlossary) => {
      //           if (smartGlossary?.["AI-Status"] === "applied") {
      //             glossaryAppliedCount++;
      //           }
      //         });
      //       };

      //       countAppliedStatus(
      //         filtered_smart_glossary_data_of_current_paragraph
      //       );
      //       countAppliedStatus(glossary_related_to_current_english_array);
      //     }
      //   );

      return {
        smartGlossaryCount,
        englishRelatedGlossaryCount,
        glossaryAppliedCount,
      };
    }, [paragraphsData, chapterNumber]);

    useEffect(() => {
        if(!Object.keys(paragraphsData || {}).length) return ;
        let filteredParagraphData=Object.entries(paragraphsData)
        .sort(([idA], [idB]) => parseInt(idA) - parseInt(idB))
        .map(([paragraphId, data]) => ({ ...data[`chapter_${chapterNumber}_paragraph_${paragraphId}`], paragraphId, chapterNumber }))
        .filter(item =>
            (showSmartGlossary && item.filtered_smart_glossary_data_of_current_paragraph?.length) ||
            (showGlossaryRelatedToEnglish && item.glossary_related_to_current_english_array?.length)
        )
        if (showAppliedGlossariesOnly) {
          filteredParagraphData = filteredParagraphData.filter((paraData) => {
            // let isApplied = false;
            
            // Object.entries(
            //   JSON.parse(paraData?.regular_glossary_applied_status || "{}")
            // ).forEach((status) => {
            //   if (status === "applied") {
            //     isApplied = true;
            //   }
            // });
            // Object.entries(
            //   JSON.parse(paraData?.smart_glossary_applied_status || "{}")
            // ).forEach((status) => {
            //   if (status === "applied") {
            //     isApplied = true;
            //   }
            // });
            // return isApplied;
            return paraData?.regular_glossary_applied_status === "applied" ||
             paraData?.smart_glossary_applied_status === "applied"
          });
          // .map((paraData) => {
          //   paraData.filtered_smart_glossary_data_of_current_paragraph =
          //     paraData.filtered_smart_glossary_data_of_current_paragraph.filter(
          //       (smartGlossary) => smartGlossary?.["AI-Status"] === "applied"
          //     );
          //   paraData.glossary_related_to_current_english_array =
          //     paraData.glossary_related_to_current_english_array.filter(
          //       (glossary) => glossary?.["AI-Status"] === "applied"
          //     );
          //   return paraData;
          // })
          // .filter(
          //   (paraData) =>
          //     paraData?.filtered_smart_glossary_data_of_current_paragraph
          //       ?.length ||
          //     paraData?.glossary_related_to_current_english_array?.length
          // );
        }
        
        console.log("filteredParagraphData",filteredParagraphData)
        setFilteredParagraphData(filteredParagraphData)
    }, [showSmartGlossary,showGlossaryRelatedToEnglish,showAppliedGlossariesOnly,paragraphsData])
    

    const renderFilteredSmartGlossary = (filteredSmartGlossaryData) => {
        return filteredSmartGlossaryData.map((item, index) => (
            <div key={index} className="mb-2 border-b pb-2">
                <strong className="text-blue-600">English Word:</strong> {item.english_word}
                <ul className="list-disc pl-5 mt-1">
                    {item.target_language_word_array.map((dutchItem, dutchIndex) => (
                        <li key={dutchIndex} className="mb-1">
                            <strong className="text-green-600">Dutch Word:</strong> {dutchItem.replacement_target_language_word}
                            <br />
                            <strong className="text-purple-600">When to Apply:</strong> {dutchItem.instructions.whenToApply}
                            <br />
                            <strong className="text-red-600">Exception:</strong> {dutchItem.instructions.exception || 'None'}
                        </li>
                    ))}
                </ul>
            </div>
        ));
    };

    const renderNormalGlossaries = (glossaryRelatedToEnglishArray) => {
        return  glossaryRelatedToEnglishArray.map((item, index) => (
            <div key={index} className="mb-2 border-b pb-2">
                <strong className="text-blue-600">English Word:</strong> {item.english_word}
                <ul className="list-disc pl-5 mt-1">
                    {item.target_language_word_array.map((dutchItem, dutchIndex) => (
                        <li key={dutchIndex} className="mb-1">
                            <strong className="text-green-600">Dutch Word:</strong> {dutchItem.replacement_target_language_word}
                            <br />
                            <strong className="text-purple-600">When to Apply:</strong> {dutchItem.instructions.whenToApply}
                            <br />
                            <strong className="text-red-600">Exception:</strong> {dutchItem.instructions.exception || 'None'}
                        </li>
                    ))}
                </ul>
            </div>
        ));
    };

    const renderFilterOptions = () => {
        return (
            <div className="mb-4">
                <label className='mr-4'>Total Preapplication ({glossaryCounts.smartGlossaryCount + glossaryCounts.englishRelatedGlossaryCount})</label>
                <label className="inline-flex items-center mr-4">
                    <input
                        type="checkbox"
                        className="form-checkbox"
                        checked={showSmartGlossary}
                        onChange={(e) => setShowSmartGlossary(e.target.checked)}
                    />
                    <span className="ml-2">Smart Glossaries ({glossaryCounts.smartGlossaryCount}) </span>
                </label>
                <label className="inline-flex items-center mr-4">
                    <input
                        type="checkbox"
                        className="form-checkbox"
                        checked={showGlossaryRelatedToEnglish}
                        onChange={(e) => setShowGlossaryRelatedToEnglish(e.target.checked)}
                    />
                    <span className="ml-2">Normal Glossaries ({glossaryCounts.englishRelatedGlossaryCount})</span>
                </label>
                <label className="inline-flex items-center">
                    <input
                        type="checkbox"
                        className="form-checkbox"
                        checked={showAppliedGlossariesOnly}
                        onChange={(e) => setShowAppliedGlossariesOnly(e.target.checked)}
                    />
                    <span className="ml-2">Applied Glossaries ({glossaryCounts.glossaryAppliedCount})</span>
                </label>
            </div>
        );
    };

    useEffect(() => {
        if (!id) return;

        // Construct the path to the paragraphs collection
        const collectionPath = `glossary_application_logging/${id}/pre_applied/chapter_${chapterNumber}/paragraphs`;
        const paragraphsRef = collection(firestore, collectionPath);

        // Set up real-time listener for the entire collection
        const unsubscribeListener = onSnapshot(
            query(paragraphsRef),
            (snapshot) => {
                const newParagraphsData = {};

                snapshot.forEach((doc) => {
                    const paragraphData = doc.data();
                    if (paragraphData) {
                        newParagraphsData[doc.id] = paragraphData;
                    }
                });
                console.log('para', paragraphsData)
                setParagraphsData(newParagraphsData);
                setLoading(false);
            },
            (error) => {
                console.error("Error listening to pre-applied glossary data:", error);
                setError(error.message);
                setLoading(false);
            }
        );

        // Cleanup subscription
        return () => unsubscribeListener();
    }, [id, chapterNumber]);

    useEffect(() => {
        const data = Object.entries(paragraphsData)
            .sort(([idA], [idB]) => parseInt(idA) - parseInt(idB))
            .map(([paragraphId, data]) => ({ ...data[`chapter_${chapterNumber}_paragraph_${paragraphId}`], paragraphId, chapterNumber }))
            .filter(item => item.filtered_smart_glossary_data_of_current_paragraph?.length || item.glossary_related_to_current_english_array?.length);
        console.log('data', data);
    }, [paragraphsData])

    if (loading) {
        return (
            <div className="bg-white fixed z-[11] top-[135px] right-0 left-0 bottom-0 text-gray-500 p-4">
                Loading pre-applied glossary data...
            </div>
        );
    }

    if (error) {
        return (
            <div className="bg-white fixed z-[11] top-[135px] right-0 left-0 bottom-0 text-red-500 p-4">
                Error: {error}
            </div>
        );
    }

    if (Object.keys(paragraphsData).length === 0) {
        return (
            <div className="bg-white fixed z-[11] top-[135px] right-0 left-0 bottom-0 text-gray-500 p-4">
                No pre-applied glossary data available
            </div>
        );
    }

    return (
        <div className="bg-white fixed z-[11] top-[135px] right-0 left-[500px] bottom-[50px] overflow-auto">
            <div className="p-4">
                {renderFilterOptions()}
                {filteredParagraphData
                    .map((item) => (
                        <div key={item.paragraphId} className="mb-6 border-b pb-4">
                            <h3 className="font-semibold mb-2">Paragraph {item.paragraphId}</h3>
                            <div className="space-y-4">
                                <div>
                                    <h4 className="text-sm font-medium mb-1">Original:</h4>
                                    <div className="bg-gray-50 p-3 rounded"
                                        dangerouslySetInnerHTML={{
                                            __html: item?.original_paragraph
                                        }}
                                    />
                                </div>
                                <div>
                                    <h4 className="text-sm font-medium mb-1">Translated:</h4>
                                    <div className="bg-gray-50 p-3 rounded"
                                        dangerouslySetInnerHTML={{
                                            __html: item?.translated_paragraph
                                        }}
                                    />
                                </div>
                                {item.glossary_related_to_current_english_array.length > 0 &&<div>
                                    <h4 className="text-sm font-medium mb-1">Normal Glossaries Applied for above translation:</h4>
                                    <div className="bg-gray-50 p-3 rounded">
                                        {renderNormalGlossaries(item.glossary_related_to_current_english_array)}
                                    </div>
                                </div>}
                                {item.filtered_smart_glossary_data_of_current_paragraph.length > 0 &&<div>
                                    <h4 className="text-sm font-medium mb-1">Filtered Smart Glossary:</h4>
                                    <div className="bg-gray-50 p-3 rounded">
                                        {renderFilteredSmartGlossary(item.filtered_smart_glossary_data_of_current_paragraph || [])}
                                    </div>
                                </div>}
                                <div>
                                    <h4 className="text-sm font-medium mb-1">Normal Glossary Application Reason:</h4>
                                    <div className="bg-gray-50 p-3 rounded"
                                        dangerouslySetInnerHTML={{
                                            __html: item?.regular_glossary_data_replacement_reason || ""
                                        }}
                                    />
                                    
                                </div>
                                <div>
                                    <h4 className="text-sm font-medium mb-1">Smart Glossary Application Reason:</h4>
                                    <div className="bg-gray-50 p-3 rounded"
                                        dangerouslySetInnerHTML={{
                                            __html: item?.smart_glossary_data_replacement_reason || ""
                                        }}
                                    />
                                </div>
                                <div>
                                    <h4 className="text-sm font-medium mb-1">Final Translation After Smart Glossary Application:</h4>

                                    <div className="bg-gray-50 p-3 rounded"
                                        dangerouslySetInnerHTML={{
                                            __html: item?.post_smart_glossary_translated_paragraph
                                        }}
                                    />
                                </div>
                                <div>
                                    <h4 className="text-sm font-medium mb-1">Translation Score from LLM:</h4>
                                    <div className="bg-gray-50 p-3 rounded">
                                        {item?.para_score}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default GlossaryPreAppliedMonitor;