import React, { useEffect, useMemo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { getCapitalisedString } from "../../utils/translationUtil";
import { ReactComponent as GlossaryViewIcon } from "../../assets/glossaryViewIcon.svg";
import { useSelector } from "react-redux";

function ShowReviewedGlossary({ glossaryData, fetchGlossariesForReview }) {
  const { userOpenedBookInfo } = useSelector((state) => state.main);
  const { approvedGlossaries, rejectedGlossaries } = useMemo(() => {
    const approved = [];
    const rejected = [];
    Object.values(glossaryData).forEach((item) => {
      if (item.status === "approved") {
        approved.push(item);
      } else if (item.status === "rejected") {
        rejected.push(item);
      }
    });

    return {
      approvedGlossaries: approved,
      rejectedGlossaries: rejected,
    };
  }, [glossaryData]);


  const getScope = (scope, glossary) => {
    if (!scope) return "None";
    switch (scope) {
      case "book":
        return `Book : ${glossary?.book_title ?? "None"}`;
      case "author":
        return `Author : ${glossary?.author}`;
      case "genre":
        return `Genre : ${glossary?.genre ?? "Romance"}`;
      case "language":
        return `Language : ${glossary?.language ?? "Dutch"}`;
    }
  };
  return (
    <div className="flex flex-col gap-4 pt-4">
      <div className="flex items-center gap-6 w-full justify-between ">
        <div
          style={{ boxShadow: "0px -6px 20px 0px rgba(0, 0, 0, 0.05)" }}
          className="flex w-[33.33%] py-4 flex-col items-center"
        >
          <span className="text-base font-medium">Total</span>
          <span className="text-2xl font-medium">
            {approvedGlossaries.length + rejectedGlossaries.length}
          </span>
        </div>
        <div
          style={{ boxShadow: "0px -6px 20px 0px rgba(0, 0, 0, 0.05)" }}
          className="flex w-[33.33%] py-4 flex-col items-center"
        >
          <span className="text-base font-medium">Approved</span>
          <span className="text-2xl font-medium">
            {approvedGlossaries.length}
          </span>
        </div>
        <div
          style={{ boxShadow: "0px -6px 20px 0px rgba(0, 0, 0, 0.05)" }}
          className="flex w-[33.33%] py-4 flex-col items-center"
        >
          <span className="text-base font-medium">Rejected</span>
          <span className="text-2xl font-medium">
            {rejectedGlossaries.length}
          </span>
        </div>
      </div>

      <div
        style={{
          height: "calc(100dvh - 133px - 20px - 134px - 50px)",
        }}
        className="flex flex-col overflow-y-auto hideSourceScrollBar gap-5"
      >
        <div className="flex flex-col">
          <div className="text-[#1E1C80] text-sm">
            Approved ({approvedGlossaries.length})
          </div>
          <div className="flex flex-col">
            {approvedGlossaries.map((glossary) => {
              return (
                <Accordion
                  //  expanded={expanded}
                  //  onChange={handleChange}
                  sx={{
                    borderBottomLeftRadius: "0",
                    borderBottomLeftRadius: "0",
                    "&:last-of-type": {
                      borderRadius: "0px",
                    },
                    "&::before": {
                      height: 0, // Removes the height
                    },
                    boxShadow: "none",
                    borderBottom: "1px solid #BFBFBF",
                    "& .MuiAccordion-region": { height: "100%" },
                    "& .MuiAccordionSummary-root": {
                      // Targeting the accordion summary
                      "&.Mui-expanded": {
                        // Adjust min-height when expanded
                        minHeight: "auto",
                      },
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<GlossaryViewIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      display: "flex",
                      borderBottomLeftRadius: "0",
                      borderBottomLeftRadius: "0",
                      paddingBottom: "0px",
                      minHeight: "30px",
                      // height: "45px",
                      paddingLeft: "5px",
                    }}
                  >
                    <div className="flex justify-between gap-x-2 w-full items-center pr-2">
                      <span className="text-[16px]  text-[#000]">
                        {glossary.english_word}
                      </span>

                      <span className="text-[12px] shrink-0  text-[#000000]">
                        {glossary?.dutch_word_array?.length}{" "}
                        {glossary?.dutch_word_array?.length === 1
                          ? "Variant"
                          : "Variants"}
                      </span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      padding: "0",
                      border: "none",
                      boxShadow: "none",
                      borderBottomLeftRadius: "0",
                      borderBottomLeftRadius: "0",
                      height: "100%",
                    }}
                  >
                    <div className="flex  flex-col gap-2  px-0 pb-2  h-full  w-full">
                      <div className="flex bg-[#F5F7FF] rounded-[4px] flex-col px-3 py-2 gap-2">
                        <div className="text-sm flex justify-between items-center px-[2px] ">
                          <span className="text-[#1E1C80]">
                            {glossary?.dutch_word_array?.length <= 1
                              ? `${
                                  getCapitalisedString(
                                    userOpenedBookInfo?.translatedTo
                                  ) || "Dutch"
                                } Variant`
                              : `${
                                  getCapitalisedString(
                                    userOpenedBookInfo?.translatedTo
                                  ) || "Dutch"
                                } Variants`}
                          </span>
                        </div>
                        <div className="flex flex-col gap-2">
                          {glossary?.dutch_word_array?.map((varient, i) => {
                            return (
                              <div
                                style={{ border: "1px solid #3961FF" }}
                                className="py-2 px-[6px] flex flex-col rounded-[4px] bg-white"
                                key={i}
                              >
                                <div className="flex items-start pl-2 gap-2">
                                  <div className=" flex gap-2 w-full flex-col">
                                    <div className=" flex justify-between gap-x-1 w-full items-start ">
                                      <span
                                        style={{
                                          fontFamily: "Times New Roman",
                                        }}
                                        className=""
                                      >
                                        {varient.replacement_dutch_word}
                                      </span>
                                      <span
                                        style={{ border: "1px solid #6F6F6F" }}
                                        className="text-[#6F6F6F] shrink-0 text-[10px] font-[500] rounded-xl py-[2px] px-3"
                                      >
                                        {getScope(
                                          varient?.variant_scope?.toLowerCase(),
                                          glossary
                                        )}
                                      </span>
                                    </div>

                                    <div className="flex flex-col -mt-1 gap-1">
                                      <span className="text-[10px] text-[#475467] font-[Poppins]">
                                        When to apply:
                                      </span>
                                      <span className="text-[12px] font-[Poppins] font-[500]">
                                        {varient?.instructions?.whenToApply?.trim() ||
                                          "None"}
                                      </span>
                                    </div>

                                    <div className="flex flex-col gap-1">
                                      <span className="text-[10px] text-[#475467] font-[Poppins]">
                                        When to not apply:
                                      </span>
                                      <span className="text-[12px] font-[Poppins] font-[500]">
                                        {varient?.instructions?.exception?.trim() ||
                                          "None"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex items-end  justify-end gap-2"></div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="text-[#1E1C80] text-sm">
            Rejected ({rejectedGlossaries.length})
          </div>
          <div className="flex flex-col">
            {rejectedGlossaries.map((glossary) => {
              return (
                <Accordion
                  //  expanded={expanded}
                  //  onChange={handleChange}
                  sx={{
                    borderBottomLeftRadius: "0",
                    borderBottomLeftRadius: "0",
                    "&:last-of-type": {
                      borderRadius: "0px",
                    },
                    "&::before": {
                      height: 0, // Removes the height
                    },
                    boxShadow: "none",
                    borderBottom: "1px solid #BFBFBF",
                    "& .MuiAccordion-region": { height: "100%" },
                    "& .MuiAccordionSummary-root": {
                      // Targeting the accordion summary
                      "&.Mui-expanded": {
                        // Adjust min-height when expanded
                        minHeight: "auto",
                      },
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<GlossaryViewIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      display: "flex",
                      borderBottomLeftRadius: "0",
                      borderBottomLeftRadius: "0",
                      paddingBottom: "0px",
                      minHeight: "30px",
                      paddingLeft: "5px",
                    }}
                  >
                    <div className="flex justify-between gap-x-2 w-full items-center pr-2">
                      <span className="text-[16px]  text-[#000]">
                        {glossary.english_word}
                      </span>

                      <span className="text-[12px] shrink-0  text-[#000000]">
                        {glossary?.dutch_word_array?.length}{" "}
                        {glossary?.dutch_word_array?.length === 1
                          ? "Variant"
                          : "Variants"}
                      </span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      padding: "0",
                      border: "none",
                      boxShadow: "none",
                      borderBottomLeftRadius: "0",
                      borderBottomLeftRadius: "0",
                      height: "100%",
                    }}
                  >
                    <div className="flex  flex-col gap-2  px-0 pb-2  h-full  w-full">
                      <div className="flex bg-[#F5F7FF] rounded-[4px] flex-col px-3 py-2 gap-2">
                        <div className="text-sm flex justify-between items-center px-[2px] ">
                          <span className="text-[#1E1C80]">
                            {glossary?.dutch_word_array?.length <= 1
                              ? `${
                                  getCapitalisedString(
                                    userOpenedBookInfo?.translatedTo
                                  ) || "Dutch"
                                } Variant`
                              : `${
                                  getCapitalisedString(
                                    userOpenedBookInfo?.translatedTo
                                  ) || "Dutch"
                                } Variants`}
                          </span>
                        </div>
                        <div className="flex flex-col gap-2">
                          {glossary?.dutch_word_array?.map((varient, i) => {
                            return (
                              <div
                                style={{ border: "1px solid #3961FF" }}
                                className="py-2 px-[6px] flex flex-col rounded-[4px] bg-white"
                                key={i}
                              >
                                <div className="flex items-start pl-2 gap-2">
                                  <div className=" flex gap-2 w-full flex-col">
                                    <div className=" flex justify-between gap-x-1 w-full items-start ">
                                      <span
                                        style={{
                                          fontFamily: "Times New Roman",
                                        }}
                                        className=""
                                      >
                                        {varient.replacement_dutch_word}
                                      </span>
                                      <span
                                        style={{ border: "1px solid #6F6F6F" }}
                                        className="text-[#6F6F6F] shrink-0 text-[10px] font-[500] rounded-xl py-[2px] px-3"
                                      >
                                        {getScope(
                                          varient?.variant_scope?.toLowerCase(),
                                          glossary
                                        )}
                                      </span>
                                    </div>

                                    <div className="flex flex-col -mt-1 gap-1">
                                      <span className="text-[10px] text-[#475467] font-[Poppins]">
                                        When to apply:
                                      </span>
                                      <span className="text-[12px] font-[Poppins] font-[500]">
                                        {varient?.instructions?.whenToApply?.trim() ||
                                          "None"}
                                      </span>
                                    </div>

                                    <div className="flex flex-col gap-1">
                                      <span className="text-[10px] text-[#475467] font-[Poppins]">
                                        When to not apply:
                                      </span>
                                      <span className="text-[12px] font-[Poppins] font-[500]">
                                        {varient?.instructions?.exception?.trim() ||
                                          "None"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex items-end  justify-end gap-2"></div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowReviewedGlossary;
