import React from "react";
import "./DashboardLoader.scss";

const DashboardLoader = () => {
  return (
    <div className="dashLoader-overlay">
      <div className="dashLoader-container">
        <div className="text-[#fff]">Loading your books...</div>
      </div>
    </div>
  );
};

export default DashboardLoader;
