import React from "react";
import parse, { domToReact } from "html-react-parser";
import { ReactComponent as Undo } from "../../assets/undo.svg";

import { Tooltip, Typography, styled } from "@mui/material";

function TextDifference({
  sentence,
  sentenceDifferenceData,
  glossaryInputData,
  setGlossaryInputData,
  sentenceType,
}) {
  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "white",
      color: "black",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
      padding: "0",
      //   width:"170px"
    },
    "& .MuiTooltip-arrow": {
      color: "white", // Arrow color
    },
  }));
  const extractPlainText = (domNode) => {
    if (!domNode) return "";

    // If the node is text, return its value
    if (domNode.type === "text") return domNode.data;

    // If the node has children, recursively get their text content
    if (domNode.children) {
      return domNode.children.map(extractPlainText).join("");
    }
    return "";
  };

  const replaceSpans = (htmlContent) => {
    return parse(htmlContent, {
      replace: (domNode) => {
        try {
          if (
            domNode.name === "span" &&
            domNode.attribs?.class === "removedOldText"
          ) {
            // You can check for a specific class or attribute here
            let elementText = domToReact(domNode.children, replaceSpans);
            let wordLevelChanges = sentenceDifferenceData.wordLevelChanges;
            let replaceWordDetails = Object.keys(wordLevelChanges).find(
              (text) =>
                text.trim().toLowerCase() === elementText.trim().toLowerCase()
            );
            if (
              replaceWordDetails &&
              ((sentenceType === "source" &&
                glossaryInputData.englishWord.includes(
                  wordLevelChanges[replaceWordDetails]
                )) ||
                (sentenceType === "translated" &&
                  glossaryInputData.replacement_dutch_word.includes(
                    wordLevelChanges[replaceWordDetails]
                  )))
            ) {
              return (
                <CustomTooltip
                  placement="top"
                  title={
                    <React.Fragment>
                      <div
                        onClick={() => {
                          let dynamicText =
                            wordLevelChanges[replaceWordDetails];
                          let originalText = elementText;
                          if (sentenceType === "source") {
                            let updatedText =
                              glossaryInputData.englishWord.replace(
                                dynamicText,
                                originalText
                              );
                            setGlossaryInputData({
                              ...glossaryInputData,
                              englishWord: updatedText,
                            });
                          }

                          if (sentenceType === "translated") {
                            let updatedText =
                              glossaryInputData.replacement_dutch_word.replace(
                                dynamicText,
                                originalText
                              );
                            setGlossaryInputData({
                              ...glossaryInputData,
                              replacement_dutch_word: updatedText,
                            });
                          }
                        }}
                        className="flex gap-2 p-3 text-[#475467] cursor-pointer font-medium text-xs"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                        >
                          <path
                            d="M5.63016 13.1649C5.44127 13.1649 5.28294 13.101 5.15516 12.9732C5.02738 12.8455 4.96349 12.6871 4.96349 12.4982C4.96349 12.3094 5.02738 12.151 5.15516 12.0232C5.28294 11.8955 5.44127 11.8316 5.63016 11.8316H9.69683C10.3968 11.8316 11.0052 11.6094 11.5218 11.1649C12.0385 10.7205 12.2968 10.1649 12.2968 9.49824C12.2968 8.83158 12.0385 8.27602 11.5218 7.83158C11.0052 7.38713 10.3968 7.16491 9.69683 7.16491H5.49683L6.76349 8.43158C6.88571 8.5538 6.94683 8.70935 6.94683 8.89824C6.94683 9.08713 6.88571 9.24269 6.76349 9.36491C6.64127 9.48713 6.48571 9.54824 6.29683 9.54824C6.10794 9.54824 5.95238 9.48713 5.83016 9.36491L3.43016 6.96491C3.36349 6.89824 3.31627 6.82602 3.28849 6.74824C3.26072 6.67046 3.24683 6.58713 3.24683 6.49824C3.24683 6.40935 3.26072 6.32602 3.28849 6.24824C3.31627 6.17046 3.36349 6.09824 3.43016 6.03158L5.83016 3.63158C5.95238 3.50935 6.10794 3.44824 6.29683 3.44824C6.48571 3.44824 6.64127 3.50935 6.76349 3.63158C6.88571 3.7538 6.94683 3.90935 6.94683 4.09824C6.94683 4.28713 6.88571 4.44269 6.76349 4.56491L5.49683 5.83158H9.69683C10.7746 5.83158 11.6996 6.18158 12.4718 6.88158C13.244 7.58158 13.6302 8.4538 13.6302 9.49824C13.6302 10.5427 13.244 11.4149 12.4718 12.1149C11.6996 12.8149 10.7746 13.1649 9.69683 13.1649H5.63016Z"
                            fill="#475467"
                          />
                        </svg>{" "}
                        Revert generalization
                      </div>
                    </React.Fragment>
                  }
                  arrow
                >
                  <span className={`${domNode.attribs?.class} cursor-pointer`}>
                    {domToReact(domNode.children, replaceSpans)}
                  </span>
                </CustomTooltip>
              );
            }
          }
        } catch (error) {
          console.log("error in parsing sentence", error);
        }
      },
    });
  };
  console.log("sentdfdfdnce", sentence);
  return (
    <div
      //   style={{
      //     borderBottom: "1px solid #E3E3E3",
      //   }}
      className=""
    >
      {sentence.trim() ? replaceSpans(sentence) : "None"}
    </div>
  );
}

export default TextDifference;
