import React, { useCallback, useEffect, useState } from "react";
import "./GlossaryMatches.scss";

import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as AddGlossaryIcon } from "../../assets/addGlossaryIcon.svg";
import { ReactComponent as AlertInfo } from "../../assets/alertInfo.svg";
import { ReactComponent as PlayIcon } from "../../assets/playIcon.svg";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

import { ToastContainer, toast } from "react-toastify";
import { Box, Button, TextField, Tooltip, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addGlossarytoTheBook,
  applyGlossaryOnOccurrence,
  deleteGlossaryFromTheBook,
  getGlossaryAffectedParagraphs,
  highlightNextOccurrenceInSourceEditor,
  removeAddedSpanTags,
  scrollOccurrenceContainer,
  scrollTowardsText,
  updateGlossarytoTheBook,
} from "../../utils/glossaryUtils";
import {
  getUserNameFromEmail,
  scrollToHighlightedText,
  searchTextOverBook,
  updateUserActiveTime,
} from "../../utils/translationUtil";
import GlossaryScopePopup from "../glossaryScopePopup/GlossaryScopePopup";
import GlossaryWarningModal from "../glosssaryWarningModal/GlossaryWarningModal";
import AccordianGlossary from "../Accordian/AccordianGlossary";
import AddGlossaryForm from "../AddGlossaryForm/AddGlossaryForm";
import { useParams } from "react-router-dom";
import store from "../../App/store";
import GlossaryApplicationStatus from "../GlossaryApplicationStatus/GlossaryApplicationStatus";
import {
  setGlossaryAppliedData,
  setGlossaryScreenType,
  setIsGlossaryApplying,
} from "../../App/editor/editor.actions";
import { collection, doc, onSnapshot, setDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import {
  cleanGlossaryApplicationData,
  removeGlossarApplicationDataViaOccurrrence,
} from "../../utils/firebaseActions";
import { useSaveTranslatedChapter } from "../../hooks/useReviewTranslation";
import LinearProgressBar from "../LinearProgressBar/LinearProgressBar";
import GlossaryFooter from "../GlossaryFooter/GlossaryFooter";
import debounce from "lodash.debounce";
import GlossaryEnglishWordInput from "../GlossaryEnglishWordInput/GlossaryEnglishWordInput";
import { ReactComponent as Pencil } from "../../assets/pencil.svg";
import { setCurrentOccurrenceNo, setIsGlossaryAssistantEnabled, setIsSmartGlossary, setSearchedEnglishWord } from "../../App/Glossary/glossary.action";
import CreateGlossary from "../CreateGlossary/CreateGlossary";
const GlossaryMatches = ({
  highlightedEnglish,
  chapter,
  getBookGlossaryData,
  handleApplyGlossary,
  setSelectedGlossary,
  selectedGlossary,
  sourceEditor,
  handleChapterChange,
  fetchTranslatedChapterPending,
  setOpenedGlossaryData,
  openedGlossaryData,
  translateEditor,
  // setIsLayoutFixed,
  // setTranslatedContent,
  // setGlossarySectionToggle,
  // individualScroll,
  // setIsFirstOccurence,
  // setFindOverBookData,
  // setFindGlossaryData,
  // isChaptersLastOccurances,
  updateFindTextResults,
  findGlossaryData,
  findOverBookData,
  findWordInSourceEditor,
  isFirstOccurence,
  removeGlossaryChangesFromTranslatedChapter,
  handleCloseGlossaryPannel,
  handleFixGlossary,
  resetScreen,
  setResetScreen
}) => {
  // console.log("englishWord",englishWord)
  const params = useParams();
  const { loggedInUserEmail } = useSelector((state) => state.main);
  const { isGlossaryAssistantEnabled ,searchedEnglishWord} = useSelector((state) => state.glossary);
  const { isGlossaryApplying, isTranslationContentEdited } = useSelector(
    (state) => state.editor
  );
  const [smartGlossaryRecommendations, setSmartGlossaryRecommendations] =
  useState(undefined);
  const {isSmartGlossary,currentOccurrenceNo}=useSelector((state)=>state.glossary)
  const [isLoading, setIsLoading] = useState(false);
  const [viewAllFeilds, setViewAllFeilds] = useState(false);
  const [showVariantInputFeild, setShowVariantInputFeild] = useState(false);
  const [deleteWarningData, setDeleteWarningData] = useState(null);
  const [highlightedEnglishText, setHighlightedEnglishText] = useState("");
  const [glossaryMatchesData, setGlossaryMatchesData] = useState([]);
  const [enableEnglishWordEditing, setEnableEnglishWordEditing] = useState(false)
  const [applicationProgress, setApplicationProgress] = useState({
    occuranceNo: 0,
    chapterNo: 0,
  });
   const [glossaryInputData, setGlossaryInputData] = useState({
      replacement_dutch_word: "",
      scope: "",
      whenToApply: "",
      exceptions: "",
      englishWord: highlightedEnglish.text,
    });
  const [showLoader, setShowLoader] = useState(false);
  const [showGlossaryNotFoundWarning, setShowGlossaryNotFoundWarning] =
    useState(false);
  const [disableApplyButton, setDisableApplyButton] = useState(false);
  const [fetchSuggestionsLoading , setFetchSuggestionsLoading ] = useState(false)
  const dispatch = useDispatch();
  const {
    glossaryList: glossaryData,
    glossaryAppliedData,
    currentChapter,
  } = useSelector((state) => state.editor);
  const [sectionType, setSectionType] = useState("glossaryMatches");
  const [highlightedTextGlossaryItem, setHighlightedTextGlossaryItem] =
    useState(false);
  const [updateIterationData, setUpdateIterationData] = useState({
    chapterNo: "",
    iterationNo: "",
  });


  useEffect(() => {
    if (Object.keys(glossaryAppliedData).length) {
      document.documentElement.style.setProperty(
        `--glossary-highlight-transparent-bg`,
        "#e6e6ff"
      );
    } else {
      document.documentElement.style.setProperty(
        `--glossary-highlight-transparent-bg`,
        "transparent"
      );
      removeGlossaryChangesFromTranslatedChapter();
    }
  }, [glossaryAppliedData]);

  useEffect(() => {
    if(resetScreen){
      setSectionType("glossaryMatches")
      setShowVariantInputFeild(false);
    }
  }, [resetScreen])
  
  const debouncedUpdateUserActiveTime = useCallback(
    debounce((translationId, loggedInUserEmail) => {
      try {
        return updateUserActiveTime(translationId, loggedInUserEmail);
      } catch (error) {}
    }, 1000),
    []
  );

  const updateLastActiveTimeOnScroll = () => {
    debouncedUpdateUserActiveTime(params.id, loggedInUserEmail);
  };

  useEffect(() => {
    if(highlightedEnglish.text){
      setGlossaryInputData((prev)=>{
        return {...prev,englishWord:highlightedEnglish.text}})
    }
  }, [highlightedEnglish.text])
  

  useEffect(() => {
    const finalEnglishWord = searchedEnglishWord || highlightedEnglish.text || highlightedEnglishText;
    if (chapter && highlightedEnglishText && !fetchTranslatedChapterPending) {
        // console.log("updatedEnglishText",updateIterationData,findOverBookData)
      if (
        // !newFilteredData.length &&
        !updateIterationData.chapterNo
      ) {
        findWordInSourceEditor({
          operation: "find",
          englishText: finalEnglishWord,
          isFirstOccurence: isFirstOccurence,
        });
        dispatch(setCurrentOccurrenceNo(0))
      }

      if (updateIterationData.isSmartGlossary) {
        scrollTowardsText(findOverBookData || {},updateIterationData?.occurrenceNo,false)
        highlightNextOccurrenceInSourceEditor(
          currentOccurrenceNo,
          updateIterationData?.occurrenceNo
        );
        dispatch(setCurrentOccurrenceNo(updateIterationData?.occurrenceNo))
        setUpdateIterationData({ chapterNo: "", iterationNo: "" });
        return
      }

      if (updateIterationData.chapterNo) {
        const findAndReplaceEditing = sourceEditor.plugins.get(
          "FindAndReplaceEditing"
        );
        findWordInSourceEditor({
          operation: "find",
          englishText: finalEnglishWord,
          occurrenceNo: findAndReplaceEditing?.state?.results?.get(
            updateIterationData.iterationNo - 1 ?? 0
          ),
        });
        setUpdateIterationData({ chapterNo: "", iterationNo: "" });
        return;
      }
    }

    const glossaryListContainer = document.querySelector(
      ".machtesGlossaryList"
    );
    glossaryListContainer?.addEventListener(
      "scroll",
      updateLastActiveTimeOnScroll
    );
    findGlossaryMatches(highlightedEnglishText)
    return () =>
      glossaryListContainer?.removeEventListener(
        "scroll",
        updateLastActiveTimeOnScroll
      );
  }, [highlightedEnglishText, fetchTranslatedChapterPending]);

  const findGlossaryMatches=(finalEnglishWord)=>{
    const lowercasedSearchTerm = finalEnglishWord.trim().toLowerCase();
    let glossaryExactMatch = "";
    const newFilteredData = glossaryData.filter((glossary) => {
      if (
        glossary.english_word.toLocaleLowerCase().trim() ===
        lowercasedSearchTerm
      ) {
        setOpenedGlossaryData(glossary);
        glossaryExactMatch = glossary;
        return false;
      }

      if (
        glossary.english_word.toLowerCase().includes(lowercasedSearchTerm) ||
        lowercasedSearchTerm.includes(glossary.english_word.toLowerCase())
      )
        return true;

      let isVariantFound = false;
      glossary.dutch_word_array.forEach((variant) => {
        if (
          variant.replacement_dutch_word
            .toLowerCase()
            .includes(lowercasedSearchTerm)
        )
          isVariantFound = true;
      });

      return isVariantFound;
    });
    setGlossaryMatchesData(!lowercasedSearchTerm ? [] : newFilteredData);
    if (glossaryExactMatch?.english_word) {
      setHighlightedTextGlossaryItem(glossaryExactMatch);
    } else {
      setShowGlossaryNotFoundWarning(true);
      setHighlightedTextGlossaryItem({});
    }
  }

  useEffect(() => {
    if (highlightedEnglish.text || highlightedEnglishText) {
      let finalEnglishWord = highlightedEnglish.text || highlightedEnglishText;
      setHighlightedEnglishText(finalEnglishWord);
      dispatch(setSearchedEnglishWord(finalEnglishWord))
      findGlossaryMatches(finalEnglishWord)
      document.documentElement.style.setProperty(
        `--glossary-highlight-bg`,
        "#3B37FF33"
      );
      // document.documentElement.style.setProperty(
      //   `--glossary-highlight-transparent-bg`,
      //   "transparent"
      // );
    }
  }, [highlightedEnglish,glossaryData]);

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "black",
      color: "white",
    },
    "& .MuiTooltip-arrow": {
      color: "black", // Arrow color
    },
  }));

  const getNewVariant = (inputData, bookInfo) => {
    return {
      ...bookInfo,
      metadata_storage: {
        "creation date": new Date(),
        creation_user: loggedInUserEmail,
        application_history: [],
      },
      instructions: {
        whenToApply: inputData.whenToApply,
        exception: inputData.exceptions,
      },
      variant_scope: inputData.scope,
      replacement_dutch_word: inputData?.replacement_dutch_word?.trim(),
    };
  };

  const isVariantAlreadyExists = (variant, variantList) => {
    let isThisVariantAlreadyExists = variantList?.find(
      (dutchVariant) =>
        dutchVariant?.replacement_dutch_word?.toLowerCase() ===
        variant?.trim()?.toLocaleLowerCase()
    );

    if (isThisVariantAlreadyExists) {
      toast(`Variant already exists`, {
        type: "warning",
        autoClose: 3000,
      });
      return true;
    }

    return false;
  };

  const handleUpdateGlossary = async ({
    action,
    inputData,
    englishText,
    variant,
    type,
  }) => {
    updateUserActiveTime(params.id, loggedInUserEmail);
    if (isLoading) return;
    let toSearchEnglishWord = englishText;
    const isCurrentGlossaryExists = glossaryData.find(
      (glossary) =>
        glossary?.english_word?.toLowerCase() ===
        toSearchEnglishWord?.toLowerCase()
    );

    let updatedDutchVariants = isCurrentGlossaryExists?.dutch_word_array?.length
      ? [...isCurrentGlossaryExists?.dutch_word_array]
      : [];
    const bookInfo = {
      author: chapter.book_info.author_name,
      language: chapter?.translation_info?.translated_to?.toLowerCase(),
      genre: chapter.book_info.genre,
    };

    switch (action) {
      case "add":
        if (!inputData.replacement_dutch_word.trim()) {
          toast(`Input feild cannot be empty`, {
            type: "warning",
            autoClose: 3000,
          });
          return;
        }

        if (!isCurrentGlossaryExists || isCurrentGlossaryExists === -1) {
          setIsLoading(true);
          const glossaryPayload = {
            unique_string: chapter.translation_id,
            glossary_item: {
              ...bookInfo,
              english_word: englishText,
              dutch_word_array: [getNewVariant(inputData, bookInfo)],
              glossary_item_scope: "Book",
              application_scope: "book_level",
              chapter_numbers: [],
              is_smart_glossary: isSmartGlossary,
            },
          };
         const newGlossary= await addGlossarytoTheBook(glossaryPayload);
          dispatch(setIsSmartGlossary(false))
          setShowVariantInputFeild(false);
          getBookGlossaryData();
          setIsLoading(false);
          return newGlossary.Glossary_item;
        }

        if (
          isCurrentGlossaryExists &&
          !isVariantAlreadyExists(
            inputData.replacement_dutch_word,
            isCurrentGlossaryExists.dutch_word_array
          )
        ) {
          updatedDutchVariants.unshift(getNewVariant(inputData, bookInfo));
        } else {
          return;
        }
        break;
      case "update":
        if (!inputData.replacement_dutch_word.trim()) {
          toast(`Input feild cannot be empty`, {
            type: "warning",
            autoClose: 3000,
          });
          return;
        }

        updatedDutchVariants = updatedDutchVariants.map((dutchVariant) => {
          if (dutchVariant.replacement_dutch_word === variant) {
            dutchVariant.replacement_dutch_word =
              inputData.replacement_dutch_word.trim();
            dutchVariant.variant_scope = inputData.scope;
            dutchVariant.instructions.whenToApply =
              inputData.whenToApply.trim();
            dutchVariant.instructions.exception = inputData?.exceptions;
          }
          return dutchVariant;
        });
        break;
      case "delete":
        if (!updatedDutchVariants.length) return;

        updatedDutchVariants = updatedDutchVariants.filter(
          (dutchVariant) => dutchVariant?.replacement_dutch_word !== variant
        );

        if (!updatedDutchVariants.length || type === "glossaryItem") {
          await deleteGlossaryFromTheBook(isCurrentGlossaryExists.id);
          setOpenedGlossaryData({});
          const findAndReplaceEditing = sourceEditor.plugins.get(
            "FindAndReplaceEditing"
          );
          findAndReplaceEditing.find(highlightedEnglishText,{searchText:highlightedEnglishText,matchCase:false,wholeWords:true})
          updateFindTextResults(highlightedEnglishText)
          if (
            selectedGlossary.englishWord ===
            isCurrentGlossaryExists.english_word
          )
            setSelectedGlossary({ englishWord: "", variant: "" });

          return;
        }
        break;
      default:
        break;
    }

    const glossaryPayload = {
      ...bookInfo,
      english_word: isCurrentGlossaryExists?.english_word,
      dutch_word_array: updatedDutchVariants,
      glossary_item_scope: "Book",
      application_scope: "book_level",
      chapter_numbers: [],
      is_active: true,
      is_part_of_collection: false,
      application_history: null,
    };
        
    await updateGlossarytoTheBook(glossaryPayload, isCurrentGlossaryExists.id);
    if(action==="add"){
      toast(`Variant added successfully!`, {
        type: "success",
        autoClose: 3000,
      });
    }

    setShowVariantInputFeild(false);
    return updatedDutchVariants;
  };

  const handleDeleteGlossaryThroughPopup = () => {
    if (deleteWarningData.type === "Glossary") {
      handleUpdateGlossary("delete", "dummyVariant", "glossaryItem");
    } else {
      handleUpdateGlossary("delete", deleteWarningData.variant, "variant");
    }

    setDeleteWarningData(null);
  };

  const getTotalGlossaryLenth = () => {
    let length = glossaryMatchesData.length;
    length += highlightedTextGlossaryItem?.english_word ? 1 : 0;
    return length;
  };

  const checkFixOperationType = () => {
    const applicationData =
      glossaryAppliedData?.[
        findOverBookData?.englishWord || highlightedEnglishText
      ];

    if (!applicationData) return;
    let isPending = false;
    let isApplied = false;
    Object.keys(applicationData?.affectedChaptersData || {})?.forEach(
      (occuranceNo) => {
        if (
          applicationData?.affectedChaptersData[occuranceNo].status ===
          "applied"
        )
          isApplied = true;

        if (
          applicationData?.affectedChaptersData[occuranceNo].status ===
          "pending"
        )
          isPending = true;
      }
    );

    if (isPending && isApplied) return "fix";

    if (!isPending && isApplied) return "fixAll";
  };

  const handleChangeScreenType = () => {
    setSectionType("applicationStatus");
    setResetScreen(false)
  };

  return (
    <>
      <div
        style={{ height: "calc(100dvh - 133px - 71px)" }}
        className={`w-[576px] shrink-0 top-[100px] left-4 glossaryContainer fixed  gap-2 flex 
            "pb-[67.46px]" 
           flex-col px-3 bg-[#FFFFFF] my-12  rounded-md`}
      >
        <ToastContainer hideProgressBar={true} />
        {sectionType === "glossaryMatches" && (
          <>
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <span className="text-sm text-[#1E1C80]">
                  Glossary for "{ highlightedEnglishText.length > 50?highlightedEnglishText.slice(0,50)+ "..." : highlightedEnglishText}" (
                  {getTotalGlossaryLenth()})
                </span>
              </div>
              <div>
                <CloseIcon
                  className="cursor-pointer"
                  onClick={() => {
                    if (isLoading) return;
                    dispatch(setIsGlossaryAssistantEnabled(false))
                    handleCloseGlossaryPannel();
                  }}
                />
              </div>
            </div>
            {/* <div className="text-[#5F6368] text-sm font-[Poppins]">
              Match found ({glossaryMatchesData.length})
            </div> */}
             {/**Hide glossary waning */}
            {/* {!highlightedTextGlossaryItem?.english_word &&
              showGlossaryNotFoundWarning && (
                <div
                  className="flex gap-[22px] text-sm justify-between rounded-[4px] py-3 px-4 bg-[#FFFBE6]"
                  style={{ border: "1px solid #FFE58F" }}
                >
                  <div className="flex gap-2 items-start">
                    <AlertInfo />
                    <span className="-mt-1">
                      No translations found.
                    </span>
                  </div>
                  <CloseIcon
                    onClick={() => {
                      setShowGlossaryNotFoundWarning(false);
                      updateUserActiveTime(params.id, loggedInUserEmail);
                    }}
                    className="cursor-pointer text-base text-[#8C8A7E]"
                  />
                </div>
              )} */}
            <div
              style={{
                height:
                  // highlightedTextGlossaryItem?.english_word ? "calc(100dvh - 133px - 170px)" :
                   //@hide glossary warning
                  // showGlossaryNotFoundWarning
                  //   ? "calc(100dvh - 133px - 179px - 42px)"
                    // :
                     "calc(100dvh - 133px - 170px)",
              }}
              className="flex flex-col overflow-y-scroll machtesGlossaryList pb-2 hideSourceScrollBar gap-2"
            >
              {!showVariantInputFeild &&
                !highlightedTextGlossaryItem?.english_word && (
                  <CreateGlossary 
                  chapter={chapter}
                  setShowVariantInputFeild={setShowVariantInputFeild}
                  setGlossaryMatchesData={setGlossaryMatchesData}
                  setOpenedGlossaryData={setOpenedGlossaryData}
                  glossaryMatchesData={glossaryMatchesData}
                  handleUpdateGlossary={handleUpdateGlossary}
                  operationType={"add"}
                  setSelectedGlossary={setSelectedGlossary}
                  englishWord={highlightedEnglishText}
                  setHighlightedEnglishText={setHighlightedEnglishText}
                  smartGlossaryRecommendations={smartGlossaryRecommendations}
                  setSmartGlossaryRecommendations={setSmartGlossaryRecommendations}
                  findOverBookData={findOverBookData}
                  setFetchSuggestionsLoading={setFetchSuggestionsLoading}
                  fetchSuggestionsLoading={fetchSuggestionsLoading}
                  glossaryInputData={glossaryInputData}
                  setGlossaryInputData={setGlossaryInputData}
                  setViewAllFeilds={setViewAllFeilds}
                  viewAllFeilds={viewAllFeilds}
                  />
                  
                  // <div className="flex flex-col mb-2 gap-3">
                  //   {!enableEnglishWordEditing ?<div  className="py-1 bg-[#F5F7FF] flex items-center  justify-between  px-2 rounded-[4px]">
                  //     {highlightedEnglishText}
                  //     <Pencil onClick={()=>setEnableEnglishWordEditing(true)} className="w-4 h-4 cursor-pointer shrink-0"/>
                  //   </div>:
                  //   <GlossaryEnglishWordInput
                  //     highlightedEnglishText={highlightedEnglishText}
                  //     isSmartGlossary={isSmartGlossary}
                  //     setHighlightedEnglishText={setHighlightedEnglishText}
                  //     setEnableEnglishWordEditing={setEnableEnglishWordEditing}
                  //   />}

                  //   <div
                  //     onClick={() => {
                  //       setShowVariantInputFeild(true);
                  //       setResetScreen(false);
                  //       setShowGlossaryNotFoundWarning(false);
                  //       updateUserActiveTime(params.id, loggedInUserEmail);
                  //     }}
                  //     className="py-2 text-[12px] text-[#3B37FF] rounded-[4px]  h-[190px] bg-[#F8F9FC] px-2 cursor-pointer flex items-center justify-center gap-1"
                  //   >
                  //     <AddGlossaryIcon className="text-[#3B37FF]" />
                  //     Add translation
                  //   </div>
                  // </div>
                )}
              {showVariantInputFeild && (
                <div className="bg-[#F8F9FC] flex flex-col px-3 pt-1 mb-2 gap-2 rounded-[4px]">
                  {!enableEnglishWordEditing ?<div  className="py-1 items-center  justify-between flex pt-2 rounded-[4px]">
                    {highlightedEnglishText}
                    <Pencil onClick={()=>setEnableEnglishWordEditing(true)} className="w-4 h-4 shrink-0 cursor-pointer"/>
                  </div>:
                  <GlossaryEnglishWordInput
                      highlightedEnglishText={highlightedEnglishText}
                      isSmartGlossary={isSmartGlossary}
                      setHighlightedEnglishText={setHighlightedEnglishText}
                      setEnableEnglishWordEditing={setEnableEnglishWordEditing}
                   />}
                    {/* <div onClick={()=>dispatch(setIsGlossaryAssistantEnabled(!isGlossaryAssistantEnabled))} className="cursor-pointer text-xs text-left -mt-1 px-1 underline text-[#1E1C80] font-[poppins] font-[500]">{isGlossaryAssistantEnabled?"Hide":"Show"} smart glossary assistant</div> */}
                  
                  <AddGlossaryForm
                    chapter={chapter}
                    setShowVariantInputFeild={setShowVariantInputFeild}
                    setGlossaryMatchesData={setGlossaryMatchesData}
                    setOpenedGlossaryData={setOpenedGlossaryData}
                    englishWord={highlightedEnglishText}
                    glossaryMatchesData={glossaryMatchesData}
                    handleUpdateGlossary={handleUpdateGlossary}
                    operationType={"add"}
                    setSelectedGlossary={setSelectedGlossary}
                  />
                  <div className="py-2 text-[12px] text-[#3B37FF] cursor-pointer flex items-center justify-start gap-1">
                    <AddGlossaryIcon className="text-[#3B37FF]" />
                    Add translation
                  </div>
                </div>
              )}

              <div className="flex flex-col gap-2">
                {highlightedTextGlossaryItem?.english_word && (
                  <div className="mb-1">
                    <AccordianGlossary
                      openedGlossaryData={openedGlossaryData}
                      glossary={highlightedTextGlossaryItem}
                      chapter={chapter}
                      setGlossaryMatchesData={setGlossaryMatchesData}
                      glossaryMatchesData={glossaryMatchesData}
                      setOpenedGlossaryData={setOpenedGlossaryData}
                      sourceEditor={sourceEditor}
                      handleUpdateGlossary={handleUpdateGlossary}
                      setSelectedGlossary={setSelectedGlossary}
                      selectedGlossary={selectedGlossary}
                      glossaryData={glossaryData}
                      updateFindTextResults={updateFindTextResults}
                      highlightedEnglishText={highlightedEnglishText}
                    />
                  </div>
                )}
                {glossaryMatchesData.length > 0 && (
                  <div className="text-[#1E1C80] -mb-2 text-sm">
                    Similar Glossary items ({glossaryMatchesData.length})
                  </div>
                )}
                <div className="flex flex-col gap-2">
                  {glossaryMatchesData.map((glossary) => {
                    return (
                      <AccordianGlossary
                        openedGlossaryData={openedGlossaryData}
                        glossary={glossary}
                        chapter={chapter}
                        setGlossaryMatchesData={setGlossaryMatchesData}
                        glossaryMatchesData={glossaryMatchesData}
                        setOpenedGlossaryData={setOpenedGlossaryData}
                        sourceEditor={sourceEditor}
                        handleUpdateGlossary={handleUpdateGlossary}
                        setSelectedGlossary={setSelectedGlossary}
                        selectedGlossary={selectedGlossary}
                        glossaryData={glossaryData}
                        updateFindTextResults={updateFindTextResults}
                        highlightedEnglishText={highlightedEnglishText}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}
        {sectionType === "applicationStatus" && (
          <GlossaryApplicationStatus
            handleCloseGlossaryPannel={() => {
              if (isLoading) return;
              handleCloseGlossaryPannel();
            }}
            // sectionType={sectionType}
            // setSectionType={setSectionType}
            highlightedEnglishText={highlightedEnglishText}
            findOverBookData={findOverBookData}
            sourceEditor={sourceEditor}
            findGlossaryData={findGlossaryData}
            updateFindTextResults={updateFindTextResults}
            handleChapterChange={handleChapterChange}
            // fetchTranslatedChapterPending={fetchTranslatedChapterPending}
            setDisableApplyButton={setDisableApplyButton}
            // disableApplyButton={disableApplyButton}
            translateEditor={translateEditor}
            openedGlossaryData={openedGlossaryData}
            setApplicationProgress={setApplicationProgress}
            // applicationProgress={applicationProgress}
            setShowLoader={setShowLoader}
            showLoader={showLoader}
            chapter={chapter}
            setUpdateIterationData={setUpdateIterationData}
            handleBackButton={() => {
              setSectionType("glossaryMatches");
              setDisableApplyButton(false);
              setShowLoader(false);
            }}
          />
        )}
        {showLoader && (
          <div className="absolute left-0 bottom-[52px] w-full">
            <LinearProgressBar
              value={
                (100 / findGlossaryData?.totalOccurances) *
                applicationProgress.occuranceNo
              }
            />
          </div>
        )}
        <GlossaryFooter
          findWordInSourceEditor={findWordInSourceEditor}
          handleChangeScreenType={handleChangeScreenType}
          sectionType={sectionType}
          findOverBookData={findOverBookData}
          findGlossaryData={findGlossaryData}
          handleFixGlossary={() => handleFixGlossary(handleChangeScreenType)}
          openedGlossaryData={openedGlossaryData}
          showLoader={showLoader}
          applicationProgress={applicationProgress}
          disableApplyButton={disableApplyButton}
          handleApplyGlossary={handleApplyGlossary}
          highlightedEnglishText={highlightedEnglishText}
          setUpdateIterationData={setUpdateIterationData}
          handleChapterChange={handleChapterChange}
        />
        {deleteWarningData && (
          <GlossaryWarningModal
            deleteWarningData={deleteWarningData}
            closePopup={() => setDeleteWarningData(null)}
            handleDelete={handleDeleteGlossaryThroughPopup}
          />
        )}
      </div>
    </>
  );
};

export default GlossaryMatches;
