import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import  IconButton from "@mui/material/IconButton";

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { userLastActiveTime } from "../../utils/firebaseActions";
import { useSelector } from "react-redux";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import store from "../../App/store";
import { styled, Tooltip } from "@mui/material";


function Header({ title,
   author,
   isTranslatedContentEdited,
   setIsTranslatedContentEdited,
   afterHandledUnsavedChangesAction,
  setAfterHandledUnsavedChangesAction,
  translatedChapterInfo,
   goBackRoute = "dashboard" }) {
  const navigate = useNavigate();
  const params = useParams();
  const { loggedInUserEmail ,userOpenedBookInfo,flowType} = useSelector((state) => state.main);
  const {data:chapter}=translatedChapterInfo;

  

  const handleGoBack = async () => {
    if(isTranslatedContentEdited){
      setAfterHandledUnsavedChangesAction({actionName:"goBack",actionData:goBackRoute})
      return ;
    }
    userLastActiveTime(params.id,"remove",loggedInUserEmail)
    {/* @review-glossary-changes */}
    if(flowType==="glossaryReview"){
      navigate(`/review-glossary`);
    }else{
      navigate(`/${goBackRoute}`);
    }
  };

  return (
    <header className="bg-hc-gray book-header w-full justify-between h-[52px] py-2 px-6 flex flex-row items-center">
      <div className="flex items-center">
        <IconButton color="primary" onClick={handleGoBack}>
          <ArrowBackOutlinedIcon color="inherit" />
        </IconButton>
        <div className="ml-4 font-medium">
          <span className="text-hc-dark-gray">Title:</span>
          {title && <span className="text-hc-title-blue pl-2">{title}</span>}
        </div>
        <div className="ml-3 font-medium">
          <span className="text-hc-dark-gray">Author:</span>
          {author && <span className="text-hc-title-blue pl-2">{author}</span>}
        </div>
      </div>
      
    </header>
  );
}

export default Header;
