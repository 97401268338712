import {
  SET_CURRENT_OCCURRENCE_NO,
  SET_FIND_GLOSSARY_CACHE,
  SET_IS_GLOSSARY_ASSISTANT_ENABLED,
  SET_IS_SMART_GLOSSARY,
  SET_RESET_GLOSSARY_MATCHES_SCREEN,
  SET_SEARCHED_ENGLISH_WORD
} from "./glossary.action";

const initialState = {
  isSmartGlossary: false,
  isGlossaryAssistantEnabled: false,
  currentOccurrenceNo: 0,
  findGlossaryCache: {},
  searchedEnglishWord: "",
  resetGlossaryMatchesScreen:{status:false,englishWord:""},
};

const glossaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_SMART_GLOSSARY:
      return { ...state, isSmartGlossary: action.payload };
    case SET_IS_GLOSSARY_ASSISTANT_ENABLED:
      return { ...state, isGlossaryAssistantEnabled: action.payload };
    case SET_CURRENT_OCCURRENCE_NO:
      return { ...state, currentOccurrenceNo: action.payload };
    case SET_FIND_GLOSSARY_CACHE:
      return { ...state, findGlossaryCache: action.payload };
    case SET_SEARCHED_ENGLISH_WORD:
      return { ...state, searchedEnglishWord: action.payload };
    case SET_RESET_GLOSSARY_MATCHES_SCREEN:
      return { ...state, resetGlossaryMatchesScreen: action.payload };
    default:
      return state;
  }
};

export default glossaryReducer;
