import React, { useCallback, useEffect, useState } from "react";

import "./AddToGlossaryForm.scss";
import { ReactComponent as Stars } from "../../assets/stars.svg";
import { ToastContainer, toast } from "react-toastify";
import { ReactComponent as GlossaryInfoIcon } from "../../assets/glossaryInfoIcon.svg";

import { useDispatch, useSelector } from "react-redux";
import apiUrls from "../../api/apiUrl";
import debounce from "lodash.debounce";
import axios from "axios";
import { CircularProgress, Tooltip, Typography, styled } from "@mui/material";
import { setGlossaryList } from "../../App/editor/editor.actions";
import { useParams } from "react-router-dom";
import { getCapitalisedString, updateUserActiveTime } from "../../utils/translationUtil";

function AddGlossaryForm({
  chapter,
  setShowVariantInputFeild,
  setOpenedGlossaryData,
  setGlossaryMatchesData,
  englishWord,
  glossaryMatchesData,
  handleUpdateGlossary,
  operationType,
  setSelectedGlossary,
  variantDataForEdit,
  setVariantDataForEdit,
  isAddVariant,
  handleUpdateInputData,
}) {
  console.log("add glossary: english_word: ", englishWord);
  const [variantInputData, setVariantInputData] = useState({
    replacement_dutch_word: "",
    scope: "",
    whenToApply: "",
    exceptions: "",
  });
  const { loggedInUserEmail } = useSelector((state) => state.main);
  const params = useParams();
  const [showFeilds, setShowFeilds] = useState({
    exception: false,
    whenToApplySuggestions: true,
  });
  const [loading, setLoading] = useState(false);
  const { glossaryList } = useSelector((state) => state.editor);
  const dispatch = useDispatch();
  const [varinatContextSuggestions, setvarinatContextSuggestions] = useState(
    []
  );

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "black",
      color: "white",
      width: "170px",
    },
    "& .MuiTooltip-arrow": {
      color: "black", // Arrow color
    },
  }));

  useEffect(() => {
    if (variantDataForEdit) {
      if (variantDataForEdit.exceptions)
        setShowFeilds({ ...showFeilds, exception: true });
      setVariantInputData(variantDataForEdit);
    }
  }, [variantDataForEdit]);

  const { userOpenedBookInfo } = useSelector((state) => state.main);

  const getVariantContextSuggestions = async (englishWord, dutchWord) => {
    try {
      const response = await axios.post(
        `${
          process.env.REACT_APP_BACKEND_URL + apiUrls.variantContextSuggestions
        }`,
        {
          english_word: englishWord,
          dutch_variant: dutchWord,
        }
      );

      console.log("drResponse", response.data);
    } catch (error) {
      console.log("error in getting glossary");
    }
  };

  const debounceGetVariantContextSuggestions = useCallback(
    debounce(
      (englishWord, dutchWord) =>
        getVariantContextSuggestions(englishWord, dutchWord),
      1000
    ),
    []
  );
  const debouncedUpdateUserActiveTime = useCallback(
    debounce((translationId, loggedInUserEmail) => {
      try {
        return updateUserActiveTime(translationId, loggedInUserEmail);
      } catch (error) {}
    }, 1000),
    []
  );

  console.log("liveValue", variantInputData);

  const handleVariantInputChange = (e) => {
    debouncedUpdateUserActiveTime(params.id, loggedInUserEmail);
    if (isAddVariant) {
      handleUpdateInputData(e);
    }
    setVariantInputData({
      ...variantInputData,
      [e.target.name]: e?.target?.value,
    });

    // if(e.target.name==="replacement_dutch_word"){
    //   debounceGetVariantContextSuggestions(englishWord,e?.target?.value)
    // }
  };

  const isVariantAlreadyExists = (variant, variantList) => {
    if (operationType === "update") return;

    let isThisVariantAlreadyExists = variantList?.find(
      (dutchVariant) =>
        dutchVariant?.replacement_dutch_word?.toLowerCase() ===
        variant?.trim()?.toLocaleLowerCase()
    );

    if (isThisVariantAlreadyExists) {
      toast(`Variant already exists`, {
        type: "warning",
        autoClose: 3000,
      });
      return true;
    }

    return false;
  };

  const handleSaveGlossary = async () => {
    updateUserActiveTime(params.id, loggedInUserEmail);
    if (loading) return;

    if (
      !variantInputData.replacement_dutch_word ||
      !variantInputData.scope ||
      !variantInputData.whenToApply
    )
      return;

    const isGlossaryExist = glossaryList?.find(
      (glossary) => glossary?.english_word === englishWord
    );

    if (
      (isGlossaryExist &&
      isVariantAlreadyExists(
        variantInputData.replacement_dutch_word.trim(),
        isGlossaryExist.dutch_word_array
      ))
    ) {
      return;
    }
    setLoading(true);
    let updateGlossaryData = {
      action: operationType,
      inputData: variantInputData,
      englishText: englishWord,
    };

    if (operationType === "update")
      updateGlossaryData.variant = variantDataForEdit.replacement_dutch_word;

    const updatedVariants = await handleUpdateGlossary(updateGlossaryData);

    setShowVariantInputFeild(false);

    if (setOpenedGlossaryData) {
      setOpenedGlossaryData({
        english_word: englishWord,
        dutch_word_array: [variantInputData],
      });
    }
    let updatedGlossaryList = glossaryList;
    if (isGlossaryExist && updatedVariants?.length) {
      updatedGlossaryList = updatedGlossaryList.map((glossary) => {
        if (glossary.english_word === englishWord) {
          glossary.dutch_word_array = updatedVariants;
        }
        return glossary;
      });
    }

    dispatch(setGlossaryList(updatedGlossaryList));

    setSelectedGlossary({
      englishWord: englishWord,
      variant: variantInputData?.replacement_dutch_word,
    });

    if (setVariantDataForEdit)
      setVariantDataForEdit({
        replacement_dutch_word: "",
        scope: "",
        whenToApply: "",
        exceptions: "",
      });

    setVariantInputData({
      replacement_dutch_word: "",
      scope: "",
      whenToApply: "",
      exceptions: "",
    });
    setLoading(false);
  };

  const handleCancelButton = () => {
    updateUserActiveTime(params.id, loggedInUserEmail);
    if (loading) return;
    setVariantInputData({
      english_word: englishWord,
      replacement_dutch_word: "",
      scope: "",
      whenToApply: "",
      exceptions: "",
    });
    if (setVariantDataForEdit)
      setVariantDataForEdit({
        replacement_dutch_word: "",
        scope: "",
        whenToApply: "",
        exceptions: "",
      });
    setShowFeilds({ ...showFeilds, whenToApplySuggestions: true });
    setShowVariantInputFeild(false);
  };

  return (
    <div
      style={{ border: !isAddVariant ? "1px solid #3961FF" : "" }}
      className={`gap-2 flex flex-col rounded-[4px] bg-white ${
        !isAddVariant ? "p-5" : ""
      }`}
    >
      <div className="flex flex-col gap-2">
        <span className="text-[12px] text-[#000000D9] flex items-center gap-1 font-[poppins] font-[500]">
          {getCapitalisedString(userOpenedBookInfo?.translatedTo)} Variant{" "}
          <span
            style={{ fontFamily: "SimSong" }}
            className="text-[#FF4D4F] text-sm"
          >
            *
          </span>
        </span>
        <input
          style={{ border: "1px solid #D9D9D9" }}
          placeholder="Type here"
          className="w-full text-sm rounded-[4px]  px-3 py-[5px] outline-none"
          name="replacement_dutch_word"
          value={variantInputData.replacement_dutch_word}
          onChange={handleVariantInputChange}
          autoComplete="off"
        />
      </div>
      <div className="w-full flex flex-col gap-2 relative">
        <label
          htmlFor="book-genre"
          className="text-[12px] text-[#000000D9] flex items-center gap-1 font-[poppins] font-[500]"
        >
          Apply this glossary to
          <span
            style={{ fontFamily: "SimSong" }}
            className="text-[#FF4D4F] text-sm"
          >
            *
          </span>
        </label>

        <div className=" w-full">
          <select
            id="book-sub-genre"
            className={`w-full  ${
              variantInputData.scope ? "text-[#242424]" : "text-[gray]"
            } py-[5px] px-3 outline-none text-sm`}
            placeholder="Enter the Sub-Genre of the book"
            style={{
              borderRadius: "4px",
              borderColor: "#D9D9D9",
              fontSize: "14px",
            }}
            onChange={handleVariantInputChange}
            name="scope"
            value={variantInputData.scope}
          >
            <option value="" className="" disabled selected hidden>
              Select apply this glossary to
            </option>
            <option className="text-sm " value={"Book"} key={"book"}>
              Current book:{" "}
              {userOpenedBookInfo.title ?? chapter?.book_info?.book_title}
            </option>
            <option className="text-sm" value={"Author"} key={"author"}>
              Author:{" "}
              {userOpenedBookInfo?.author ?? chapter?.book_info?.author_name}
            </option>
            <option className="text-sm" value={"Genre"} key={"genre"}>
              Genre: {userOpenedBookInfo?.genre ?? "Romance"}
            </option>
            <option className="text-sm" value={"Language"} key={"language"}>
              Language: {userOpenedBookInfo?.translatedTo ?? "Dutch"}
            </option>
          </select>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-1">
            <span className="text-[12px] flex items-center gap-1 text-[#000000D9] font-[poppins] font-[500]">
              When to apply
              <span
                style={{ fontFamily: "SimSong" }}
                className="text-[#FF4D4F] text-sm"
              >
                *
              </span>
            </span>
            <CustomTooltip
              sx={{
                "& .MuiTooltip-tooltip": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              placement="top-start"
              arrow
              title={
                <React.Fragment>
                  <b>Example</b>
                  : Light <br />
                  Use light when referring to brightness, like switch on the
                  light.
                </React.Fragment>
              }
            >
              <GlossaryInfoIcon className="cursor-pointer mb-[2px] text-[#999898] w-[14px] h-[14px]" />
            </CustomTooltip>
          </div>
          <textarea
            style={{ border: "1px solid #D9D9D9", fontFamily: "Poppins" }}
            onChange={handleVariantInputChange}
            name="whenToApply"
            placeholder="Type here"
            value={variantInputData.whenToApply}
            className="w-full h-[54px] hideSourceScrollBar whenToApply resize-none  text-sm rounded-[4px]  px-3 py-[5px] outline-none "
          />
        </div>
        {/* {showFeilds.whenToApplySuggestions && (
          <div className="flex flex-col gap-2">
            <div
              style={{ border: "1px solid #D9D9D9" }}
              onClick={() => {
                setVariantInputData({
                  ...variantInputData,
                  whenToApply:
                    "When someone inflates or amplifies a fact beyond truth.",
                });
                setShowFeilds({ ...showFeilds, whenToApplySuggestions: false });
              }}
              className="flex gap-2 py-1 px-2 cursor-pointer text-[10px] w-fit bg-[#F5F5F5] items-center"
            >
              <Stars />{" "}
              <span>
                When someone inflates or amplifies a fact beyond truth.
              </span>
            </div>
          </div>
        )} */}
        {/* {showFeilds.exception && (
          <input
            style={{ border: "1px solid #D9D9D9" }}
            onChange={handleVariantInputChange}
            name="exceptions"
            placeholder="Type the exceptions here"
            value={variantInputData.exceptions}
            autoComplete="off"
            className="w-full text-sm rounded-[4px]  px-3 py-[5px] outline-none "
          />
        )} */}

        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-1">
            <span className="text-[12px] flex items-center gap-1 text-[#000000D9] font-[poppins] font-[500]">
              When to not apply
              {/* <span
                style={{ fontFamily: "SimSong" }}
                className="text-[#FF4D4F] text-sm"
              >
                *
              </span> */}
            </span>
            <CustomTooltip
              sx={{
                "& .MuiTooltip-tooltip": {
                  backgroundColor: "black",
                  color: "white",
                  width: "170px",
                },
              }}
              placement="top-start"
              arrow
              title={
                <React.Fragment>
                  <b>Example</b>
                  : Light <br />
                  Avoid using light for non-brightness contexts, like light
                  weight.
                </React.Fragment>
              }
            >
              <GlossaryInfoIcon className="cursor-pointer mb-[2px] text-[#999898] w-[14px] h-[14px]" />
            </CustomTooltip>
            <span className="text-[#999898] text-[12px]">(optional)</span>
          </div>
          <textarea
            style={{ border: "1px solid #D9D9D9", fontFamily: "Poppins" }}
            onChange={handleVariantInputChange}
            name="exceptions"
            placeholder="Type here"
            value={variantInputData.exceptions}
            autoComplete="off"
            className="w-full h-[54px] whenToApply hideSourceScrollBar resize-none  text-sm rounded-[4px]  px-3 py-[5px] outline-none "
          />
        </div>
      </div>
      {!isAddVariant && (
        <div className="flex items-center gap-[17px] text-xs">
          <span
            className="cursor-pointer text-[#979797] "
            onClick={handleCancelButton}
          >
            Cancel
          </span>
          <div className="flex items-center gap-2">
            <span
              onClick={handleSaveGlossary}
              className="text-[#3B37FF] cursor-pointer"
            >
              {loading ? "Saving" : "Save"}
            </span>
            {loading && <CircularProgress size={11} className="mb-[2px]" />}
          </div>
        </div>
      )}
    </div>
  );
}

export default AddGlossaryForm;
