import React, { useEffect, useState } from "react";
const options = [
    {
      label: "Dutch",
      value: "dutch",
    },
    { label: "Malyalam", value: "malyalam" },
    { label: "Hinglish", value: "hinglish" },

    { label: "Hindi", value: "hindi" },
    { label: "Telugu", value: "telugu" },
    { label: "French", value: "french" },
    { label: "All", value: "all" },
  ];
function LanguageFilter({data,setFilteredData,setLanguageSelectedOption,languageSelectedOption}) {
  
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setLanguageSelectedOption(selectedValue);
    if(selectedValue==="all"){
      setFilteredData(data)
      return
    }

    let filteredData=data.filter((bookData)=>  bookData?.assistant_name?.split?.("-")[0]===selectedValue)
    setFilteredData(filteredData)
  };
  useEffect(() => {
     let filteredData=data.filter((bookData)=>  bookData?.assistant_name?.split?.("-")[0]===languageSelectedOption)
     setTimeout(()=>{
         setFilteredData(filteredData)
     })
  }, [data])
  
  return (
    <div className="relative w-full">
      <select
        id="target-language"
        className=" w-36 round px-1 outline-none h-[50px] shrink-0 "
        style={{ borderRadius: "10px"}}
        onChange={handleChange}
        value={languageSelectedOption}
        label={"Select a value"}
      >
        <optipn>Select a Value</optipn>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default LanguageFilter;
