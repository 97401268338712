const fontSizeOptions = [
    "default",
    9,
    12,
    14,
    16,
    18,
    20,
    22,
    24,
    26,
    28,
    30,
    32,
  ];

export const editorConstConfig = {
  licenseKey:
    "dDUwTTB2dXp4QngyQUxvSUJlWG9yS21kWkJwTDVuUUFmVUtuaHFnZ0pPYThXZUFBZlNpbXl6NzVtcHcrLU1qQXlOREEwTWpZPQ==",
  highlight: {
    options: [
      {
        model: "yellowMarker",
        class: "marker-yellow",
        title: "Yellow marker",
        color: "var(--ck-highlight-marker-yellow)",
        type: "marker",
      },
    ],
  },
  fontFamily: {
    options: [
      "default",
      "Arial, Helvetica, sans-serif",
      "Times New Roman, Times, serif",
    ],
  },
  fontSize: {
    options: fontSizeOptions,
    supportAllValues: true,
  },
  htmlSupport: {
    allow: [
      {
        name: "span",
        attributes: {
          "data-sentence-id": true,
          "data-replacement-reason": true,
          variant_used: true,
          glossary_english_word: true,
          local_id: true,
          "data-occurrence-no":true,
        },
        styles: true,
        classes: true,
      },
      {
        name: "p",
        attributes: {
          "data-paragraph-id": true,
          "data-first-sentence-id": true,
          "data-connected-first-sentence-id": true,
          "data-spacer-paragraph-id": true,
          "data-confidence-score": true,
          confidence_score: true,
          "data-replacement-reason": true,
        },
        styles: true,
        classes: true,
      },
    ],
  },
  wordCount: {
    displayCharacters: false,
  },
  findAndReplace: {
    uiType: "dialog",
  },
};
