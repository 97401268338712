import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsGlossaryAssistantEnabled } from "../../App/Glossary/glossary.action";
import { ReactComponent as Stars } from "../../assets/WhiteStars.svg";

function SmartGlossaryChatButton() {
  const { isGlossaryAssistantEnabled } = useSelector((state) => state.glossary);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   setTimeout(()=>{
  //   let element = document.querySelector("[alt='close-icon']");
  //   if (element) {
  //     console.log("element print",element);
  //   }
  // },2000)
  // }, [isGlossaryAssistantEnabled]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        dispatch(setIsGlossaryAssistantEnabled(!isGlossaryAssistantEnabled));
      }}
      style={{
        background: "linear-gradient(96deg, #5652CC -55.7%, #29217C 131.72%)",
      }}
      className=" text-xs rounded flex items-center cursor-pointer gap-[6px] p-2 text-[#FFF] font-[poppins] font-[500]"
    >
      {" "}
      <Stars className="" />{" "}
      <span>
        {" "}
        {isGlossaryAssistantEnabled
          ? "Hide Glossary Chat"
          : "Smart Glossary Chat"}{" "}
      </span>
    </div>
  );
}

export default SmartGlossaryChatButton;
