import useApi from "../../api/useAPi";
import apiUrls from "../../api/apiUrl";
import { SET_CONCURRENT_EDITS_DATA, SET_FLOW_TYPE, SET_GLOSSAY_ITEM, SET_IS_ITEM_LOAD, SET_IS_LOADING, SET_LOGGED_IN_USER_EMAIL, SET_PAIRED_BOOK_DATA, SET_TRANSFORMED_DATA, SET_USER_OPENED_BOOK_INFO } from "../type";

export const fetchBooksList =
  ({ fetchData, isItemCallId, flowType }) =>
  async (dispatch) => {
    if (isItemCallId?.length === 0) {
      dispatch(setIsLoading(true));
    } else {
      dispatch(setIsItemLoad(isItemCallId));
    }

    const status = "['Queued','Translating','Sending to RLEF','Completed','Error']";
    const encodedStatus = `"${status}"`;
    try {
      // const response = await fetchData(apiUrls.getBooksList + encodedStatus);
      const response = await fetchData(flowType==="glossaryReview" ? apiUrls.getGeneratedGlossaryBookList : apiUrls.getBooksList);
      // const response = await fetchData(apiUrls.getBooksList + type);

      const transformedData = [];
      const getStatusLabel = (status) => {
        console.log("status",status)
        if (status === "Completed") return "Pairing Completed";
        if (status === "Queued") return "Queued For Translation";
        if (status === "Translating") return "Translation in Progress";
        if (status === "Translated") return "AI Translation Completed";
        return status;
      };
      const order = { Translated: "", Queued: "", Translating: "" };
      for (const key in order) {
        if (response.hasOwnProperty(key)) {
          response[key].forEach((tag) => {
            transformedData.push({
              title: tag.book_title,
              author: tag.author_name,
              status: getStatusLabel(key),
              pages: "NA",
              link: tag.unique_string,
              created_at_date: tag.created_at_date,
              created_at_time: tag.created_at_time,
              temperature: tag.temperature,
              assistant_name: tag.assistant_name
            });
          });
        }
      }
      dispatch(setTransformedData(transformedData));
      if (isItemCallId.length === 0) {
        dispatch(setIsLoading(false));
      } else {
        dispatch(setIsItemLoad(""));
      }
    } catch (error) {
      isItemCallId?.length === 0 ? dispatch(setIsLoading(false)) : dispatch(setIsItemLoad(""));
    }
  };

export const setTransformedData = (payload) => (dispatch) => {
  const sortedDataByTime = payload.sort((a, b) => {
    const dateA = new Date(`${a.created_at_date} ${a.created_at_time}`);
    const dateB = new Date(`${b.created_at_date} ${b.created_at_time}`);
    return dateB - dateA; // Sort in descending order (most recent first)
  });
  dispatch({
    type: SET_TRANSFORMED_DATA,
    payload: sortedDataByTime,
  });
};

export const fetchPairedDataRedux =
  ({ fetchData, pairedBookId, currentPage },stopLoader=true) =>
  async (dispatch) => {
    try {
      if(stopLoader) dispatch(setIsLoading(true));
      const response = await fetchData(apiUrls.getPairs + pairedBookId + '&chapter_item=' + currentPage);
      // response.auto_ai_resource.resources.forEach((item, i) => {
      //   if (i >= 5) {
      //     item.textTranslatedLanguageSentences = null;
      //   }
      //   return item;
      // });
      dispatch(setPairedBookData(response));

      dispatch(setIsLoading(false));
    } catch (error) {
      console.error("Error fetching data:", error);
      dispatch(setIsLoading(false));
    }
  };

export const setPairedBookData = (payload) => (dispatch) => {
  dispatch({
    type: SET_PAIRED_BOOK_DATA,
    payload: payload,
  });
};

const setIsLoading = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_LOADING,
    payload,
  });
};
export const setIsItemLoad = (payload) => (dispatch) => {
  console.log("payloadRedux", payload);
  dispatch({
    type: SET_IS_ITEM_LOAD,
    payload,
  });
};

export const setGlossaryItem = payload => (dispatch) => {
  dispatch({
    type: SET_GLOSSAY_ITEM,
    payload
  });
};

export const  setLoggedInUserEmail = (payload) => (dispatch) => {
  dispatch({
    type: SET_LOGGED_IN_USER_EMAIL,
    payload,
  });
};

export const  setUserOpenedBookInfo = (payload) => (dispatch) => {
  dispatch({
    type: SET_USER_OPENED_BOOK_INFO,
    payload,
  });
};

export const  setConcurrentEditsData = (payload) => (dispatch) => {
  dispatch({
    type: SET_CONCURRENT_EDITS_DATA,
    payload,
  });
};

export const  setFlowType = (payload) => (dispatch) => {
  dispatch({
    type: SET_FLOW_TYPE,
    payload,
  });
};


